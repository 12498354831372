import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logoEmpresa from './assets/logoEmpresa.png'; 

function SideMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();

  //Menú Mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goDashboard = () => {
    navigate("/dashboard", { state: props.dataLogin });
  };

  /* const goInformeDiario = () => {
    navigate("/informediario", { state: props.dataLogin });
  }; */

  const goCliente = () => {
    navigate("/cliente", { state: props.dataLogin });
  };

  const goEquipos = () => {
    navigate("/equipos", { state: props.dataLogin });
  };

  const goHerramientas = () => {
    navigate("/herramientas", { state: props.dataLogin });
  };

  /* const goObras = () => {
    navigate("/obras", { state: props.dataLogin });
  }; */

  const goServicios = () => {
    navigate("/servicios", { state: props.dataLogin });
  };

 /*  const goVisitaTecnica = () => {
    navigate("/visitatecnica", { state: props.dataLogin })
  } */

  const goReporteEstadoPago = () => {
    navigate("/reporte-estado-pago", { state: props.dataLogin });
  }

  const goReporteVentas = () => {
    navigate("/reporte-ventas", { state: props.dataLogin });
  }

  const goReporteHoras = () => {
    navigate("/reporte-horas-trabajadas", { state: props.dataLogin });
  }

  const goReporteFacturacion = () => {
    navigate("/reporte-facturacion", { state: props.dataLogin });
  }

  return (
    <div className="h-100">
      {isMobile ? (
        <div className="offcanvas offcanvas-start" id="menuMobile" aria-labelledby="menuMobileLabel">
          <div className="offcanvas-header border-bottom">
            <img src={logoEmpresa} alt="Logo de la empresa" />
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body d-flex flex-column justify-content-between">
            <ul className="list-group list-group-flush">
              <button type="button" className={location.pathname === '/dashboard' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goDashboard}>
                <i className="bi bi-house"></i> Dashboard
              </button>
              {/* <button type="button" className={location.pathname === '/informediario' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goInformeDiario}>
                <i className="bi bi-file-earmark-text"></i> Informe Diario
              </button> */}
              <button type="button" className={location.pathname === '/cliente' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goCliente}>
                <i className="bi bi-person"></i> Clientes
              </button>
              {/* <button type="button" className={location.pathname === '/obras' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goObras}>
                <i className="bi bi-building"></i> Obras
              </button> */}
              {/* <button type="button" className={location.pathname === '/visitatecnica' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goVisitaTecnica}>
                <i className="bi bi-person-down"></i> Visita Técnica
              </button> */}
              <button type="button" className={location.pathname === '/servicios' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goServicios}>
                <i className="bi bi-list-task"></i> Servicios
              </button>
              <button type="button" className={location.pathname === '/equipos' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goEquipos}>
                <i className="bi bi-truck"></i> Equipos
              </button>
              <button type="button" className={location.pathname === '/herramientas' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goHerramientas}>
                <i className="bi bi-gear"></i> Herramientas
              </button>
              <button type="button" className={location.pathname === '/reporte-estado-pago' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goReporteEstadoPago}>
                <i className="bi bi-credit-card-2-front"></i> Reporte Estado Pago
              </button>
              <button type="button" className={location.pathname === '/reporte-ventas' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goReporteVentas}>
                <i className="bi bi-receipt"></i> Reporte Ventas
              </button>
              <button type="button" className={location.pathname === '/reporte-horas-trabajadas' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goReporteHoras}>
                <i className="bi bi-clock"></i> Reporte Horas Trabajadas
              </button>
              <button type="button" className={location.pathname === '/reporte-facturacion' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goReporteFacturacion}>
                <i className="bi bi-file-earmark-ruled"></i> Reporte Facturación
              </button>
            </ul>
            <ul className="list-group list-group-flush">
              <button type="button" className={location.pathname === '/herramientas' ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'} onClick={goHerramientas}>
                <i className="bi bi-gear"></i> Herramientas
              </button>
            </ul>
          </div>
        </div>
      ) : (
        <div className="sideMenu d-none d-lg-flex flex-column flex-shrink-1">
          <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
            <li>
              <button type="button" className={location.pathname === '/dashboard' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Dashboard" data-bs-original-title="Dashboard" title="Dashboard" onClick={goDashboard}>
                <i className="bi bi-house"></i>
              </button>
            </li>
            {/* <li>
              <button type="button" className={location.pathname === '/informediario' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Informe Diario" data-bs-original-title="Informe Diario" title="Informe Diario" onClick={goInformeDiario}>
                <i className="bi bi-file-earmark-text"></i>
              </button>
            </li> */}
            <li>
              <button type="button" className={location.pathname === '/cliente' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Clientes" data-bs-original-title="Clientes" title="Clientes" onClick={goCliente}>
                <i className="bi bi-person"></i>
              </button>
            </li>
            {/* <li>
              <button type="button" className={location.pathname === '/obras' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Obras" data-bs-original-title="Obras" title="Obras" onClick={goObras}>
                <i className="bi bi-building"></i>
              </button>
            </li> */}
            {/* <li>
              <button type="button" className={location.pathname === '/visitatecnica' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Visita Técnica" data-bs-original-title="Visita Técnica" title="Obras" onClick={goVisitaTecnica}>
                <i className="bi bi-person-down"></i>
              </button>
            </li> */}
            <li>
              <button type="button" className={location.pathname === '/servicios' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Servicios" data-bs-original-title="Servicios" title="Servicios" onClick={goServicios}>
                <i className="bi bi-list-task"></i>
              </button>
            </li>
            <li>
              <button type="button" className={location.pathname === '/equipos' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Equipos" data-bs-original-title="Equipos" title="Equipos" onClick={goEquipos}>
                <i className="bi bi-truck"></i>
              </button>
            </li>
            <li>
              <button type="button" className={location.pathname === '/reporte-estado-pago' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Reporte Estado Pago" data-bs-original-title="Reporte Estado Pago" title="Reporte Estado Pago" onClick={goReporteEstadoPago}>
                <i className="bi bi-credit-card-2-front"></i>
              </button>
            </li>
            <li>
              <button type="button" className={location.pathname === '/reporte-ventas' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Reporte Ventas" data-bs-original-title="Reporte Ventas" title="Reporte Ventas" onClick={goReporteVentas}>
                <i className="bi bi-receipt"></i>
              </button>
            </li>
            <li>
              <button type="button" className={location.pathname === '/reporte-horas-trabajadas' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Reporte Horas Trabajadas" data-bs-original-title="Reporte Horas Trabajadas" title="Reporte Horas Trabajadas" onClick={goReporteHoras}>
                <i className="bi bi-clock"></i>
              </button>
            </li>
            <li>
              <button type="button" className={location.pathname === '/reporte-facturacion' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Reporte Facturación" data-bs-original-title="Reporte Facturación" title="Reporte Facturación" onClick={goReporteFacturacion}>
                <i className="bi bi-file-earmark-ruled"></i>
              </button>
            </li>
          </ul>
          { props.dataLogin.id_perfil === 1 ? 
            <ul className="nav nav-pills nav-flush">
              <li>
                <button type="button" className={location.pathname === '/herramientas' ? 'active nav-link py-3' : 'nav-link py-3'} data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Herramientas" data-bs-original-title="Herramientas" title="Herramientas" onClick={goHerramientas}>
                  <i className="bi bi-gear"></i>
                </button>
              </li>
            </ul>
          : "" }
        </div>
      )}
    </div>
  );
}

export default SideMenu;
