import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
//import InformeDiario from './InformeDiario';
import Cliente from './Cliente';
import Equipos from './Equipos';
import Herramientas from './Herramientas';
/* import Obras from './Obras';
import VisitaTecnica from './VisitaTecnica'; */
import Servicios from './Servicios';
import ReporteEstadoPago from './ReporteEstadoPago';
import ReporteHoras from './ReporteHoras';
import ReporteVenta from './ReporteVenta';
import ReporteFacturacion from './ReporteFacturacion';
//import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  /* useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []); */

  return (
    <Router>
      <Routes>
        <Route basename="/" path="/" element={<Login />} />
        <Route basename="/" path="/dashboard" element={<Dashboard />} />
        <Route basename="/" path="/cliente" element={<Cliente />} />
        <Route basename="/" path="/equipos" element={<Equipos />} />
        {/* <Route basename="/" path="/informediario" element={<InformeDiario />} /> */}
        <Route basename="/" path="/herramientas" element={<Herramientas />} />
        {/* <Route basename="/" path="/obras" element={<Obras />} /> */}
        {/* <Route basename="/" path="/visitatecnica" element={<VisitaTecnica />} /> */}
        <Route basename="/" path="/servicios" element={<Servicios />} />
        <Route basename="/" path="/reporte-estado-pago" element={<ReporteEstadoPago />} />
        <Route basename="/" path="/reporte-ventas" element={<ReporteVenta />} />
        <Route basename="/" path="/reporte-horas-trabajadas" element={<ReporteHoras />} />
        <Route basename="/" path="/reporte-facturacion" element={<ReporteFacturacion />} />
      </Routes>
    </Router>
  );
}

export default App;
