import React from 'react';
import logoEmpresa from './assets/logoEmpresa.png';
import CentroNotificaciones from './CentroNotificaciones';
import { useNavigate } from 'react-router-dom';

function Header(props) {
  const navigate = useNavigate();
  const logout = () => {
    try {
      //console.log(JSON.stringify(props));
      if (props && props.dataLogin.id_usuario) {
        props = "";
        navigate('/', { state: "" });
      } 
    } catch (error) {
      alert('Hubo un error al intentar cerrar su sesión. Por favor, inténtalo de nuevo.');
    }
  };

  // Aquí obtenemos nombres y apellidos, y si no están definidos, mostramos "Nombre de usuario"
  const displayName = props.dataLogin 
                      ? `${props.dataLogin.nombres} ${props.dataLogin.apellido_paterno}`
                      : 'Nombre de usuario';

  return (
    <header>
      <img src={logoEmpresa} alt="Logo de la empresa" />

      <button type="button" className="btn btn-dark d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#menuMobile">
        <i className="bi bi-list"></i>
      </button>

      <div className="d-none d-lg-flex">
        {/* <button
          type="button"
          className="btn btn-dark me-2"
          data-bs-toggle="offcanvas"
          data-bs-target="#notificaciones"
        >
          <i className="bi bi-bell"></i>
        </button> */}
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <button className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              {displayName}
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <button className="dropdown-item" onClick={logout}>Cerrar Sesión</button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <CentroNotificaciones />
    </header>
  );
}

export default Header;
