import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { format, parseISO, parse, minutesToMilliseconds } from 'date-fns';
import { useNavigate, useLocation, json } from "react-router-dom";
import { validate, clean } from 'rut.js';
import './App.css';

function Cliente() {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataLogin, setDataLogin] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [clienteSelected, setClienteSelected] = useState([]);
  const [obraSelected, setObraSelected] = useState([]);
  const [obras, setObras] = useState([]);
  const [mostrarBotonObras, setMostrarBotonObras] = useState(false);
  const [regiones, setRegiones] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [bodegas, setBodegas] = useState([]);
  const [detalleCliente, setDetalleCliente] = useState([]);
  const [detalleObra, setDetalleObra] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [reportsBH, setReportsBH] = useState([]);
  const [reportsGH, setReportsGH] = useState([]);
  const [reportsGT, setReportsGT] = useState([]);
  const [mostrarBotonEvaluacion, setMostrarBotonEvaluacion] = useState(false);
  const [isModalClienteOpen, setModalClienteOpen] = useState(false);
  const [isModalObrasOpen, setModalObrasOpen] = useState(false);
  const [isModalComentariosOpen, setModalComentariosOpen] = useState(false);
  const [isModalDetalleClienteOpen, setModalDetalleClienteOpen] = useState(false);
  const [isModalEvaluacionOpen, setModalEvaluacionOpen] = useState(false);
  const [isModalDetalleObraOpen, setModalDetalleObraOpen] = useState(false);
  const [isModalEditarClienteOpen, setModalEditarClienteOpen] = useState(false);
  const [isModalEditarObraOpen, setModalEditarObraOpen] = useState(false);
  const [indicadorUF, setIndicadorUF] = useState(0);

  const [formClienteData, setFormClienteData] = useState({
    rut: "",
    nombre_cliente: "",
    razon_social: "",
    giro: "",
    direccion: "",
    id_region: "",
    id_comuna: "",
    nombre_representante: "",
    rut_representante: "",
    telefono_representante: "",
    email_representante: "",
    id_clasificacion: "1",
    id_usuario: ""
  });

  const [formObras, setFormObras] = useState({
    nombre: "",
    direccion: "",
    id_region: "",
    id_comuna: "",
    id_bodega: "",
    id_usuario: ""
  });

  const [formClienteObra, setFormClienteObra] = useState({
    id_cliente: "",
    id_obra: "",
    id_usuario: ""
  });

  const [formContacto, setFormContacto] = useState({
    id_cliente: "",
    id_obra: "",
    rut: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    telefono: "",
    email: "",
    cargo: "",
    id_usuario: ""
  });

  const [formComentarios, setFormComentarios] = useState({
    id_cliente: "",
    id_clasificacion: "1",
    observaciones: "",
    id_usuario: ""
  });

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerIndicadorUF();
      obtenerClientes();
      obtenerRegiones();
      obtenerBodegas();
    }
  }, [location]);

  useEffect(() => {
    setFormObras({
      nombre: "",
      direccion: "",
      id_region: "",
      id_comuna: "",
      id_bodega: "",
      id_usuario: dataLogin.id_usuario
    });
    setFormContacto({
      id_cliente: clienteSelected.id_cliente,
      id_obra: "",
      rut: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      telefono: "",
      email: "",
      cargo: "",
      id_usuario: dataLogin.id_usuario
    });
    setFormClienteObra({
      id_cliente: clienteSelected.id_cliente,
      id_obra: "",
      id_usuario: dataLogin.id_usuario
    });
  }, [clienteSelected]);

  useEffect(() => {
    setFormComentarios({
      id_cliente: clienteSelected.id_cliente,
      id_clasificacion: "1",
      observaciones: "",
      id_usuario: dataLogin.id_usuario
    });
  }, [clienteSelected]);

  const obtenerClientes = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/cliente.php`
      );
      const data = await response.json();

      if (data && data[0].id_cliente) {
        setClientes(data);
      }
    } catch (error) {
      setClientes([]);
      //alert("No hay clientes para mostrar.");
    }
  };

  const obtenerDetalleCliente = async (cliente) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/cliente.php?id_cliente=${cliente}`
      );
      const data = await response.json();
  
      if (data && data[0].id_cliente) {
        setDetalleCliente(data);

        obtenerComunas(data[0].id_region);
      }
    } catch (error) {
      setDetalleCliente([]);
    }
  }

  const obtenerObras = async (cliente) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${cliente}`
      );
      const data = await response.json();

      setMostrarBotonObras(true);

      if (data && data[0].id_obra) {
        setObras(data);
      }
    } catch (error) {
      setObras([]);
      //alert("No hay obras para mostrar.");
    }
  };

  const obtenerDetalleObra = async (obra) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/obra.php?id_obra=${obra}`
      );
      const data = await response.json();
      //console.log(JSON.stringify(data,null,2))
  
      if (data && data[0].id_obra) {
        setDetalleObra(data);
      }
    } catch (error) {
      setDetalleObra([]);
    }
  }

  const obtenerReportsBH = async (obra) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/report_bh.php?id_obra=${obra}`
      );
      const data = await response.json();

      if (data && data[0].id_report_bh) {
        setReportsBH(data);
      }
    } catch (error) {
      console.log(error);
      setReportsBH([]);
      //alert("No hay obras para mostrar.");
    }
  };

  const obtenerReportsGH = async (obra) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/report_gh.php?id_obra=${obra}`
      );
      const data = await response.json();

      if (data && data[0].id_report_gh) {
        setReportsGH(data);
      }
    } catch (error) {
      setReportsGH([]);
      //alert("No hay obras para mostrar.");
    }
  };

  const obtenerReportsGT = async (obra) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/report_gt.php?id_obra=${obra}`
      );
      const data = await response.json();

      if (data && data[0].id_report_gt) {
        setReportsGT(data);

        //alert(JSON.stringify(data));
      }
    } catch (error) {
      setReportsGT([]);
      //alert("No hay obras para mostrar.");
    }
  };

  const obtenerComentarios = async (cliente) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/evaluacion.php?id_cliente=${cliente}`
      );
      const data = await response.json();

      if (data && data[0].id_clasificacion) {
        setComentarios(data);
      }
    } catch (error) {
      setComentarios([]);
      //alert("No hay Comentarios para mostrar.");
    }
  };

  const obtenerRegiones = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/region.php`
      );
      const data = await response.json();

      if (data && data[0].id_region) {
        setRegiones(data);
      }
    } catch (error) {
      setRegiones([]);
      //alert("No hay Comentarios para mostrar.");
    }
  };

  const obtenerComunas = async (region) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/comuna.php?id_region=${region}`
      );
      const data = await response.json();

      if (data && data[0].id_comuna) {
        setComunas(data);
      }
    } catch (error) {
      setComunas([]);
    }
  };

  const obtenerBodegas = async (region) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/bodega.php`
      );
      const data = await response.json();

      if (data && data[0].id_bodega) {
        setBodegas(data);
      }
    } catch (error) {
      setBodegas([]);
    }
  };

  const obtenerIndicadorUF = async () => {
    try {
      const response = await fetch('https://mindicador.cl/api/uf');
      const data = await response.json();
      
      if (data && Object.keys(data).length > 0) {
        // Acceder al valor de la UF del día actual
        /* const fechaHoy = new Date().toISOString().split('T')[0];
        const ufDelDiaActual = data.serie.find(uf => uf.fecha.includes(fechaHoy));
        const valorUF = ufDelDiaActual.valor; */

        setIndicadorUF(data);
      } else {
        setIndicadorUF(0);
      }
    } catch (error) {
      setIndicadorUF(0);
    }
  };

  // Calcular la fecha mínima (un mes atrás) para input de Fechas en Reports
  const today = new Date();
  const minDate = new Date();
  /* minDate.setMonth(today.getMonth() - 1);
  minDate.setDate(today.getDate() + 1); */
  minDate.setMonth(minDate.getMonth() - 1);

  const formatearRut = (rut) => {
    const rutFormateado = `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(5)}`;
    return rutFormateado;
  };

  const formatearRutSinPuntos = (rut) => {
    const rutLimpio = clean(rut);
    
    if (rutLimpio.length < 8) {
      return rut;
    }

    const rutFormateado = `${rutLimpio.slice(0, -1)}-${rutLimpio.slice(-1)}`;
    return rutFormateado;
  };

  //Valida Rut
  const validarRut = (rut) => {
    const rutFormateado = formatearRutSinPuntos(rut);
    return validate(rutFormateado);
  }

  //Valida Email
  const validarEmail = (email) => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function ClienteCard({ cliente, onSelect }) {
    const evaluacionToEmoji = {
      1: "😊",
      2: "😐",
      3: "☹️",
    };

    const evaluacionToColor = {
      1: "green",
      2: "yellow",
      3: "red",
    };

    return (
      <div className="card-inside c-pointer" onClick={() => onSelect(cliente)}>
        <div className="card-title d-flex justify-content-between align-items-center border-bottom bg-light p-2 mb-1">
          <span className="label">{cliente.nombre_cliente}</span>
          <div className="d-inline-flex gap-2">
            <button className="btn btn-dark btn-sm" type="button" onClick={handleDetalleClienteClick} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Ver Detalles" data-bs-original-title="Ver Detalles" title="Ver Detalles">
              <i className="bi bi-eye"></i>
            </button>
            {dataLogin.id_perfil === 1 && (
              <button className="btn btn-dark btn-sm" type="button" onClick={handleEvaluacionClick} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Evaluación" data-bs-original-title="Evaluación" title="Evaluación">
                <i className="bi bi-clipboard-check"></i>
              </button>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between px-2 mb-1">
          <span className="form-label mb-0">Razón Social</span>
          <span className="label">{cliente.razon_social}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-1">
          <span className="form-label mb-0">Rut</span>
          <span className="label">{formatearRut(cliente.rut)}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-1" style={{ color: evaluacionToColor[cliente.X_EVALUACION] }}>
          <span className="form-label mb-0">Evaluación</span>
          <span>{evaluacionToEmoji[cliente.id_clasificacion]}</span>
        </div>
      </div>
    );
  }

  function ObraCard({ obra, onSelect }) {
    return (
      <div className="card-inside c-pointer" onClick={() => onSelect(obra)}>
        <div className="card-title d-flex justify-content-between align-items-center border-bottom bg-light p-2 mb-1">
          <span className="label">{obra.nombre}</span>
          <button className="btn btn-dark btn-sm" type="button" onClick={handleDetalleObraClick} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Ver Detalles" data-bs-original-title="Ver Detalles" title="Ver Detalles">
            <i className="bi bi-eye"></i>
          </button>
        </div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <span className="form-label mb-0">Cliente</span>
          <span className="label">{clienteSelected.nombre_cliente}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <span className="form-label mb-0">Dirección</span>
          <span className="label">{obra.direccion}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <span className="form-label mb-0">E-Mail</span>
          <span className="label">{obra.email}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <span className="form-label mb-0">Teléfono</span>
          <span className="label">{obra.telefono}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-1">
          <span className="form-label mb-0">Fecha Ingreso</span>
          <span className="label">{obra.fecha_ingreso}</span>
        </div>
      </div>
    );
  }

  function Comentario({ comentario }) {
    const evaluacionToEmoji = {
      1: "😊",
      2: "😐",
      3: "☹️",
    };
    const nombreCompleto = `${comentario.nombres} ${comentario.apellidos}`;
    return (
      <div className="card-inside">
        <div className="d-flex justify-content-between mb-2">
          <span className="form-label mb-o">Autor</span>
          <span className="label">{comentario.nombre_usuario}</span>
        </div>
        <div className="d-flex justify-content-between mb-2">
          <span className="form-label mb-0">Fecha</span>
          <span className="label">{comentario.fecha_evaluacion || ""}</span>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <span className="form-label mb-0">Evaluación</span>
          <span className="label">{evaluacionToEmoji[comentario.id_clasificacion]}</span>
        </div>
        <div className="border rounded bg-light p-3">
          <p className="mb-0">{comentario.observaciones}</p>
        </div>
      </div>
    );
  }

  function ReportCard({ report_data, onClick }) {

    //console.log(JSON.stringify(report_data));

    return (
      <div className="card-inside c-pointer" onClick={onClick}>
        <div className="card-title border-bottom bg-light p-2 mb-1">
          <span className="label">
            {report_data.id_report_bh && `BH-${report_data.id_report_bh}`}
            {report_data.id_report_gh && `GH-${report_data.id_report_gh}`}
            {report_data.id_report_gt && `GT-${report_data.id_report_gt}`}
          </span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <span className="form-label mb-0">Cliente</span>
          <span className="label">
            {report_data.nombre_cliente}
          </span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <span className="form-label mb-0">Fecha</span>
          <span className="label">{format(parse(report_data.fecha_report, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')}</span>
        </div>
        <div className="d-flex justify-content-between px-2 mb-1">
          <span className="form-label mb-0">Obra</span>
          <span className="label">{report_data.nombre_obra}</span>
        </div>
      </div>
    );
  }

  const handleFormClienteData = (e) => {
    const textOnly = /^[A-Za-z\s]+$/;

    switch (e.target.name) {
      case "id_region":
        obtenerComunas(e.target.value);
        setFormClienteData({
          ...formClienteData,
          [e.target.name]: e.target.value,
        });
        break;
      case "rut":
        setFormClienteData({
          ...formClienteData,
          [e.target.name]: e.target.value,
        });
        break;
      case "nombre_cliente":
        if (textOnly.test(e.target.value)) {
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "razon_social":
        if (textOnly.test(e.target.value)) {
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "nombre_representante":
        if (textOnly.test(e.target.value)) {
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "rut_representante":
        setFormClienteData({
          ...formClienteData,
          [e.target.name]: e.target.value,
        });
        break;
      case "telefono_representante":
        if (/^\d*$/.test(e.target.value)) {
          setFormClienteData({
            ...formClienteData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      default:
        setFormClienteData({
          ...formClienteData,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleFormObras = (e) => {
    if (e.target.name == "id_region") {
      obtenerComunas(e.target.value);
    }

    setFormObras({
      ...formObras,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormContacto = (e) => {
    const textOnly = /^[A-Za-z\s]+$/;
    
    switch (e.target.name) {
      case "nombres":
        if (textOnly.test(e.target.value)) {
          setFormContacto({
            ...formContacto,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormContacto({
            ...formContacto,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "apellido_paterno":
        if (textOnly.test(e.target.value)) {
          setFormContacto({
            ...formContacto,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormContacto({
            ...formContacto,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "apellido_materno":
        if (textOnly.test(e.target.value)) {
          setFormContacto({
            ...formContacto,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormContacto({
            ...formContacto,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "telefono":
        if (/^\d*$/.test(e.target.value)) {
          setFormContacto({
            ...formContacto,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "cargo":
        if (textOnly.test(e.target.value)) {
          setFormContacto({
            ...formContacto,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormContacto({
            ...formContacto,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "rut":
        setFormContacto({
          ...formContacto,
          [e.target.name]: e.target.value,
        });
        break;
      default:
        setFormContacto({
          ...formContacto,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleFormComentarios = (e) => {
    setFormComentarios({
      ...formComentarios,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = async (cliente) => {
    //alert(JSON.stringify(cliente));
    setClienteSelected(cliente);
    setReportsBH([]);
    setReportsGH([]);
    setReportsGT([]);
    setMostrarBotonEvaluacion(false);
    await obtenerDetalleCliente(cliente.id_cliente);
    await obtenerObras(cliente.id_cliente);
    await obtenerComentarios(cliente.id_cliente);
  };

  const handleSelectObra = async (obra) => {
    setObraSelected(obra);
    await obtenerDetalleObra(obra.id_obra);
    await obtenerReportsBH(obra.id_obra);
    await obtenerReportsGH(obra.id_obra);
    await obtenerReportsGT(obra.id_obra);
    setMostrarBotonEvaluacion(true);
  };

  const closeModalCliente = () => {
    setModalClienteOpen(false);
  };

  const closeModalObras = () => {
    setModalObrasOpen(false);
  };

  const closeModalComentarios = () => {
    setModalComentariosOpen(false);
  };

  const handleAddClienteClick = () => {
    setFormClienteData({
      ...formClienteData,
      ["id_usuario"]: dataLogin.id_usuario,
    });

    setModalClienteOpen(true);
  };

  const handleAddObrasClick = () => {
    setModalObrasOpen(true);
  };

  const handleAddComentariosClick = () => {
    setModalComentariosOpen(true);
  };

  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  //Obtener folios para registro de reports
  const obtenerFolios = async (idObra, idMaquinaria) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php?id_obra=${idObra}&id_maquinaria=${idMaquinaria}`
      );
      const data = await response.json();
  
      if (data && data[0].folio) {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };
  
  const handleAddReportClick = async (clienteSelected, obraSelected, maquinaria) => {
    let idTipoMaquinaria;
  
    switch (maquinaria) {
      case "BH":
        idTipoMaquinaria = '1';
        break;
      case "GH":
        idTipoMaquinaria = '2';
        break;
      case "GT":
        idTipoMaquinaria = '3';
        break;
      default:
        alert("Tipo de maquinaria no reconocido.");
        return;
    }
  
    const foliosObra = await obtenerFolios(obraSelected.id_obra, idTipoMaquinaria);
  
    if (foliosObra.length > 0) {
      switch (maquinaria) {
        case "BH":
          setModalContent(<ReportBHModalContent cliente={clienteSelected} obra={obraSelected} folios={foliosObra} />);
          break;
        case "GH":
          setModalContent(<ReportGHModalContent cliente={clienteSelected} obra={obraSelected} folios={foliosObra} />);
          break;
        case "GT":
          setModalContent(<ReportGTModalContent cliente={clienteSelected} obra={obraSelected} folios={foliosObra} />);
          break;
      }
      setModalOpen(true);
    } else {
      alert(`Debe agregar una cotización de ${maquinaria === 'BH' ? 'bomba hormigón' : maquinaria === 'GH' ? 'grúa hidráulica' : 'grúa torre'} para registrar reports a la obra.`);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const handleSubmitCliente = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioCliente();

      if (esFormularioValido) {
        const esRutValido = validarRut(formClienteData.rut);
        const esRutRepresentanteValido = validarRut(formClienteData.rut_representante);
        const esEmailValido = validarEmail(formClienteData.email_representante);

        if (!esRutValido) {
          alert("Rut del cliente no válido");
        } else if (!esRutRepresentanteValido) {
          alert("Rut del representante no válido");
        } else if (!esEmailValido) {
          alert("Email no válido");
        } else {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/cliente.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formClienteData),
            }
          );
    
          const data = await response.json();
          
          if (data.message === "Cliente agregado con éxito.") {
            alert("Cliente agregado con éxito.");
            setFormClienteData({
              rut: "",
              nombre_cliente: "",
              razon_social: "",
              giro: "",
              direccion: "",
              id_region: "",
              id_comuna: "",
              nombre_representante: "",
              rut_representante: "",
              email_representante: "",
              id_clasificacion: "1",
              id_usuario: ""
            });
            navigate("/cliente", { state: dataLogin });
            closeModalCliente();
          } else {
            throw new Error();
          }
        }
      }
    } catch (error) {
      alert("Error al agregar cliente.");
    }
  };

  const validaFormularioCliente = () => {
    if (formClienteData.rut.trim() === "") {
      alert('Debe ingresar un RUT');
      return false;
    }

    if (formClienteData.nombre_cliente.trim() === "") {
      alert('Debe ingresar el nombre del cliente');
      return false;
    }

    if (formClienteData.razon_social.trim() === "") {
      alert('Debe ingresar la razón social');
      return false;
    }

    if (formClienteData.giro.trim() === "") {
      alert('Debe ingresar el giro');
      return false;
    }

    if (formClienteData.direccion.trim() === "") {
      alert('Debe ingresar la dirección');
      return false;
    }

    if (formClienteData.id_region === "") {
      alert('Debe seleccionar una región');
      return false;
    }

    if (formClienteData.id_comuna === "") {
      alert('Debe seleccionar una comuna');
      return false;
    }

    if (formClienteData.nombre_representante === "") {
      alert('Debe ingresar el nombre del representante');
      return false;
    }

    if (formClienteData.rut_representante === "") {
      alert('Debe ingresar el rut del representante');
      return false;
    }

    if (formClienteData.telefono_representante === "") {
      alert('Debe ingresar el teléfono del representante');
      return false;
    }

    if (formClienteData.email_representante === "") {
      alert('Debe ingresar el email del representante');
      return false;
    }

    return true;
  }

  const handleSubmitObras = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioObras();

      if (esFormularioValido) {
        const esRutValido = validarRut(formContacto.rut);
        const esEmailValido = validarEmail(formContacto.email);

        if (!esRutValido) {
          alert("Rut No Válido");
        } else if (!esEmailValido) {
          alert("Email No Válido");
        } else {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/obra.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formObras),
            }
          );
    
          const data = await response.json();
          if (data.message === "Obra agregada con éxito.") {
            formClienteObra.id_obra = data.id_obra;
            formContacto.id_obra = data.id_obra;
            
            const responseCO = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(formClienteObra),
              }
            );
    
            const dataCO = await responseCO.json();
            if (dataCO.message === "Obra agregada al cliente con éxito.") {
              const responseCT = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/contacto_2.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(formContacto),
                }
              );
      
              const dataCT = await responseCT.json();
              if (dataCT.message === "Contacto agregado con éxito.") {
                alert("Obra agregada al cliente con éxito.");
                setFormObras({
                  nombre: "",
                  direccion: "",
                  id_region: "",
                  id_comuna: "",
                  id_bodega: "",
                  id_usuario: ""
                });
                setFormClienteObra({
                  id_cliente: "",
                  id_obra: "",
                  id_usuario: "",
                });
                setFormContacto({
                  id_cliente: "",
                  id_obra: "",
                  rut: "",  
                  nombres: "",
                  apellido_paterno: "",
                  apellido_materno: "",
                  telefono: "",
                  email: "",
                  cargo: "",
                  id_usuario: ""
                });
                obtenerObras(clienteSelected.id_cliente);
                obtenerComentarios(clienteSelected.id_cliente);
                navigate("/cliente", { state: dataLogin });
                closeModalObras();
              }
            }
          } else {
            throw new Error();
          }
        }
      }
    } catch (error) {
      alert("Error al agregar la Obra al cliente.");
    }
  };

  const validaFormularioObras = () => {
    if (formObras.nombre === "") {
      alert('Debe ingresar el nombre de la obra');
      return false;
    }

    if (formObras.direccion === "") {
      alert('Debe ingresar una dirección');
      return false;
    }

    if (formObras.id_region === "") {
      alert('Debe seleccionar una región');
      return false;
    }

    if (formObras.id_comuna === "") {
      alert('Debe seleccionar una comuna');
      return false;
    }

    if (formObras.id_bodega === "") {
      alert('Debe seleccionar una bodega');
      return false;
    }

    if (formContacto.rut === "") {
      alert('Debe ingresar un RUT');
      return false;
    }

    if (formContacto.dv === "") {
      alert('Debe ingresar un dígito verificador');
      return false;
    }

    if (formContacto.nombres === "") {
      alert('Debe ingresar los nombres del contacto');
      return false;
    }

    if (formContacto.apellido_paterno === "") {
      alert('Debe ingresar el apellido paterno del contacto');
      return false;
    }

    if (formContacto.apellido_materno === "") {
      alert('Debe ingresar el apellido materno del contacto');
      return false;
    }

    if (formContacto.telefono === "") {
      alert('Debe ingresar el teléfono del contacto');
      return false;
    }

    if (formContacto.email === "") {
      alert('Debe ingresar el email del contacto');
      return false;
    }

    if (formContacto.cargo === "") {
      alert('Debe ingresar el cargo del contacto');
      return false;
    }

    return true;
  }

  const handleSubmitComentarios = async (event) => {
    try {
      const esFormularioValido = validaFormularioComentarios();

      if (esFormularioValido) {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/evaluacion.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formComentarios),
          }
        );
  
        const data = await response.json();
  
        if (data.message === "Evaluación agregada con éxito.") {
          alert("Evaluación agregada con éxito.");
          setFormComentarios({
            id_cliente: "",
            id_clasificacion: "",
            observaciones: "",
            id_usuario: ""
          });
          obtenerComentarios(clienteSelected.id_cliente);
          navigate("/cliente", { state: dataLogin });
          closeModalComentarios();
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      alert("Error al agregar Evaluación.");
    }
  };

  const validaFormularioComentarios = () => {
    if (formComentarios.id_clasificacion === "") {
      alert('Debe seleccionar una evaluación');
      return false;
    }

    if (formComentarios.observaciones === "") {
      alert('Debe seleccionar una observación');
      return false;
    }

    return true;
  }

  const modalComponentCliente = (
    <Modal
      isOpen={isModalClienteOpen}
      onRequestClose={closeModalCliente}
      contentLabel="ModalCliente"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Cliente</h4>
            <button onClick={closeModalCliente} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Rut</label>
                <input
                  className="form-control"
                  name="rut"
                  value={formClienteData.rut}
                  onChange={handleFormClienteData}
                  type="text"
                  maxLength={10}
                  placeholder="xxxxxxxx-x"
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Nombre</label>
              <input
                className="form-control"
                name="nombre_cliente"
                value={formClienteData.nombre_cliente}
                onChange={handleFormClienteData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Razón Social</label>
              <input
                className="form-control"
                name="razon_social"
                value={formClienteData.razon_social}
                onChange={handleFormClienteData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Giro</label>
              <input
                className="form-control"
                name="giro"
                value={formClienteData.giro}
                onChange={handleFormClienteData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Dirección</label>
              <input
                className="form-control"
                name="direccion"
                value={formClienteData.direccion}
                onChange={handleFormClienteData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Región</label>
              {regiones.length > 0 ? (
                <select className="form-select" name="id_region" value={formClienteData.id_region} onChange={handleFormClienteData}>
                  <option value="">Seleccione Región</option>
                  {regiones.map((region, index) => (
                    <option key={index} value={region.id_region}>{region.region}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_region">
                  <option value="">Seleccione Región</option>
                </select>
              )}
            </div>
            <div className="mb-4">
              <label className="form-label">Comuna / Ciudad</label>
              {comunas.length > 0 ? (
                  <select className="form-select" name="id_comuna" value={formClienteData.id_comuna} onChange={handleFormClienteData}>
                    <option value="">Seleccione Comuna</option>
                    {comunas.map((comuna, index) => (
                      <option key={index} value={comuna.id_comuna}>{comuna.comuna}</option>
                    ))}
                  </select>
                ) : (
                  <select className="form-select" name="id_comuna">
                    <option value="">Seleccione Comuna</option>
                  </select>
                )}
            </div>
            <h5 className="mb-3">Información de Representante</h5>
            <div className="mb-3">
              <label className="form-label">Nombre Representante</label>
              <input
                className="form-control"
                name="nombre_representante"
                value={formClienteData.nombre_representante}
                onChange={handleFormClienteData}
                type="text"
              />
            </div>
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Rut Representante</label>
                <input
                  className="form-control"
                  name="rut_representante"
                  value={formClienteData.rut_representante}
                  onChange={handleFormClienteData}
                  type="text"
                  maxLength={10}
                  placeholder="xxxxxxxx-x"
                />
              </div>
              <div className="col">
                <label className="form-label">Teléfono Representante</label>
                <input
                  className="form-control"
                  name="telefono_representante"
                  value={formClienteData.telefono_representante}
                  onChange={handleFormClienteData}
                  type="text"
                  maxLength={9}
                  placeholder="9xxxxxxxx"
                />
              </div>
            </div>
            <label className="form-label">Email Representante</label>
            <input
              className="form-control"
              name="email_representante"
              value={formClienteData.email_representante}
              onChange={handleFormClienteData}
              type="email"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitCliente}><i className="bi bi-floppy"></i> Guardar Cliente</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const modalComponentObras = (
    <Modal
      isOpen={isModalObrasOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalObras}
      contentLabel="ModalObras"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Obra</h4>
            <button onClick={closeModalObras} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-2">
              <label className="form-label d-block mb-1">Cliente</label>
              <span className="label">{clienteSelected.nombre_cliente}</span>
            </div>
            <div className="mb-2">
              <label className="form-label">Nombre Obra</label>
              <input
                className="form-control"
                name="nombre"
                type="text"
                value={formObras.nombre}
                onChange={handleFormObras}
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Dirección</label>
              <input
                className="form-control"
                name="direccion"
                type="text"
                value={formObras.direccion}
                onChange={handleFormObras}
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Región</label>
              {regiones.length > 0 ? (
                <select className="form-select" name="id_region" value={formObras.id_region} onChange={handleFormObras}>
                  <option value="">Seleccione Región</option>
                  {regiones.map((region, index) => (
                    <option key={index} value={region.id_region}>{region.region}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_region">
                  <option value="">Seleccione Región</option>
                </select>
              )}
            </div>
            <div className="mb-2">
              <label className="form-label">Comuna / Ciudad</label>
              {comunas.length > 0 ? (
                <select className="form-select" name="id_comuna" value={formObras.id_comuna} onChange={handleFormObras}>
                  <option value="">Seleccione Comuna</option>
                  {comunas.map((comuna, index) => (
                    <option key={index} value={comuna.id_comuna}>{comuna.comuna}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_comuna">
                  <option value="">Seleccione Comuna</option>
                </select>
              )}
            </div>
            <div className="mb-4">
              <label className="form-label">Bodega</label>
              {bodegas.length > 0 ? (
                <select className="form-select" name="id_bodega" value={formObras.id_bodega} onChange={handleFormObras}>
                  <option value="">Seleccione Bodega</option>
                  {bodegas.map((bodega, index) => (
                    <option key={index} value={bodega.id_bodega}>{bodega.bodega}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_bodega">
                  <option value="">Seleccione Bodega</option>
                </select>
              )}
            </div>
            <h5 className="mb-3">Información de Contacto</h5>
            <div className="mb-2">
              <label className="form-label">Nombres</label>
              <input
                className="form-control"
                name="nombres"
                type="text"
                value={formContacto.nombres}
                onChange={handleFormContacto}
              />
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-2">
              <div className="col">
                <label className="form-label">Apellido Paterno</label>
                <input
                  className="form-control"
                  name="apellido_paterno"
                  type="text"
                  value={formContacto.apellido_paterno}
                  onChange={handleFormContacto}
                />
              </div>
              <div className="col">
                <label className="form-label">Apellido Materno</label>
                <input
                  className="form-control"
                  name="apellido_materno"
                  type="text"
                  value={formContacto.apellido_materno}
                  onChange={handleFormContacto}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-2">
              <div className="col">
                <label className="form-label">Rut</label>
                <input
                  className="form-control"
                  name="rut"
                  type="text"
                  maxLength={10}
                  value={formContacto.rut}
                  onChange={handleFormContacto}
                />
              </div>
              <div className="col">
                <label className="form-label">Cargo</label>
                <input
                  className="form-control"
                  name="cargo"
                  type="text"
                  value={formContacto.cargo}
                  onChange={handleFormContacto}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col">
                <label className="form-label">Teléfono</label>
                <input
                  className="form-control"
                  name="telefono"
                  type="text"
                  value={formContacto.telefono}
                  onChange={handleFormContacto}
                  maxLength={9}
                  placeholder="9xxxxxxxx"
                />
              </div>
              <div className="col">
                <label className="form-label">Email</label>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  value={formContacto.email}
                  onChange={handleFormContacto}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitObras}><i className="bi bi-floppy"></i> Guardar Obra</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const modalComponentComentarios = (
    <Modal
      isOpen={isModalComentariosOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalComentarios}
      style={modalStyles}
      contentLabel="ModalComentarios"
    >
      <button onClick={closeModalComentarios} style={styles.modalCloseButton}>
        <i className="bi-x-lg"></i>
      </button>
      <div style={styles.modalOverlay}>
        <div>
          <div className="modal-header">
            <h5 className="mb-3">Comentarios sobre el Cliente</h5>
          </div>
          <div className="mb-3">
            <label className="form-label">Nombre</label>
            <input
              className="form-control"
              name="comentario_nombre_empresa"
              value={clienteSelected.razon_social}
              type="text"
              disabled
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Evaluación</label>
            <select
              className="form-select"
              name="id_clasificacion"
              value={formComentarios.id_clasificacion}
              onChange={handleFormComentarios}
            >
              <option value="1">Positiva 😊</option>
              <option value="2">Neutra 😐</option>
              <option value="3">Negativa ☹️</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Observaciones</label>
            <textarea
              className="form-control"
              name="observaciones"
              value={formComentarios.observaciones}
              onChange={handleFormComentarios}
              type="textarea"
              rows="4"
            />
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-yellow" onClick={handleSubmitComentarios}>Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  function ReportBHModalContent({cliente, obra, folios}) {
    const detalleReportBH = Array.from({ length: 20 }, (_, index) => index + 1);

    const [formReportData, setFormReportData] = useState({
      id_cliente: "",
      id_obra: "",
      hormigonera: "",
      accesos_instalacion: false,
      distancia_tendidos_electricos: false,
      distancia_excavaciones: false,
      condiciones_terreno: false,
      plataforma_trabajo: false,
      folio: "",
      fecha_report: "",
      id_tipo_maquinaria: "",
      requiere_tuberia_adicional: true,
      requiere_traslado_bomba: true,
      bomba: "",
      operador: (dataLogin.id_perfil === 2) ? dataLogin.nombres + " " + dataLogin.apellido_paterno + " " + dataLogin.apellido_materno : "",
      tubero_1: "",
      tubero_2: "",
      tubero_3: "",
      tubero_4: "",
      tubero_5: "",
      horometro_inicial: "",
      horometro_final: "",
      elemento_hormigonado: "",
      tipo_hormigon: "",
      volumen_minimo: "",
      volumen_bombeado: 0,
      hora_salida_bodega: "",
      hora_llegada_obra: "",
      hora_solicitada_bombeo: "",
      hora_inicio_bombeo: "",
      hora_termino_bombeo: "",
      tiempo_espera: "",
      motivo_espera: "",
      hora_salida_obra: "",
      hora_llegada_bodega: "",
      observaciones: "",
      aditivo: "",
      valor_uf: "",
      id_usuario: ""
    });

    useEffect(() => {
      formReportData.id_cliente = clienteSelected.id_cliente;
      formReportData.id_obra = obraSelected.id_obra;
      formReportData.id_usuario = dataLogin.id_usuario;
      
      if (dataLogin.id_perfil === 1) {
        obtenerOperadores();
      }
      obtenerFallos(1);
    }, []);

    const [operadores, setOperadores] = useState([]);
    const obtenerOperadores = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?operadores=1`
        );
        const data = await response.json();
  
        if (data && data[0].id_usuario) {
          setOperadores(data);
          //console.log(JSON.stringify(data, null, 2))
        }
      } catch (error) {
        setOperadores([]);
      }
    };
  
    const [fallos, setFallos] = useState([]);
    const obtenerFallos = async (idTipoMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/fallos.php?id_tipo_maquinaria=${idTipoMaquinaria}`
        );
        const data = await response.json();

        if (data && data[0].id_fallo) {
          setFallos(data);
        }
      } catch (error) {
        setFallos([]);
        //alert("No hay fallos para mostrar.");
      }
    };

    const [tiposMaquinarias, setTiposMaquinarias] = useState([]);
    const obtenerTiposMaquinarias = async (folio) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/tipo_maquinaria.php?folio=${folio}`
        );
        const data = await response.json();
        if (data && data[0].id_tipo_maquinaria) {
          setTiposMaquinarias(data);
        }
      } catch (error) {
        setTiposMaquinarias([]);
      }
    };

    const obtenerVolumenMinimo = async (folio, idServicio, idTipoMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio_vm=${folio}&id_servicio_vm=${idServicio}`
        );
        const data = await response.json();
        if (data && data[0].cantidad_minima) {
          setFormReportData({
            ...formReportData,
            ["id_tipo_maquinaria"]: idTipoMaquinaria,
            ["volumen_minimo"]: data[0].cantidad_minima,
          });
        }
      } catch (error) {
        setFormReportData({
          ...formReportData,
          ["id_tipo_maquinaria"]: idTipoMaquinaria,
          ["volumen_minimo"]: "",
        });
        alert("Error al obtener el volumen mínimo (M3), verifique la cotización asociada.")
      }
    };

    const handleFormReportData = (e) => {
      const { name, value, type, checked } = e.target;

      let newData = { ...formReportData };
      newData[name] = type === 'checkbox' ? checked : value;

      switch (name) {
        case "folio":
          obtenerTiposMaquinarias(value);
          setFormReportData(newData);
          break;
        case "id_tipo_maquinaria":
          const dataConsulta = tiposMaquinarias.find(item => item.id_tipo_maquinaria === Number(value));
          if (dataConsulta) {
            obtenerVolumenMinimo(dataConsulta.folio, dataConsulta.id_servicio, value);
          } else {
            newData.volumen_minimo = "";
            setFormReportData(newData);
          }
          break;
        case "fecha_report":
          const selected = value ? new Date(value) : '';

          if (selected && selected > today) {
            newData.fecha_report = today.toISOString().split('T')[0];
          } else {
            newData.fecha_report = selected ? selected.toISOString().split('T')[0] : '';
  
            let valorUFEncontrado = null;
            indicadorUF.serie.forEach(item => {
              if (item.fecha.startsWith(selected ? selected.toISOString().split('T')[0] : '')) {
                valorUFEncontrado = item.valor;
              }
            });
  
            if (valorUFEncontrado) {
              newData.valor_uf = valorUFEncontrado;
            } else {
              newData.valor_uf = 0;
            }
          }
          setFormReportData(newData);
          break;
        case "horometro_inicial":
          if (/^\d*([\.]\d*)?$/.test(value)) {
            newData.horometro_inicial = value;
            setFormReportData(newData);
          }
          break;
        case "horometro_final":
          if (/^\d*([\.]\d*)?$/.test(value)) {
            newData.horometro_final = value;
            setFormReportData(newData);
          }
          break;
        case "hora_solicitada_bombeo":
        case "hora_inicio_bombeo":
          const horaSolicitada = newData.hora_solicitada_bombeo;
          const horaInicio = newData.hora_inicio_bombeo;

          const horaSolicitadaDate = new Date(`2000-01-01T${horaSolicitada}`);
          const horaInicioDate = new Date(`2000-01-01T${horaInicio}`);

          const diferenciaMilisegundos = horaInicioDate - horaSolicitadaDate;

          if (!isNaN(diferenciaMilisegundos) && isFinite(diferenciaMilisegundos)) {
            const horas = Math.floor(diferenciaMilisegundos / 3600000);
            const minutos = Math.floor((diferenciaMilisegundos % 3600000) / 60000);

            const horasStr = horas.toString().padStart(2, '0');
            const minutosStr = minutos.toString().padStart(2, '0');

            newData.tiempo_espera = `${horasStr}:${minutosStr}`;
          } else {
            newData.tiempo_espera = '';
          }
          setFormReportData(newData);
          break;
        default:
          setFormReportData(newData);
      }
    };

    const [dataDetalleReportBH, setDetalleReportBHData] = useState({});
    const [prevVolumenBombeado, setPrevVolumenBombeado] = useState({});
    const handleFormDetalleReportBHData = (id, inputName, value) => {
      const regex = /^(\d*([.:]\d*)?|\d{1,2}:\d{2})$/;
      if (regex.test(value)) {
        /* setDetalleReportBHData(prevState => ({
          ...prevState,
          [id]: {
            id_report_bh: '',
            id_usuario: dataLogin.id_usuario,
            ...prevState[id],
            [inputName]: value
          }
        })); */
        setDetalleReportBHData(prevState => {
          const newState = { ...prevState };
    
          // Si la clave 'id' no existe en el estado, crea un nuevo objeto para ella
          if (!newState[id]) {
            newState[id] = {
              id_report_bh: "",
              id_usuario: dataLogin.id_usuario,
              [`mixer_${id}`]: "",
              [`guia_${id}`]: "",
              [`m3_${id}`]: "",
              [`llegada_obra_${id}`]: "",
              [`descarga_inicio_${id}`]: "",
              [`descarga_termino_${id}`]: ""
            };
          }
    
          // Actualiza el campo específico dentro del objeto 'id'
          newState[id][inputName] = value;
    
          // Eliminar el objeto si todos los valores (excepto id_report_bh y id_usuario) están vacíos
          const allEmpty = Object.keys(newState[id]).every(key =>
            (key === "id_report_bh" || key === "id_usuario") || newState[id][key] === ""
          );

          if (allEmpty) {
            delete newState[id];
          }

          return newState;
        });
        
        if (inputName.includes("m3")) {
          let numericValue = parseFloat(value);
          if (isNaN(numericValue)) {
            numericValue = 0; // Si el valor es NaN, se trata como 0
          }
  
          setFormReportData(prevReportData => {
            const prevValue = prevVolumenBombeado[inputName] || 0;
            const newVolumenBombeado = prevReportData.volumen_bombeado - prevValue + numericValue;
  
            setPrevVolumenBombeado(prev => ({
              ...prev,
              [inputName]: numericValue
            }));
  
            return {
              ...prevReportData,
              volumen_bombeado: newVolumenBombeado
            };
          });
        }
      }
    };

    const modificaLlavesServiCot = (objetoOriginal) => {
      //alert(JSON.stringify(objetoOriginal))

      const nuevoObjeto = {};
      Object.keys(objetoOriginal).forEach((clave) => {
        const nuevoNombreReport = `id_report_bh`;
        const nuevoNombreMixer = `mixer`;
        const nuevoNombreGuia = `guia`;
        const nuevoNombreM3 = `m3`;
        const nuevoNombreLlegadaObra = `llegada_obra`;
        const nuevoNombreDescargaInicio = `descarga_inicio`;
        const nuevoNombreDescargaTermino = `descarga_termino`;
  
        nuevoObjeto[clave] = {
          [nuevoNombreReport]: objetoOriginal[clave][`id_report_bh`],
          [nuevoNombreMixer]: objetoOriginal[clave][`mixer_${clave}`],
          [nuevoNombreGuia]: objetoOriginal[clave][`guia_${clave}`],
          [nuevoNombreM3]: objetoOriginal[clave][`m3_${clave}`],
          [nuevoNombreLlegadaObra]: objetoOriginal[clave][`llegada_obra_${clave}`],
          [nuevoNombreDescargaInicio]: objetoOriginal[clave][`descarga_inicio_${clave}`],
          [nuevoNombreDescargaTermino]: objetoOriginal[clave][`descarga_termino_${clave}`],
        };
      });
  
      return nuevoObjeto;
    };

    const [inputCount, setInputCount] = useState(1);
    const handleAddInputTuberos = () => {
      if (inputCount < 5) {
        setInputCount(inputCount + 1);
      }
    };
    const handleRemoveInput = (index) => {
      if (inputCount > 1) {
        setInputCount(inputCount - 1);
      }
    };

    // Fallos
    const [formFallosData, setFormFallosData] = useState([]);
    const handleFormFallosData = (e) => {
      const falloSeleccionado = {
        id_report_bh: "",
        id_fallo: e.target.value,
        id_usuario: dataLogin.id_usuario
      };

      const falloIndex = formFallosData.findIndex(fallo => fallo.id_fallo === falloSeleccionado.id_fallo);

      if (falloIndex === -1) {
        setFormFallosData([...formFallosData, falloSeleccionado]);
      } else {
        const updatedFallos = formFallosData.filter((_, index) => index !== falloIndex);
        setFormFallosData(updatedFallos);
      }
    };

    const [requiereTuberiaAdicional, setRequiereTuberiaAdicional] = useState(true);
    const [requiereTrasladoBomba, setRequiereTrasladoBomba] = useState(true);

    const handleRadioChange = (e) => {
      const { name, value } = e.target;

      if (name === "requiere_tuberia_adicional") {
        setRequiereTuberiaAdicional(value === "1");

        setFormReportData({
          ...formReportData,
          ["requiere_tuberia_adicional"]: value === "1"
        });
      } else {
        setRequiereTrasladoBomba(value === "1");

        setFormReportData({
          ...formReportData,
          ["requiere_traslado_bomba"]: value === "1"
        });
      }      
    }

    const handleSubmitReportBH = async (event) => {      
      event.preventDefault();

      try {
        const esFormularioValido = validaFormularioReportBH();
        
        if (esFormularioValido) {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/report_bh.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formReportData),
            }
          );
    
          const data = await response.json();
          if (data.message === "Cabecera de report BH agregada con éxito.") {
            const objetoTransformado = modificaLlavesServiCot(dataDetalleReportBH);
  
            const detalleReportBH = Object.keys(objetoTransformado).reduce((nuevoObjeto, clave) => {
              nuevoObjeto[clave] = { ...objetoTransformado[clave], id_report_bh: data.id_report_bh, id_usuario: dataLogin.id_usuario };
              return nuevoObjeto;
            }, {});
            
            const responseDetalle = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/report_bh_detalle.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(detalleReportBH),
              }
            );
      
            const dataDetalle = await responseDetalle.json();
            if (dataDetalle.message === "Detalle de report BH agregado con éxito.") {
              const updatedFallos = formFallosData.map(fallo => ({
                ...fallo,
                id_report_bh: data.id_report_bh
              }));
  
              const responseFallos = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/report_bh_fallos.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(updatedFallos),
                }
              );
  
              const dataFallos = await responseFallos.json();
              if (dataFallos.message === "Fallos agregados con éxito.") {
                obtenerReportsBH(obraSelected.id_obra);
                alert("Report de Bomba Hormigón agregado con éxito.");
                setFormReportData({
                  id_cliente: "",
                  id_obra: "",
                  hormigonera: "",
                  accesos_instalacion: false,
                  distancia_tendidos_electricos: false,
                  distancia_excavaciones: false,
                  condiciones_terreno: false,
                  plataforma_trabajo: false,
                  folio: "",
                  fecha_report: "",
                  id_tipo_maquinaria: "",
                  requiere_tuberia_adicional: true,
                  requiere_traslado_bomba: true,
                  bomba: "",
                  operador: "",
                  tubero_1: "",
                  tubero_2: "",
                  tubero_3: "",
                  tubero_4: "",
                  tubero_5: "",
                  horometro_inicial: "",
                  horometro_final: "",
                  elemento_hormigonado: "",
                  tipo_hormigon: "",
                  volumen_minimo: "",
                  volumen_bombeado: 0,
                  hora_salida_bodega: "",
                  hora_llegada_obra: "",
                  hora_solicitada_bombeo: "",
                  hora_inicio_bombeo: "",
                  hora_termino_bombeo: "",
                  tiempo_espera: "",
                  motivo_espera: "",
                  hora_salida_obra: "",
                  hora_llegada_bodega: "",
                  observaciones: "",
                  aditivo: "",
                  valor_uf: "",
                  id_usuario: ""
                });
                setFormFallosData([]);
                setDetalleReportBHData([]);
                closeModal();
                navigate("/cliente", { state: dataLogin });
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert("Error al agregar el report de Bomba Hormigón.");
      }
    };

    const validaFormularioReportBH = () => {
      if (formReportData.hormigonera === "") {
        alert('Debe ingresar una hormigonera');
        return false;
      }

      if (formReportData.folio === "") {
        alert('Debe seleccionar el folio');
        return false;
      }
  
      if (formReportData.fecha_report === "") {
        alert('Debe indicar la fecha del report');
        return false;
      }

      if (formReportData.id_tipo_maquinaria === "") {
        alert('Debe seleccionar un tipo de maquinaria');
        return false;
      }
      
      if (formReportData.requiere_tuberia_adicional === "") {
        alert('Debe seleccionar si requiere tubería adicional');
        return false;
      }

      if (formReportData.requiere_traslado_bomba === "") {
        alert('Debe seleccionar si requiere traslado de bomba');
        return false;
      }

      if (formReportData.bomba === "") {
        alert('Debe ingresar una bomba');
        return false;
      }

      if (formReportData.operador === "") {
        alert('Debe seleccionar un operador');
        return false;
      }

      /* if (formReportData.tubero_1 === "") {
        alert('Debe ingresar al menos un tubero');
        return false;
      } */

      if (formReportData.horometro_inicial === "") {
        alert('Debe ingresar un horómetro inicial');
        return false;
      }

      if (formReportData.horometro_final === "") {
        alert('Debe ingresar un horómetro final');
        return false;
      }

      if (formReportData.elemento_hormigonado === "") {
        alert('Debe ingresar el elemento hormigonado');
        return false;
      }

      if (formReportData.tipo_hormigon === "") {
        alert('Debe ingresar el tipo de hormigón');
        return false;
      }

      if (formReportData.volumen_minimo === "") {
        alert('Debe ingresar el volumen mínimo');
        return false;
      }

      if (formReportData.volumen_bombeado === "") {
        alert('Debe ingresar el volumen bombeado');
        return false;
      }

      if (formReportData.hora_salida_bodega === "") {
        alert('Debe ingresar la hora de salida de bodega');
        return false;
      }

      if (formReportData.hora_llegada_obra === "") {
        alert('Debe ingresar la hora de llegada a obra');
        return false;
      }

      if (formReportData.hora_solicitada_bombeo === "") {
        alert('Debe ingresar la hora solicitada de bombeo');
        return false;
      }

      if (formReportData.hora_inicio_bombeo === "") {
        alert('Debe ingresar la hora de inicio de bombeo');
        return false;
      }

      if (formReportData.hora_termino_bombeo === "") {
        alert('Debe ingresar la hora de término de bombeo');
        return false;
      }

      if (formReportData.tiempo_espera === "") {
        alert('Debe ingresar el tiempo de espera');
        return false;
      }

      if (formReportData.hora_salida_obra === "") {
        alert('Debe ingresar la hora de salida de obra');
        return false;
      }

      if (formReportData.hora_llegada_bodega === "") {
        alert('Debe ingresar la hora de llegada a bodega');
        return false;
      }

      if (formReportData.aditivo === "") {
        alert('Debe ingresar las bolsas de Vittech');
        return false;
      }

      if (Object.keys(dataDetalleReportBH).length === 0) {
        alert('Debe ingresar el detalle del report');
        return false;
      } else {
        const detalleCompleto = Object.entries(dataDetalleReportBH["1"]).filter(([key]) => key !== "id_report_bh").every(([, value]) => value !== "");
        if (!detalleCompleto) {
          alert("Debe completar el detalle de las filas, de lo contrario elimine la información.");
          return false;
        }
      }

      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Report: Bomba Hormigón</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{cliente.nombre_cliente}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{formatearRut(cliente.rut)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Solicitante</span>
                    <span className="label">{obra.nombre_solicitante}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Obra</span>
                    <span className="label">{obra.nombre}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hormigonera</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="hormigonera" 
                      value={formReportData.hormigonera}
                      onChange={handleFormReportData}
                    />
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="accesos_instalacion" id="accesos_instalacion" checked={formReportData.accesos_instalacion} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="accesos_instalacion">Accesos y vías de instalación expeditas</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="distancia_tendidos_electricos" id="distancia_tendidos_electricos" checked={formReportData.distancia_tendidos_electricos} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="distancia_tendidos_electricos">Distancia optima a tendidos eléctricos</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="distancia_excavaciones" id="distancia_excavaciones" checked={formReportData.distancia_excavaciones} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="distancia_excavaciones">Distancia a excavaciones y taludes</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="condiciones_terreno" id="condiciones_terreno" checked={formReportData.condiciones_terreno} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="condiciones_terreno">Condiciones de terreno para instalación de equipo</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="plataforma_trabajo" id="plataforma_trabajo" checked={formReportData.plataforma_trabajo} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="plataforma_trabajo">Plataforma de trabajo y cuerdas de vida</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <select className="form-select form-select-sm form-select-width" name="folio" value={formReportData.folio} onChange={handleFormReportData}>
                      <option value="">Seleccione un Folio</option>
                      {folios.map((item, index) => (
                        <option key={index} value={item.folio}>{item.folio}</option>
                      ))}
                    </select>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Report</span>
                    <input 
                      className="form-control form-control-sm form-select-width" 
                      type="date" 
                      name="fecha_report" 
                      value={formReportData.fecha_report}
                      onChange={handleFormReportData}
                      min={minDate ? minDate.toISOString().split('T')[0] : undefined}
                      max={today.toISOString().split('T')[0]}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-2">Información de Bomba</h5>
            <div className="row row-cols-lg-4 mb-3">
              <div className="col">
                <label className="form-label">Tipo de bomba</label>
                <select className="form-select form-select-sm" name="id_tipo_maquinaria" value={formReportData.id_tipo_maquinaria} onChange={handleFormReportData}>
                  <option value="">Seleccione tipo de maquinaria</option>
                  {tiposMaquinarias.map((tipo_maquinaria, index) => (
                    <option key={index} value={tipo_maquinaria.id_tipo_maquinaria}>{tipo_maquinaria.tipo_maquinaria}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label className="form-label d-block">¿Traslado Bomba?</label>
                <input
                  type="radio" 
                  className="btn-check" 
                  name="requiere_traslado_bomba" 
                  id="requiere-traslado-bomba-si" 
                  autoComplete="off" 
                  value="1"
                  checked={requiereTrasladoBomba}
                  onChange={handleRadioChange}
                />
                <label className="btn py-1" htmlFor="requiere-traslado-bomba-si">Si</label>
                <input 
                  type="radio" 
                  className="btn-check" 
                  name="requiere_traslado_bomba" 
                  id="requiere-traslado-bomba-no" 
                  autoComplete="off"
                  value="0"
                  checked={!requiereTrasladoBomba}
                  onChange={handleRadioChange}
                />
                <label className="btn py-1 px-2" htmlFor="requiere-traslado-bomba-no">No</label>
              </div>
              <div className="col">
                <label className="form-label d-block">¿Tubería Acicional?</label>
                <input
                  type="radio" 
                  className="btn-check" 
                  name="requiere_tuberia_adicional" 
                  id="requiere-tuberia-adicional-si" 
                  autoComplete="off" 
                  value="1"
                  checked={requiereTuberiaAdicional}
                  onChange={handleRadioChange}
                />
                <label className="btn py-1" htmlFor="requiere-tuberia-adicional-si">Si</label>
                <input 
                  type="radio" 
                  className="btn-check" 
                  name="requiere_tuberia_adicional" 
                  id="requiere-tuberia-adicional-no" 
                  autoComplete="off"
                  value="0"
                  checked={!requiereTuberiaAdicional}
                  onChange={handleRadioChange}
                />
                <label className="btn py-1 px-2" htmlFor="requiere-tuberia-adicional-no">No</label>
              </div>
            </div>
            <div className="row row-cols-md-2">
              <div className="col">
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Bomba</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="bomba" 
                      value={formReportData.bomba} 
                      onChange={handleFormReportData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Operador</span>
                    {operadores.length > 0 ? (
                      <select className="form-select form-select-sm w-50" name="operador" value={formReportData.operador} onChange={handleFormReportData}>
                        <option value="">Seleccione Operador</option>
                        {operadores.map((operador, index) => (
                          <option key={index} value={operador.nombres + " " + operador.apellido_paterno + " " + operador.apellido_materno}>{operador.nombres + " " + operador.apellido_paterno + " " + operador.apellido_materno}</option>
                        ))}
                      </select>
                      ) : (
                        <input 
                          className="form-control form-control-sm w-50" 
                          type="text" 
                          name="operador" 
                          value={formReportData.operador} 
                          onChange={handleFormReportData}
                          disabled
                        />
                    )}
                    {/* {dataLogin.id_perfil === 2 ? (
                      <input 
                        className="form-control form-control-sm w-50" 
                        type="text" 
                        name="operador" 
                        value={formReportData.operador} 
                        onChange={handleFormReportData}
                        disabled
                      />
                    ) : (
                      <input 
                        className="form-control form-control-sm w-50" 
                        type="text" 
                        name="operador" 
                        value={formReportData.operador} 
                        onChange={handleFormReportData}
                      />
                    )} */}
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="d-flex gap-2">
                      <span className="form-label mb-0" style={styles.lineHeight}>Tuberos</span>
                      <button className="btn btn-outline-success btn-sm" onClick={handleAddInputTuberos}><i className="bi bi-plus-circle"></i></button>
                    </div>
                    <div className="d-flex flex-column w-50 gap-2">
                      {[...Array(inputCount)].map((_, index) => (
                        <div className="d-inline-flex gap-2" key={index}>
                          <input
                            className="form-control form-control-sm" 
                            type="text" 
                            name={`tubero_${index + 1}`}
                            value={formReportData[`tubero_${index + 1}`]} 
                            onChange={handleFormReportData}
                          />
                          {inputCount > 1 && (
                            <button className="btn btn-outline-danger btn-sm" onClick={() => handleRemoveInput(index)}>
                              <i className="bi bi-dash-circle"></i>
                            </button>
                          )}
                        </div>
                      ))}
                      {/* <input 
                        className="form-control form-control-sm" 
                        type="text" 
                        name="tubero_1" 
                        value={formReportData.tubero_1} 
                        onChange={handleFormReportData}
                      />
                      <input 
                        className="form-control form-control-sm" 
                        type="text" 
                        name="tubero_2" 
                        value={formReportData.tubero_2} 
                        onChange={handleFormReportData}
                      /> */}
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Horometro Inicial</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="horometro_inicial" 
                      value={formReportData.horometro_inicial} 
                      onChange={handleFormReportData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Horometro Final</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="horometro_final" 
                      value={formReportData.horometro_final} 
                      onChange={handleFormReportData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Elemento Hormigonado</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="elemento_hormigonado" 
                      value={formReportData.elemento_hormigonado} 
                      onChange={handleFormReportData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tipo de Hormigón</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="tipo_hormigon" 
                      value={formReportData.tipo_hormigon} 
                      onChange={handleFormReportData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Volumen Mínimo M3</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="volumen_minimo" 
                      value={formReportData.volumen_minimo} 
                      onChange={handleFormReportData}
                      readOnly
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Volumen Bombeado M3</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="text" 
                      name="volumen_bombeado" 
                      value={formReportData.volumen_bombeado} 
                      onChange={handleFormReportData}
                      readOnly
                    />
                  </li>
                </ul>
                <ul className="list-group mb-4">
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label">Salida Bodega</span>
                        <input 
                          className="form-control form-control-sm" 
                          type="time" 
                          name="hora_salida_bodega" 
                          value={formReportData.hora_salida_bodega} 
                          onChange={handleFormReportData}
                        />
                      </div>
                      <div className="col">
                        <span className="form-label">Llegada a Obra</span>
                        <input 
                          className="form-control form-control-sm" 
                          type="time" 
                          name="hora_llegada_obra" 
                          value={formReportData.hora_llegada_obra} 
                          onChange={handleFormReportData}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Solicitada de Bombeo</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_solicitada_bombeo" 
                      value={formReportData.hora_solicitada_bombeo} 
                      onChange={handleFormReportData}
                    />
                  </li>
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label">Hora Inicio Bombeo</span>
                        <input 
                          className="form-control form-control-sm" 
                          type="time" 
                          name="hora_inicio_bombeo" 
                          value={formReportData.hora_inicio_bombeo} 
                          onChange={handleFormReportData}
                        />
                      </div>
                      <div className="col">
                        <span className="form-label">Hora Termino Bombeo</span>
                        <input 
                          className="form-control form-control-sm" 
                          type="time" 
                          name="hora_termino_bombeo" 
                          value={formReportData.hora_termino_bombeo} 
                          onChange={handleFormReportData}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tiempo de Espera</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="tiempo_espera" 
                      value={formReportData.tiempo_espera} 
                      onChange={handleFormReportData}
                      readOnly
                    />
                  </li>
                  <li className="list-group-item">
                    <span className="form-label mb-0">Motivo de Espera</span>
                    <textarea 
                      className="form-control form-control-sm" 
                      rows={2}
                      name="motivo_espera"
                      value={formReportData.motivo_espera} 
                      onChange={handleFormReportData}
                    ></textarea>
                  </li>
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label">Salida de Obra</span>
                        <input 
                          className="form-control form-control-sm" 
                          type="time" 
                          name="hora_salida_obra" 
                          value={formReportData.hora_salida_obra} 
                          onChange={handleFormReportData}
                        />
                      </div>
                      <div className="col">
                        <span className="form-label">Llegada a Bodega</span>
                        <input 
                          className="form-control form-control-sm" 
                          type="time" 
                          name="hora_llegada_bodega" 
                          value={formReportData.hora_llegada_bodega} 
                          onChange={handleFormReportData}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                      <span className="form-label">Bolsas Vittech</span>
                      <input 
                        className="form-control form-control-sm" 
                        type="number" 
                        name="aditivo" 
                        min={0}
                        value={formReportData.aditivo} 
                        onChange={handleFormReportData}
                      />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col">
                <div className="table-responsive border rounded">
                  <table className="table table-hover table-sm text-center mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th scope="col" className="ps-2">Mixer</th>
                        <th scope="col">Guía</th>
                        <th scope="col">M3</th>
                        <th scope="col">Llegada Obra</th>
                        <th scope="col">Descarga Inicio</th>
                        <th scope="col">Descarga Termino</th>
                      </tr>
                    </thead>
                    <tbody>
                    {detalleReportBH.map((fila) => (
                      <tr key={fila}>
                        <td className="ps-2">
                          <input type="text" className="form-control form-control-sm" name={`mixer_${fila}`} value={dataDetalleReportBH[fila]?.[`mixer_${fila}`] || ''} onChange={(e) => handleFormDetalleReportBHData(`${fila}`, `mixer_${fila}`, e.target.value)}/>
                        </td>
                        <td>
                          <input type="text" className="form-control form-control-sm" name={`guia_${fila}`} value={dataDetalleReportBH[fila]?.[`guia_${fila}`] || ''} onChange={(e) => handleFormDetalleReportBHData(`${fila}`, `guia_${fila}`, e.target.value)}/>
                        </td>
                        <td>
                          <input type="text" className="form-control form-control-sm" name={`m3_${fila}`} value={dataDetalleReportBH[fila]?.[`m3_${fila}`] || ''} onChange={(e) => handleFormDetalleReportBHData(`${fila}`, `m3_${fila}`, e.target.value)}/>
                        </td>
                        <td>
                          <input type="time" className="form-control form-control-sm" name={`llegada_obra_${fila}`} value={dataDetalleReportBH[fila]?.[`llegada_obra_${fila}`] || ''} onChange={(e) => handleFormDetalleReportBHData(`${fila}`, `llegada_obra_${fila}`, e.target.value)}/>
                        </td>
                        <td>
                          <input type="time" className="form-control form-control-sm" name={`descarga_inicio_${fila}`} value={dataDetalleReportBH[fila]?.[`descarga_inicio_${fila}`] || ''} onChange={(e) => handleFormDetalleReportBHData(`${fila}`, `descarga_inicio_${fila}`, e.target.value)}/>
                        </td>
                        <td>
                          <input type="time" className="form-control form-control-sm" name={`descarga_termino_${fila}`} value={dataDetalleReportBH[fila]?.[`descarga_termino_${fila}`] || ''} onChange={(e) => handleFormDetalleReportBHData(`${fila}`, `descarga_termino_${fila}`, e.target.value)}/>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <h5 className="mb-3">Fallos Cliente</h5>
                <ul className="list-group">
                  {fallos.length > 0 ? (
                    fallos.filter(fallo => fallo.fallo_cliente === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloCliente" + index} value={fallo.id_fallo} onChange={handleFormFallosData}/>
                        <label className="form-check-label" htmlFor={"falloCliente" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos de cliente</span>
                  )}
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Fallos Vittamaq</h5>
                <ul className="list-group">
                  {fallos.length > 0 ? (
                    fallos.filter(fallo => fallo.fallo_vittamaq === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloVittamaq" + index} value={fallo.id_fallo} onChange={handleFormFallosData}/>
                        <label className="form-check-label" htmlFor={"falloVittamaq" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos de vittamaq</span>
                  )}
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Observaciones</h5>
            <textarea 
              className="form-control form-control-sm" 
              rows={3}
              name="observaciones"
              value={formReportData.observaciones} 
              onChange={handleFormReportData}
            ></textarea>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitReportBH}><i className="bi bi-floppy"></i> Guardar Report</button>
          </div>
        </div>
      </div>
    );
  }

  function ReportGHModalContent({cliente, obra, folios}) {
    const [formReportData, setFormReportData] = useState({
      id_cliente: "",
      id_obra: "",
      folio: "",
      fecha_report: "",
      inspeccion_diaria_maquina: "",
      numero: "",
      operador_grua: (dataLogin.id_perfil === 2) ? dataLogin.nombres + " " + dataLogin.apellido_paterno + " " + dataLogin.apellido_materno : "",
      ayudante: (dataLogin.id_perfil === 3) ? dataLogin.nombres + " " + dataLogin.apellido_paterno + " " + dataLogin.apellido_materno : "",
      segundo_ayudante: "",
      solicitado_por: "",
      tonelaje_grua: "",
      observaciones: "",
      id_usuario: "",
      requiere_movilizacion_grua: "1",
      requiere_traslado_contrapeso: "1"
    });

    //Asigna horas minimas de trabajo inicial
    const [currentDate, setCurrentDate] = useState(new Date());
    const [minimoHorasInicial, setMinimoHorasInicial] = useState("08:00");
    
    const esViernes = (date) => {
      return date.getDay() === 5;
    }

    const esSabado = (date) => {
      return date.getDay() === 6;
    }

    useEffect(() => {
      setDetalleReportGHData({
        ...dataDetalleReportGH,
        ["minimo_horas"]: minimoHorasInicial,
      });
    }, [minimoHorasInicial]);

    const [dataDetalleReportGH, setDetalleReportGHData] = useState({
      id_report_gh: "",
      hora_salida_bodega: "",
      hora_llegada_faena: "",
      hora_termino_faena: "",
      hora_llegada_bodega: "",
      minimo_horas: minimoHorasInicial,
      hora_inicio_colacion: "",
      hora_termino_colacion: "",
      total_horas_trabajadas: "",
      id_usuario: ""
    });

    useEffect(() => {
      setFormReportData({
        ...formReportData,
        ["id_cliente"]: clienteSelected.id_cliente,
        ["id_obra"]: obraSelected.id_obra,
        ["id_usuario"]: dataLogin.id_usuario
      });

      setDetalleReportGHData({
        ...dataDetalleReportGH,
        ["id_cliente"]: clienteSelected.id_cliente,
        ["id_obra"]: obraSelected.id_obra,
        ["id_usuario"]: dataLogin.id_usuario
      });

      if (dataLogin.id_perfil === 1) {
        obtenerOperadores();
        obtenerAyudantes();
      } else if (dataLogin.id_perfil === 2) {
        obtenerAyudantes();
      } else if (dataLogin.id_perfil === 3) {
        obtenerOperadores();
      }

      obtenerFallos(2);

      if (esViernes(currentDate)) {
        setMinimoHorasInicial("08:00");
      } else if (esSabado(currentDate)) {
        setMinimoHorasInicial("05:00");
      }
    }, []);

    const [operadores, setOperadores] = useState([]);
    const obtenerOperadores = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?operadores=1`
        );
        const data = await response.json();
  
        if (data && data[0].id_usuario) {
          setOperadores(data);
        }
      } catch (error) {
        setOperadores([]);
      }
    };

    const [ayudantes, setAyudantes] = useState([]);
    const obtenerAyudantes = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?ayudantes=1`
        );
        const data = await response.json();
  
        if (data && data[0].id_usuario) {
          setAyudantes(data);
        }
      } catch (error) {
        setAyudantes([]);
      }
    };

    const [fallos, setFallos] = useState([]);
    const obtenerFallos = async (idTipoMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/fallos.php?id_tipo_maquinaria=${idTipoMaquinaria}`
        );
        const data = await response.json();

        if (data && data[0].id_fallo) {
          setFallos(data);
        }
      } catch (error) {
        setFallos([]);
        //alert("No hay fallos para mostrar.");
      }
    };
  
    const handleFormReportData = (e) => {
      const { name, value, type, checked } = e.target;
    
      const inputValue = type === 'checkbox' ? checked : value;
    
      const updatedFormReportData = {
        ...formReportData,
        [name]: inputValue,
      };
    
      if (
        (name === 'ayudante' || name === 'segundo_ayudante') &&
        updatedFormReportData.ayudante === updatedFormReportData.segundo_ayudante
      ) {
        alert('Debe seleccionar un ayudante distinto.');
        return;
      }
    
      setFormReportData(updatedFormReportData);
    };

    const handleFormReportDetalleData = (e) => {
      /* const { name, value } = e.target;

      setDetalleReportGHData({
        ...dataDetalleReportGH,
        [name]: value,
      }); */

      const { name, value, type, checked } = e.target;

      // Obtener los valores
      let newData = { ...dataDetalleReportGH };
      newData[name] = type === 'checkbox' ? checked : value;

      if (name === 'hora_salida_bodega' || name === 'hora_inicio_colacion' || name === 'hora_termino_colacion' || name === 'hora_llegada_bodega') {
        const horaSalidaBodega = newData.hora_salida_bodega;
        const horaInicioColacion = newData.hora_inicio_colacion;
        const horaTerminoColacion = newData.hora_termino_colacion;
        const horaLlegadaBodega = newData.hora_llegada_bodega;

        const horaSalidaBodegaDate = new Date(`2000-01-01T${horaSalidaBodega}`);
        const horaInicioColacionDate = new Date(`2000-01-01T${horaInicioColacion}`);
        const horaTerminoColacionDate = new Date(`2000-01-01T${horaTerminoColacion}`);
        const horaLlegadaBodegaDate = new Date(`2000-01-01T${horaLlegadaBodega}`);

        const diferenciaMilisegundos = (horaLlegadaBodegaDate - horaSalidaBodegaDate) - (horaTerminoColacionDate - horaInicioColacionDate);

        if (!isNaN(diferenciaMilisegundos) && isFinite(diferenciaMilisegundos)) {
          const horas = Math.floor(diferenciaMilisegundos / 3600000);
          const minutos = Math.floor((diferenciaMilisegundos % 3600000) / 60000);

          let horasStr = horas.toString().padStart(2, '0');
          let minutosStr = minutos.toString().padStart(2, '0');

          if (convertirHoraAMinutos(`${horasStr}:${minutosStr}`) < convertirHoraAMinutos(minimoHorasInicial)) {
            //horasStr = '09';
            if (formFallosData.length === 0) {
              horasStr = minimoHorasInicial.split(":")[0];
            }
          }

          newData.total_horas_trabajadas = `${horasStr}:${minutosStr}`;
        } else {
          newData.total_horas_trabajadas = '';
        }
      }

      setDetalleReportGHData(newData);
    };

    /* useEffect(() => {
      console.log(JSON.stringify(formReportData,null,2))
    }, [formReportData]); */

    function convertirHoraAMinutos(hora) {
      const [horas, minutos] = hora.split(":").map(Number);
      return horas * 60 + minutos;
    }

    // Fallos
    const [formFallosData, setFormFallosData] = useState([]);
    const handleFormFallosData = (e) => {
      const falloSeleccionado = {
        id_report_gh: "",
        id_fallo: e.target.value,
        id_usuario: dataLogin.id_usuario
      };

      const falloIndex = formFallosData.findIndex(fallo => fallo.id_fallo === falloSeleccionado.id_fallo);

      if (falloIndex === -1) {
        setFormFallosData([...formFallosData, falloSeleccionado]);

        //Libera mínimo de horas y le asigna las horas reales trabajadas (Cuando existen fallos)
        const horaSalidaBodegaDate = new Date(`2000-01-01T${dataDetalleReportGH.hora_salida_bodega}`);
        const horaInicioColacionDate = new Date(`2000-01-01T${dataDetalleReportGH.hora_inicio_colacion}`);
        const horaTerminoColacionDate = new Date(`2000-01-01T${dataDetalleReportGH.hora_termino_colacion}`);
        const horaLlegadaBodegaDate = new Date(`2000-01-01T${dataDetalleReportGH.hora_llegada_bodega}`);

        const diferenciaMilisegundos = (horaLlegadaBodegaDate - horaSalidaBodegaDate) - (horaTerminoColacionDate - horaInicioColacionDate);
        if (!isNaN(diferenciaMilisegundos) && isFinite(diferenciaMilisegundos)) {
          const horas = Math.floor(diferenciaMilisegundos / 3600000);
          const minutos = Math.floor((diferenciaMilisegundos % 3600000) / 60000);

          let horasStr = horas.toString().padStart(2, '0');
          const minutosStr = minutos.toString().padStart(2, '0');

          setDetalleReportGHData({
            ...dataDetalleReportGH,
            ["minimo_horas"]: convertirHoraAMinutos(minimoHorasInicial) < convertirHoraAMinutos(dataDetalleReportGH.total_horas_trabajadas) ? minimoHorasInicial : `${horasStr}:${minutosStr}`,
            ["total_horas_trabajadas"]: `${horasStr}:${minutosStr}`,
          });
        }
      } else {
        const updatedFallos = formFallosData.filter((_, index) => index !== falloIndex);
        setFormFallosData(updatedFallos);

        //Asigna mínimo de horas inicial
        setDetalleReportGHData({
          ...dataDetalleReportGH,
          ["minimo_horas"]: minimoHorasInicial,
          ["total_horas_trabajadas"]: dataDetalleReportGH.total_horas_trabajadas === "" ? "" : (convertirHoraAMinutos(minimoHorasInicial) < convertirHoraAMinutos(dataDetalleReportGH.total_horas_trabajadas) ? dataDetalleReportGH.total_horas_trabajadas : minimoHorasInicial),
        });
      }
    };

    const handleRadioChange = (event) => {
      setFormReportData({
        ...formReportData,
        ["requiere_movilizacion_grua"]: event.target.value,
      });
    }
    const handleRadioChangeContrapeso = (event) => {
      setFormReportData({
        ...formReportData,
        ["requiere_traslado_contrapeso"]: event.target.value,
      });
    }

    const handleSubmitReportGH = async (event) => {
      event.preventDefault();
  
      try {
        const esFormularioValido = validaFormularioReportGH();

        if (esFormularioValido) {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/report_gh.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formReportData),
            }
          );
    
          const data = await response.json();
  
          //console.log(JSON.stringify(data));
    
          if (data.message === "Cabecera de report GH agregada con éxito.") {
            dataDetalleReportGH.id_report_gh = data.id_report_gh;
  
            const responseDetalle = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/report_gh_detalle.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(dataDetalleReportGH),
              }
            );
      
            const dataDetalle = await responseDetalle.json();
      
            if (dataDetalle.message === "Detalle de report GH agregado con éxito.") {
              const updatedFallos = formFallosData.map(fallo => ({
                ...fallo,
                id_report_gh: data.id_report_gh
              }));
  
              const responseFallos = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/report_gh_fallos.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(updatedFallos),
                }
              );
  
              const dataFallos = await responseFallos.json();
              if (dataFallos.message === "Fallos agregados con éxito.") {
                obtenerReportsGH(obraSelected.id_obra);
                alert("Report de Grúa Hidráulica agregado con éxito.");
                setFormReportData({
                  id_cliente: "",
                  id_obra: "",
                  folio: "",
                  fecha_report: "",
                  inspeccion_diaria_maquina: "",
                  numero: "",
                  operador_grua: "",
                  ayudante: "",
                  segundo_ayudante: "",
                  solicitado_por: "",
                  tonelaje_grua: "",
                  observaciones: "",
                  id_usuario: "",
                  requiere_movilizacion_grua: "1",
                  requiere_traslado_contrapeso: "1"
                });
                setDetalleReportGHData({
                  id_report_gh: "",
                  hora_salida_bodega: "",
                  hora_llegada_faena: "",
                  hora_termino_faena: "",
                  hora_llegada_bodega: "",
                  minimo_horas: "",
                  hora_inicio_colacion: "",
                  hora_termino_colacion: "",
                  total_horas_trabajadas: "",
                  id_usuario: ""
                });
                setFormFallosData([]);
                closeModal();
                navigate("/cliente", { state: dataLogin });
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert("Error al agregar el report de Grúa Hidráulica.");
      }
    };

    const validaFormularioReportGH = () => {
      if (formReportData.folio === "") {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (formReportData.fecha_report === "") {
        alert('Debe indicar la fecha del report');
        return false;
      }
      
      if (formReportData.numero === "") {
        alert('Debe ingresar el número de máquina');
        return false;
      }

      if (formReportData.operador_grua === "") {
        alert('Debe seleccionar un operador de grúa');
        return false;
      }

      /*if (formReportData.ayudante === "") {
        alert('Debe seleccionar un ayudante');
        return false;
      } */

      if (formReportData.solicitado_por === "") {
        alert('Debe ingresar un solicitante');
        return false;
      }

      if (formReportData.tonelaje_grua === "") {
        alert('Debe ingresar un tonleaje de grúa');
        return false;
      }

      if (dataDetalleReportGH.hora_salida_bodega === "") {
        alert('Debe ingresar la hora de salida de bodega');
        return false;
      }

      if (dataDetalleReportGH.hora_llegada_faena === "") {
        alert('Debe ingresar la hora de llegada a faena');
        return false;
      }

      if (dataDetalleReportGH.hora_termino_faena === "") {
        alert('Debe ingresar la hora de término de faena');
        return false;
      }

      if (dataDetalleReportGH.hora_llegada_bodega === "") {
        alert('Debe ingresar la hora de llegada a bodega');
        return false;
      }

      if (dataDetalleReportGH.minimo_horas === "") {
        alert('Debe ingresar el mínimo de horas');
        return false;
      }

      if (dataDetalleReportGH.hora_inicio_colacion === "") {
        alert('Debe ingresar la hora de inicio de colación');
        return false;
      }

      if (dataDetalleReportGH.hora_termino_colacion === "") {
        alert('Debe ingresar la hora de término de colación');
        return false;
      }

      if (dataDetalleReportGH.total_horas_trabajadas === "") {
        alert('Debe ingresar el total de horas trabajadas');
        return false;
      }

      if (dataDetalleReportGH.minimo_horas != "" && dataDetalleReportGH.total_horas_trabajadas != "") {
        if (convertirHoraAMinutos(dataDetalleReportGH.total_horas_trabajadas) < convertirHoraAMinutos(dataDetalleReportGH.minimo_horas)) {
          alert('El total de horas trabajadas no puede ser menor al mínimo de horas');
          return false;
        }
      }

      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Report: Grúa Hidráulica</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut Cliente</span>
                    <span className="label">{formatearRut(cliente.rut)}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="inspeccion_diaria_maquina" id="inspeccion_diaria_maquina" checked={formReportData.accesos_instalacion} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="inspeccion_diaria_maquina">Inspección diaria de máquina</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-lg-between align-items-lg-center mb-3">
              <h5 className="mb-0">Información de Grúa</h5>
              <div className="d-inline-flex flex-row align-items-center gap-2">
                <label className="form-label mb-0">Folio:</label>
                <select className="form-select" name="folio" value={formReportData.folio} onChange={handleFormReportData}>
                  <option value="">Seleccione un Folio</option>
                  {folios.map((item, index) => (
                    <option key={index} value={item.folio}>{item.folio}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Cliente</label>
                <input 
                  className="form-control"
                  value={cliente.nombre_cliente}
                  disabled
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Report</label>
                  <input 
                    className="form-control" 
                    type="date" 
                    name="fecha_report" 
                    value={formReportData.fecha_report} 
                    onChange={handleFormReportData}
                    min={minDate.toISOString().split('T')[0]}
                    max={today.toISOString().split('T')[0]}
                  />
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Lugar de Faena / Obra</label>
                <input 
                  className="form-control"
                  value={obra.nombre}
                  disabled
                />
              </div>
              <div className="col">
                <div className="row row-cols-lg-3">
                  <div className="col">
                    <label className="form-label">Número</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="numero"
                      value={formReportData.numero}
                      onChange={handleFormReportData}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label d-block">¿Movilización Grúa?</label>
                    <div className="d-flex justify-content-md-between">
                      <div>
                        <input
                          type="radio" 
                          className="btn-check" 
                          name="movilizacion_grua_req" 
                          id="movilizacion-grua-si-req" 
                          autoComplete="off" 
                          value="1"
                          onChange={handleRadioChange}
                          defaultChecked
                        />
                        <label className="btn" htmlFor="movilizacion-grua-si-req">Si</label>
                        <input 
                          type="radio" 
                          className="btn-check" 
                          name="movilizacion_grua_req" 
                          id="movilizacion-grua-no-req" 
                          autoComplete="off"
                          value="0"
                          onChange={handleRadioChange}
                        />
                        <label className="btn" htmlFor="movilizacion-grua-no-req">No</label>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <label className="form-label d-block">¿Traslado de Contrapesos?</label>
                    <div className="d-flex justify-content-md-between">
                      <div>
                        <input
                          type="radio" 
                          className="btn-check" 
                          name="traslado_contrapeso_req" 
                          id="traslado_contrapeso-si-req" 
                          autoComplete="off" 
                          value="1"
                          onChange={handleRadioChangeContrapeso}
                          defaultChecked
                        />
                        <label className="btn" htmlFor="traslado_contrapeso-si-req">Si</label>
                        <input 
                          type="radio" 
                          className="btn-check" 
                          name="traslado_contrapeso_req" 
                          id="traslado_contrapeso-no-req" 
                          autoComplete="off"
                          value="0"
                          onChange={handleRadioChangeContrapeso}
                        />
                        <label className="btn" htmlFor="traslado_contrapeso-no-req">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Solicitado Por</label>
                <input 
                  className="form-control"
                  type="text" 
                  name="solicitado_por"
                  value={formReportData.solicitado_por}
                  onChange={handleFormReportData}
                />
              </div>
              <div className="col">
                <label className="form-label">Tonelaje Grúa</label>
                <input 
                  className="form-control" 
                  type="text" 
                  name="tonelaje_grua"
                  value={formReportData.tonelaje_grua}
                  onChange={handleFormReportData}
                />
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Operador Grúa</label>
                {/* <input 
                  className="form-control"
                  type="text" 
                  name="operador_grua"
                  value={formReportData.operador_grua}
                  onChange={handleFormReportData}
                /> */}
                {operadores.length > 0 ? (
                  <select className="form-select" name="operador_grua" value={formReportData.operador_grua} onChange={handleFormReportData}>
                    <option value="">Seleccione Operador</option>
                    {operadores.map((operador, index) => (
                      <option key={index} value={operador.nombres + " " + operador.apellido_paterno + " " + operador.apellido_materno}>{operador.nombres + " " + operador.apellido_paterno + " " + operador.apellido_materno}</option>
                    ))}
                  </select>
                  ) : (
                    <input 
                      className="form-control" 
                      type="text" 
                      name="operador_grua" 
                      value={formReportData.operador_grua} 
                      onChange={handleFormReportData}
                      disabled
                    />
                )}
              </div>
              <div className="col">
                <label className="form-label">Ayudante</label>
                {/* <input 
                  className="form-control" 
                  type="text" 
                  name="ayudante"
                  value={formReportData.ayudante}
                  onChange={handleFormReportData}
                /> */}
                {ayudantes.length > 0 ? (
                  <select className="form-select" name="ayudante" value={formReportData.ayudante} onChange={handleFormReportData}>
                    <option value="">Seleccione Ayudante</option>
                    {ayudantes.map((ayudante, index) => (
                      <option key={index} value={ayudante.nombres + " " + ayudante.apellido_paterno + " " + ayudante.apellido_materno}>{ayudante.nombres + " " + ayudante.apellido_paterno + " " + ayudante.apellido_materno}</option>
                    ))}
                  </select>
                  ) : (
                    <input 
                      className="form-control" 
                      type="text" 
                      name="ayudante" 
                      value={formReportData.ayudante} 
                      onChange={handleFormReportData}
                      disabled
                    />
                )}
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-4">
              <div className="col">
                <label className="form-label">Segundo Ayudante</label>
                {ayudantes.length > 0 ? (
                  <select className="form-select" name="segundo_ayudante" value={formReportData.segundo_ayudante} onChange={handleFormReportData}>
                    <option value="">Seleccione Segundo Ayudante</option>
                    {ayudantes.map((ayudante, index) => (
                      <option key={index} value={ayudante.nombres + " " + ayudante.apellido_paterno + " " + ayudante.apellido_materno}>{ayudante.nombres + " " + ayudante.apellido_paterno + " " + ayudante.apellido_materno}</option>
                    ))}
                  </select>
                  ) : (
                    <input 
                      className="form-control" 
                      type="text" 
                      name="ayudante" 
                      value={formReportData.ayudante} 
                      onChange={handleFormReportData}
                      disabled
                    />
                )}
              </div>
            </div>
            <h5 className="mb-3">Horarios</h5>
            <div className="row row-cols-lg-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Salida Bodega</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_salida_bodega" 
                      value={dataDetalleReportGH.hora_salida_bodega} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Llegada Faena</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_llegada_faena" 
                      value={dataDetalleReportGH.hora_llegada_faena} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Término Faena</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_termino_faena" 
                      value={dataDetalleReportGH.hora_termino_faena} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Llegada Bodega</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_llegada_bodega" 
                      value={dataDetalleReportGH.hora_llegada_bodega} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Mínimo de Horas</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="minimo_horas" 
                      value={dataDetalleReportGH.minimo_horas} 
                      onChange={handleFormReportDetalleData}
                      disabled
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Inicio</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_inicio_colacion" 
                      value={dataDetalleReportGH.hora_inicio_colacion}  
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Término</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_termino_colacion" 
                      value={dataDetalleReportGH.hora_termino_colacion}  
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Total Horas Trabajadas</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="total_horas_trabajadas" 
                      value={dataDetalleReportGH.total_horas_trabajadas}  
                      onChange={handleFormReportDetalleData}
                      disabled
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <h5 className="mb-3">Fallos Cliente</h5>
                <ul className="list-group">
                  {fallos.length > 0 ? (
                    fallos.filter(fallo => fallo.fallo_cliente === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloCliente" + index} value={fallo.id_fallo} onChange={handleFormFallosData}/>
                        <label className="form-check-label" htmlFor={"falloCliente" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos de cliente</span>
                  )}
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Fallos Vittamaq</h5>
                <ul className="list-group">
                  {fallos.length > 0 ? (
                    fallos.filter(fallo => fallo.fallo_vittamaq === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloVittamaq" + index} value={fallo.id_fallo} onChange={handleFormFallosData}/>
                        <label className="form-check-label" htmlFor={"falloVittamaq" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos de vittamaq</span>
                  )}
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Observaciones</h5>
            <textarea 
              className="form-control form-control-sm" 
              rows={3}
              name="observaciones"
              value={formReportData.observaciones} 
              onChange={handleFormReportData}
            ></textarea>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitReportGH}><i className="bi bi-floppy"></i> Guardar Report</button>
          </div>
        </div>
      </div>
    );
  }

  function ReportGTModalContent({cliente, obra, folios}) {
    const [formReportData, setFormReportData] = useState({
      id_cliente: "",
      id_obra: "",
      folio: "",
      fecha_report: "",
      inspeccion_diaria_maquina: "",
      maquina: "",
      numero: "",
      modelo: "",
      operador_grua: (dataLogin.id_perfil === 2) ? dataLogin.nombres + " " + dataLogin.apellido_paterno + " " + dataLogin.apellido_materno : "",
      observaciones: "",
      id_usuario: ""
    });

    //Asigna horas minimas de trabajo inicial
    const [currentDate, setCurrentDate] = useState(new Date());
    const [minimoHorasInicial, setMinimoHorasInicial] = useState("09:00");
    
    const esViernes = (date) => {
      return date.getDay() === 5;
    }

    const esSabado = (date) => {
      return date.getDay() === 6;
    }

    useEffect(() => {
      setDetalleReportGTData({
        ...dataDetalleReportGT,
        ["minimo_horas"]: minimoHorasInicial,
      });
    }, [minimoHorasInicial]);

    const [dataDetalleReportGT, setDetalleReportGTData] = useState({
      id_report_gt: "",
      hora_inicio_manana: "",
      hora_termino_manana: "",
      hora_inicio_tarde: "",
      hora_termino_tarde: "",
      minimo_horas: minimoHorasInicial,
      hora_inicio_colacion: "",
      hora_termino_colacion: "",
      total_horas_trabajadas: "",
      id_usuario: ""
    });

    const [inputDisabled, setInputDisabled] = useState(false);
    useEffect(() => {
      //Asigna valores de colación en 00:00 en caso de ser sábado y trabajar hasta las 13:00
      if (esSabado(currentDate) && dataDetalleReportGT.hora_inicio_tarde != "" || dataDetalleReportGT.hora_termino_tarde != "") {
        if (dataDetalleReportGT.hora_inicio_tarde === "00:00" && dataDetalleReportGT.hora_termino_tarde === "00:00") {
          setInputDisabled(!inputDisabled);
          setDetalleReportGTData({
            ...dataDetalleReportGT,
            ["hora_inicio_colacion"]: "00:00",
            ["hora_termino_colacion"]: "00:00"
          });
        } else {
          setInputDisabled(false);
        }
      }
    }, [dataDetalleReportGT.hora_inicio_tarde, dataDetalleReportGT.hora_termino_tarde]);

    useEffect(() => {
      setFormReportData({
        ...formReportData,
        ["id_cliente"]: clienteSelected.id_cliente,
        ["id_obra"]: obraSelected.id_obra,
        ["id_usuario"]: dataLogin.id_usuario
      });

      setDetalleReportGTData({
        ...dataDetalleReportGT,
        ["id_cliente"]: clienteSelected.id_cliente,
        ["id_obra"]: obraSelected.id_obra,
        ["id_usuario"]: dataLogin.id_usuario
      });

      if (dataLogin.id_perfil === 1) {
        obtenerOperadores();
      }

      obtenerFallos(3);

      if (esViernes(currentDate)) {
        setMinimoHorasInicial("08:00");
      } else if (esSabado(currentDate)) {
        setMinimoHorasInicial("05:00");
      }
    }, []);

    const [operadores, setOperadores] = useState([]);
    const obtenerOperadores = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?operadores=1`
        );
        const data = await response.json();
  
        if (data && data[0].id_usuario) {
          setOperadores(data);
        }
      } catch (error) {
        setOperadores([]);
      }
    };

    const [fallos, setFallos] = useState([]);
    const obtenerFallos = async (idTipoMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/fallos.php?id_tipo_maquinaria=${idTipoMaquinaria}`
        );
        const data = await response.json();

        if (data && data[0].id_fallo) {
          setFallos(data);
        }
      } catch (error) {
        setFallos([]);
        //alert("No hay fallos para mostrar.");
      }
    };
  
    const handleFormReportData = (e) => {
      const { name, value, type, checked } = e.target;

      const inputValue = type === 'checkbox' ? checked : value;

      setFormReportData({
        ...formReportData,
        [name]: inputValue,
      });

      //console.log(JSON.stringify(formReportData));
    };

    const handleFormReportDetalleData = (e) => {
      /* const { name, value } = e.target;

      setDetalleReportGTData({
        ...dataDetalleReportGT,
        [name]: value,
      }); */

      const { name, value, type, checked } = e.target;

      // Obtener los valores
      let newData = { ...dataDetalleReportGT };
      newData[name] = type === 'checkbox' ? checked : value;

      if (name === 'hora_inicio_manana' || name === 'hora_termino_manana' || name === 'hora_inicio_tarde' || name === 'hora_termino_tarde') {
        const horaInicioManana = newData.hora_inicio_manana;
        const horaTerminoManana = newData.hora_termino_manana;
        const horaInicioTarde = newData.hora_inicio_tarde;
        const horaTerminoTarde = newData.hora_termino_tarde;

        const horaInicioMananaDate = new Date(`2000-01-01T${horaInicioManana}`);
        const horaTerminoMananaDate = new Date(`2000-01-01T${horaTerminoManana}`);
        const horaInicioTardeDate = new Date(`2000-01-01T${horaInicioTarde}`);
        const horaTerminoTardeDate = new Date(`2000-01-01T${horaTerminoTarde}`);

        const diferenciaMilisegundos = ((horaTerminoMananaDate - horaInicioMananaDate) + (horaTerminoTardeDate - horaInicioTardeDate));

        if (!isNaN(diferenciaMilisegundos) && isFinite(diferenciaMilisegundos)) {
          const horas = Math.floor(diferenciaMilisegundos / 3600000);
          const minutos = Math.floor((diferenciaMilisegundos % 3600000) / 60000);

          let horasStr = horas.toString().padStart(2, '0');
          let minutosStr = minutos.toString().padStart(2, '0');

          if (convertirHoraAMinutos(`${horasStr}:${minutosStr}`) < convertirHoraAMinutos(minimoHorasInicial)) {
            //horasStr = '09';
            if (formFallosData.length === 0) {
              horasStr = minimoHorasInicial.split(":")[0];
            }
          }

          newData.total_horas_trabajadas = `${horasStr}:${minutosStr}`;
        } else {
          newData.total_horas_trabajadas = '';
        }
      }

      setDetalleReportGTData(newData);
    };

    /* useEffect(() => {
      console.log(JSON.stringify(dataDetalleReportGT,null,2))
    }, [dataDetalleReportGT]); */
    
    function convertirHoraAMinutos(hora) {
      const [horas, minutos] = hora.split(":").map(Number);
      return horas * 60 + minutos;
    }

    // Fallos
    const [formFallosData, setFormFallosData] = useState([]);
    const handleFormFallosData = (e) => {
      const falloSeleccionado = {
        id_report_gt: "",
        id_fallo: e.target.value,
        id_usuario: dataLogin.id_usuario
      };

      const falloIndex = formFallosData.findIndex(fallo => fallo.id_fallo === falloSeleccionado.id_fallo);

      if (falloIndex === -1) {
        setFormFallosData([...formFallosData, falloSeleccionado]);

        const horaInicioMananaDate = new Date(`2000-01-01T${dataDetalleReportGT.hora_inicio_manana}`);
        const horaTerminoMananaDate = new Date(`2000-01-01T${dataDetalleReportGT.hora_termino_manana}`);
        const horaInicioTardeDate = new Date(`2000-01-01T${dataDetalleReportGT.hora_inicio_tarde}`);
        const horaTerminoTardeDate = new Date(`2000-01-01T${dataDetalleReportGT.hora_termino_tarde}`);

        const diferenciaMilisegundos = ((horaTerminoMananaDate - horaInicioMananaDate) + (horaTerminoTardeDate - horaInicioTardeDate));

        if (!isNaN(diferenciaMilisegundos) && isFinite(diferenciaMilisegundos)) {
          const horas = Math.floor(diferenciaMilisegundos / 3600000);
          const minutos = Math.floor((diferenciaMilisegundos % 3600000) / 60000);

          let horasStr = horas.toString().padStart(2, '0');
          let minutosStr = minutos.toString().padStart(2, '0');

          setDetalleReportGTData({
            ...dataDetalleReportGT,
            ["minimo_horas"]: convertirHoraAMinutos(minimoHorasInicial) < convertirHoraAMinutos(dataDetalleReportGT.total_horas_trabajadas) ? minimoHorasInicial : `${horasStr}:${minutosStr}`,
            ["total_horas_trabajadas"]: `${horasStr}:${minutosStr}`,
          });
        }
      } else {
        const updatedFallos = formFallosData.filter((_, index) => index !== falloIndex);
        setFormFallosData(updatedFallos);

        //Asigna mínimo de horas inicial
        setDetalleReportGTData({
          ...dataDetalleReportGT,
          ["minimo_horas"]: minimoHorasInicial,
          ["total_horas_trabajadas"]: dataDetalleReportGT.total_horas_trabajadas === "" ? "" : (convertirHoraAMinutos(minimoHorasInicial) < convertirHoraAMinutos(dataDetalleReportGT.total_horas_trabajadas) ? dataDetalleReportGT.total_horas_trabajadas : minimoHorasInicial),
        });
      }
    };

    const handleSubmitReportGT = async (event) => {
      event.preventDefault();
  
      try {
        const esFormularioValido = validaFormularioReportGT();

        if (esFormularioValido) {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/report_gt.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formReportData),
            }
          );
    
          const data = await response.json();
  
          //console.log(JSON.stringify(data));
          if (data.message === "Cabecera de report GT agregada con éxito.") {
            dataDetalleReportGT.id_report_gt = data.id_report_gt;
  
            const responseDetalle = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/report_gt_detalle.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(dataDetalleReportGT),
              }
            );
      
            const dataDetalle = await responseDetalle.json();
      
            if (dataDetalle.message === "Detalle de report GT agregado con éxito.") {
              const updatedFallos = formFallosData.map(fallo => ({
                ...fallo,
                id_report_gt: data.id_report_gt
              }));
  
              const responseFallos = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/report_gt_fallos.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(updatedFallos),
                }
              );
  
              const dataFallos = await responseFallos.json();
              if (dataFallos.message === "Fallos agregados con éxito.") {
                obtenerReportsGT(obraSelected.id_obra);
                alert("Report de Grúa Torre agregado con éxito.");
                setFormReportData({
                  id_cliente: "",
                  id_obra: "",
                  folio: "",
                  fecha_report: "",
                  inspeccion_diaria_maquina: "",
                  maquina: "",
                  numero: "",
                  modelo: "",
                  operador_grua: "",
                  observaciones: "",
                  id_usuario: ""
                });
                setDetalleReportGTData({
                  id_report_gt: "",
                  hora_inicio_manana: "",
                  hora_termino_manana: "",
                  hora_inicio_tarde: "",
                  hora_termino_tarde: "",
                  minimo_horas: "",
                  hora_inicio_colacion: "",
                  hora_termino_colacion: "",
                  total_horas_trabajadas: "",
                  id_usuario: ""
                });
                setFormFallosData([]);
                closeModal();
                navigate("/cliente", { state: dataLogin });
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert("Error al agregar el report de Grúa Torre.");
      }
    };

    const validaFormularioReportGT = () => {
      if (formReportData.folio === "") {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (formReportData.fecha_report === "") {
        alert('Debe indicar la fecha del report');
        return false;
      }

      if (formReportData.maquina === "") {
        alert('Debe ingresar una máquina');
        return false;
      }
      
      if (formReportData.numero === "") {
        alert('Debe ingresar el número de máquina');
        return false;
      }

      if (formReportData.modelo === "") {
        alert('Debe ingresar el modelo de la máquina');
        return false;
      }

      if (formReportData.operador_grua === "") {
        alert('Debe seleccionar un operador de grúa');
        return false;
      }

      if (dataDetalleReportGT.hora_inicio_manana === "") {
        alert('Debe ingresar la hora de inicio de mañana');
        return false;
      }

      if (dataDetalleReportGT.hora_termino_manana === "") {
        alert('Debe ingresar la hora de término de mañana');
        return false;
      }

      if (dataDetalleReportGT.hora_inicio_tarde === "") {
        alert('Debe ingresar la hora de inicio de tarde');
        return false;
      }

      if (dataDetalleReportGT.hora_termino_tarde === "") {
        alert('Debe ingresar la hora de término de tarde');
        return false;
      }

      if (dataDetalleReportGT.minimo_horas === "") {
        alert('Debe ingresar el mínimo de horas');
        return false;
      }

      if (dataDetalleReportGT.hora_inicio_colacion === "") {
        alert('Debe ingresar la hora de inicio de colación');
        return false;
      }

      if (dataDetalleReportGT.hora_termino_colacion === "") {
        alert('Debe ingresar la hora de término de colación');
        return false;
      }

      if (dataDetalleReportGT.total_horas_trabajadas === "") {
        alert('Debe ingresar el total de horas trabajadas');
        return false;
      }

      // Valida las horas trabajadas según minimo de horas
      if (dataDetalleReportGT.minimo_horas != "" && dataDetalleReportGT.total_horas_trabajadas != "") {
        const horaTerminoManana = new Date(`01/01/2000 ${dataDetalleReportGT.hora_termino_manana}`);
        let minimoHorasDate = new Date(`01/01/2000 ${dataDetalleReportGT.minimo_horas}`);
        const totalHorasDate = new Date(`01/01/2000 ${dataDetalleReportGT.total_horas_trabajadas}`);
        const horarioTrabajoSabado = new Date(`01/01/2000 13:00`);

        if (esSabado(currentDate) && horaTerminoManana <= horarioTrabajoSabado && dataDetalleReportGT.hora_inicio_tarde === "00:00" && dataDetalleReportGT.hora_termino_tarde === "00:00") {
          minimoHorasDate = new Date(`01/01/2000 05:00`);
          setMinimoHorasInicial("05:00");
        }

        if (totalHorasDate < minimoHorasDate) {
          alert('El total de horas trabajadas es menor que el mínimo de horas');
          return false;
        }
      }

      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Report: Grúa Torre</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut Cliente</span>
                    <span className="label">{formatearRut(cliente.rut)}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="inspeccion_diaria_maquina" id="inspeccion_diaria_maquina" checked={formReportData.accesos_instalacion} onChange={handleFormReportData}/>
                    <label className="form-check-label" htmlFor="inspeccion_diaria_maquina">Inspección diaria de máquina</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-lg-between align-items-lg-center mb-3">
              <h5 className="mb-0">Información de Grúa</h5>
              <div className="d-inline-flex flex-row align-items-center gap-2">
                <label className="form-label mb-0">Folio:</label>
                <select className="form-select" name="folio" value={formReportData.folio} onChange={handleFormReportData}>
                  <option value="">Seleccione un Folio</option>
                  {folios.map((item, index) => (
                    <option key={index} value={item.folio}>{item.folio}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Cliente</label>
                <input 
                  className="form-control"
                  value={cliente.nombre_cliente}
                  disabled
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Report</label>
                  <input 
                    className="form-control" 
                    type="date" 
                    name="fecha_report" 
                    value={formReportData.fecha_report} 
                    onChange={handleFormReportData}
                    min={minDate.toISOString().split('T')[0]}
                    max={today.toISOString().split('T')[0]}
                  />
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Lugar de Faena / Obra</label>
                <input 
                  className="form-control"
                  value={obra.nombre}
                  disabled
                />
              </div>
              <div className="col">
                <div className="row row-cols-lg-3">
                  <div className="col">
                    <label className="form-label">Máquina</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="maquina"
                      value={formReportData.maquina}
                      onChange={handleFormReportData}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Número</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="numero"
                      value={formReportData.numero}
                      onChange={handleFormReportData}
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Modelo</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="modelo"
                      value={formReportData.modelo}
                      onChange={handleFormReportData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <label className="form-label">Operador Grúa</label>
                {/* <input 
                  className="form-control"
                  type="text" 
                  name="operador_grua"
                  value={formReportData.operador_grua}
                  onChange={handleFormReportData}
                /> */}
                {operadores.length > 0 ? (
                  <select className="form-select" name="operador_grua" value={formReportData.operador_grua} onChange={handleFormReportData}>
                    <option value="">Seleccione Operador</option>
                    {operadores.map((operador, index) => (
                      <option key={index} value={operador.nombres + " " + operador.apellido_paterno + " " + operador.apellido_materno}>{operador.nombres + " " + operador.apellido_paterno + " " + operador.apellido_materno}</option>
                    ))}
                  </select>
                  ) : (
                    <input 
                      className="form-control" 
                      type="text" 
                      name="operador_grua" 
                      value={formReportData.operador_grua} 
                      onChange={handleFormReportData}
                      disabled
                    />
                )}
              </div>
            </div>
            <h5 className="mb-3">Horarios</h5>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Inicio Mañana</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_inicio_manana" 
                      value={dataDetalleReportGT.hora_inicio_manana} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Término Mañana</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_termino_manana" 
                      value={dataDetalleReportGT.hora_termino_manana} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Inicio Tarde</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_inicio_tarde" 
                      value={dataDetalleReportGT.hora_inicio_tarde} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Término Tarde</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_termino_tarde" 
                      value={dataDetalleReportGT.hora_termino_tarde} 
                      onChange={handleFormReportDetalleData}
                    />
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Mínimo de Horas</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="minimo_horas" 
                      value={dataDetalleReportGT.minimo_horas} 
                      onChange={handleFormReportDetalleData}
                      disabled
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Inicio</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_inicio_colacion" 
                      value={dataDetalleReportGT.hora_inicio_colacion}  
                      onChange={handleFormReportDetalleData}
                      disabled={inputDisabled}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Término</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="hora_termino_colacion" 
                      value={dataDetalleReportGT.hora_termino_colacion}  
                      onChange={handleFormReportDetalleData}
                      disabled={inputDisabled}
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Total Horas Trabajadas</span>
                    <input 
                      className="form-control form-control-sm w-50" 
                      type="time" 
                      name="total_horas_trabajadas" 
                      value={dataDetalleReportGT.total_horas_trabajadas}  
                      onChange={handleFormReportDetalleData}
                      disabled
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <h5 className="mb-3">Fallos Cliente</h5>
                <ul className="list-group">
                  {fallos.length > 0 ? (
                    fallos.filter(fallo => fallo.fallo_cliente === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloCliente" + index} value={fallo.id_fallo} onChange={handleFormFallosData}/>
                        <label className="form-check-label" htmlFor={"falloCliente" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos de cliente</span>
                  )}
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Fallos Vittamaq</h5>
                <ul className="list-group">
                  {fallos.length > 0 ? (
                    fallos.filter(fallo => fallo.fallo_vittamaq === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloVittamaq" + index} value={fallo.id_fallo} onChange={handleFormFallosData}/>
                        <label className="form-check-label" htmlFor={"falloVittamaq" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos de vittamaq</span>
                  )}
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Observaciones</h5>
            <textarea 
              className="form-control form-control-sm" 
              rows={3}
              name="observaciones"
              value={formReportData.observaciones} 
              onChange={handleFormReportData}
            ></textarea>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitReportGT}><i className="bi bi-floppy"></i> Guardar Report</button>
          </div>
        </div>
      </div>
    );
  }


  const [filtroCliente, setFiltroCliente] = useState('');
  const [filtroObra, setFiltroObra] = useState('');
  const [filtroReport, setFiltroReport] = useState('');

  const handleFiltroCliente = (event) => {
    setFiltroCliente(event.target.value);
  };
  const handleFiltroObra = (event) => {
    setFiltroObra(event.target.value);
  };
  const handleFiltroReport = (event) => {
    setFiltroReport(event.target.value);
  };

  const clientesFiltrados = clientes.filter((item) =>
    item.nombre_cliente.toLowerCase().includes(filtroCliente.toLowerCase())
  );

  let obrasFiltradas = [];
  if (obras.length > 0) {
    obrasFiltradas = obras.filter((item) =>
      item.nombre.toLowerCase().includes(filtroObra.toLowerCase())
    );
  }

  /* const reportFiltrados = reportsBH.filter((item) =>
    item.id_report.toLowerCase().includes(filtroReport.toLowerCase())
  ); */

  const handleDetalleClienteClick = async () => {
    setModalDetalleClienteOpen(true);
  };

  const closeModalDetalleCliente = () => {
    setModalDetalleClienteOpen(false);
  };

  const handleDetalleObraClick = async () => {
    setModalDetalleObraOpen(true);
  };

  const closeModalDetalleObra = () => {
    setModalDetalleObraOpen(false);
  };

  const [formClienteEditData, setFormClienteEditData] = useState({});
  const handleEditarClienteClick = async () => {
    setModalDetalleClienteOpen(false);

    setFormClienteEditData({
      ...formClienteEditData,
      ["id_cliente"]: detalleCliente.length > 0 && detalleCliente[0].id_cliente,
      ["rut"]: detalleCliente.length > 0 && detalleCliente[0].rut || "",
      ["nombre_cliente"]: clienteSelected.nombre_cliente || "",
      ["razon_social"]: detalleCliente.length > 0 && detalleCliente[0].razon_social || "",
      ["giro"]: detalleCliente.length > 0 && detalleCliente[0].giro || "",
      ["direccion"]: detalleCliente.length > 0 && detalleCliente[0].direccion || "",
      ["id_region"]: detalleCliente.length > 0 && detalleCliente[0].id_region || "",
      ["id_comuna"]: detalleCliente.length > 0 && detalleCliente[0].id_comuna || "",
      ["nombre_representante"]: detalleCliente.length > 0 && detalleCliente[0].nombre_representante || "",
      ["rut_representante"]: detalleCliente.length > 0 && detalleCliente[0].rut_representante || "",
      ["telefono_representante"]: detalleCliente.length > 0 && detalleCliente[0].telefono_representante || "",
      ["email_representante"]: detalleCliente.length > 0 && detalleCliente[0].email_representante || ""
    });

    setModalEditarClienteOpen(true);
  };

  const handleFormClienteEditData = (e) => {
    const textOnly = /^[A-Za-z\s]+$/;

    switch (e.target.name) {
      case "id_region":
        obtenerComunas(e.target.value);
        formClienteEditData.id_comuna = "";
        setFormClienteEditData({
          ...formClienteEditData,
          [e.target.name]: e.target.value,
        });
        break;
      case "rut":
        setFormClienteEditData({
          ...formClienteEditData,
          [e.target.name]: e.target.value,
        });
        break;
      case "nombre_cliente":
        if (textOnly.test(e.target.value)) {
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "razon_social":
        if (textOnly.test(e.target.value)) {
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "nombre_representante":
        if (textOnly.test(e.target.value)) {
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "rut_representante":
        setFormClienteEditData({
          ...formClienteEditData,
          [e.target.name]: e.target.value,
        });
        break;
      case "telefono_representante":
        if (/^\d*$/.test(e.target.value)) {
          setFormClienteEditData({
            ...formClienteEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      default:
        setFormClienteEditData({
          ...formClienteEditData,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleSubmitEditCliente = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioEditarCliente();

      if (esFormularioValido) {
        const esRutValido = validarRut(formClienteEditData.rut);
        const esRutRepresentanteValido = validarRut(formClienteEditData.rut_representante);
        const esEmailValido = validarEmail(formClienteEditData.email_representante);

        if (!esRutValido) {
          alert("Rut del cliente no válido");
        } else if (!esRutRepresentanteValido) {
          alert("Rut del representante no válido");
        } else if (!esEmailValido) {
          alert("Email No Válido");
        } else {
          const responseEditCliente = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/cliente.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formClienteEditData),
            }
          );
  
          const dataEditCliente = await responseEditCliente.json();
          if (dataEditCliente.message === "Cliente modificado con éxito.") {
            //setFormClienteEditData({});

            obtenerClientes();
  
            alert("Cliente modificado con éxito.");
  
            closeModalEditarCliente();
  
            navigate("/cliente", { state: dataLogin });
          } else {
            throw new Error();
          }
        }
      }
    } catch (error) {
      alert("Error al editar el cliente.");
    }
  }

  const validaFormularioEditarCliente = () => {
    if (formClienteEditData.rut.trim() === "") {
      alert('Debe ingresar un RUT');
      return false;
    }

    if (formClienteEditData.nombre_cliente.trim() === "") {
      alert('Debe ingresar el nombre del cliente');
      return false;
    }

    if (formClienteEditData.razon_social.trim() === "") {
      alert('Debe ingresar la razón social');
      return false;
    }

    if (formClienteEditData.giro.trim() === "") {
      alert('Debe ingresar el giro');
      return false;
    }

    if (formClienteEditData.direccion.trim() === "") {
      alert('Debe ingresar la dirección');
      return false;
    }

    if (formClienteEditData.id_region === "") {
      alert('Debe seleccionar una región');
      return false;
    }

    if (formClienteEditData.id_comuna === "") {
      alert('Debe seleccionar una comuna');
      return false;
    }

    if (formClienteEditData.nombre_representante === "") {
      alert('Debe ingresar el nombre del representante');
      return false;
    }

    if (formClienteEditData.rut_representante === "") {
      alert('Debe ingresar el rut del representante');
      return false;
    }

    if (formClienteEditData.telefono_representante === "") {
      alert('Debe ingresar el teléfono del representante');
      return false;
    }

    if (formClienteEditData.email_representante === "") {
      alert('Debe ingresar el email del representante');
      return false;
    }

    return true;
  }

  const closeModalEditarCliente = () => {
    setModalEditarClienteOpen(false);
  };

  //Editar Obra
  const [formObraEditData, setFormObraEditData] = useState({});
  const handleEditarObraClick = async () => {
    setModalDetalleObraOpen(false);

    setFormObraEditData({
      ...formObraEditData,
      ["id_obra"]: detalleObra.length > 0 && detalleObra[0].id_obra,
      ["nombre_obra"]: detalleObra.length > 0 && detalleObra[0].nombre_obra || "",
      ["direccion"]: detalleObra.length > 0 && detalleObra[0].direccion || "",
      ["id_region"]: detalleObra.length > 0 && detalleObra[0].id_region || "",
      ["id_comuna"]: detalleObra.length > 0 && detalleObra[0].id_comuna || "",
      ["id_bodega"]: detalleObra.length > 0 && detalleObra[0].id_bodega || "",
      ["id_contacto"]: detalleObra.length > 0 && detalleObra[0].id_contacto || "",
      ["nombres"]: detalleObra.length > 0 && detalleObra[0].nombres || "",
      ["apellido_paterno"]: detalleObra.length > 0 && detalleObra[0].apellido_paterno || "",
      ["apellido_materno"]: detalleObra.length > 0 && detalleObra[0].apellido_materno || "",
      ["rut_contacto"]: detalleObra.length > 0 && detalleObra[0].rut_contacto || "",
      ["cargo_contacto"]: detalleObra.length > 0 && detalleObra[0].cargo_contacto || "",
      ["telefono_contacto"]: detalleObra.length > 0 && detalleObra[0].telefono_contacto || "",
      ["email_contacto"]: detalleObra.length > 0 && detalleObra[0].email_contacto || ""
    });

    setModalEditarObraOpen(true);
  };

  const handleFormObraEditData = (e) => {
    const textOnly = /^[A-Za-z\s]+$/;
    
    switch (e.target.name) {
      case "nombres":
        if (textOnly.test(e.target.value)) {
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "apellido_paterno":
        if (textOnly.test(e.target.value)) {
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "apellido_materno":
        if (textOnly.test(e.target.value)) {
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      case "id_region":
        obtenerComunas(e.target.value);
        formObraEditData.id_comuna = "";
        setFormObraEditData({
          ...formObraEditData,
          [e.target.name]: e.target.value,
        });
        break;
      case "telefono_contacto":
        if (/^\d*$/.test(e.target.value)) {
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "cargo_contacto":
        if (textOnly.test(e.target.value)) {
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: e.target.value,
          });
        } else {
          const filteredValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
          setFormObraEditData({
            ...formObraEditData,
            [e.target.name]: filteredValue,
          });
        }
        break;
      default:
        setFormObraEditData({
          ...formObraEditData,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleSubmitEditObra = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioEditarObra();

      if (esFormularioValido) {
        const esRutValido = validarRut(formObraEditData.rut_contacto);
        const esEmailValido = validarEmail(formObraEditData.email_contacto);

        if (!esRutValido) {
          alert("Rut No Válido");
        } else if (!esEmailValido) {
          alert("Email No Válido");
        } else {
          const responseEditObra = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/obra.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formObraEditData),
            }
          );
  
          const dataEditObra = await responseEditObra.json();
          if (dataEditObra.message === "Obra modificada con éxito.") {

            const responseEditContacto = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/contacto_2.php`,
              {
                method: "PUT",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(formObraEditData),
              }
            );
    
            const dataEditContacto = await responseEditContacto.json();
            if (dataEditContacto.message === "Contacto modificado con éxito.") {
              //setFormObraEditData({});

              obtenerObras(clienteSelected.id_cliente);
                
              alert("Obra modificada con éxito.");

              closeModalEditarObra();

              navigate("/cliente", { state: dataLogin });
            }
          } else {
            throw new Error();
          }
        }
      }
    } catch (error) {
      //console.log(error);
      alert("Error al editar la obra.");
    }
  }

  const validaFormularioEditarObra = () => {
    if (formObraEditData.nombre_obra === "") {
      alert('Debe ingresar el nombre de la obra');
      return false;
    }

    if (formObraEditData.direccion === "") {
      alert('Debe ingresar una dirección');
      return false;
    }

    if (formObraEditData.id_region === "") {
      alert('Debe seleccionar una región');
      return false;
    }

    if (formObraEditData.id_comuna === "") {
      alert('Debe seleccionar una comuna');
      return false;
    }

    if (formObraEditData.id_bodega === "") {
      alert('Debe seleccionar una bodega');
      return false;
    }

    if (formObraEditData.rut === "") {
      alert('Debe ingresar el RUT del contacto');
      return false;
    }

    if (formObraEditData.nombres === "") {
      alert('Debe ingresar los nombres del contacto');
      return false;
    }

    if (formObraEditData.apellido_paterno === "") {
      alert('Debe ingresar el apellido paterno del contacto');
      return false;
    }

    if (formObraEditData.apellido_materno === "") {
      alert('Debe ingresar el apellido materno del contacto');
      return false;
    }

    if (formObraEditData.telefono_contacto === "") {
      alert('Debe ingresar el teléfono del contacto');
      return false;
    }

    if (formObraEditData.email_contacto === "") {
      alert('Debe ingresar el email del contacto');
      return false;
    }

    if (formObraEditData.cargo_contacto === "") {
      alert('Debe ingresar el cargo del contacto');
      return false;
    }

    return true;
  }

  const closeModalEditarObra = () => {
    setModalEditarObraOpen(false);
  };

  const handleEvaluacionClick = () => {
    setModalEvaluacionOpen(true);
  };

  const closeModalEvaluacion = () => {
    setModalEvaluacionOpen(false);
  };

  const modalComponentDetalleCliente = (
    <Modal
      isOpen={isModalDetalleClienteOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalDetalleCliente}
      contentLabel="ModalDetalleCliente"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Cliente</h4>
            <button onClick={closeModalDetalleCliente} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Cliente</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].nombre_cliente}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Rut</span>
                <span className="label">{detalleCliente.length > 0 && formatearRut(detalleCliente[0].rut)}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Razón Social</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].razon_social}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Giro</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].giro}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Dirección</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].direccion}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Región</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].region}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Comuna</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].comuna}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Nombre Representante</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].nombre_representante}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Rut Representante</span>
                <span className="label">{detalleCliente.length > 0 && formatearRut(detalleCliente[0].rut_representante)}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Teléfono Representante</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].telefono_representante}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Email Representante</span>
                <span className="label">{detalleCliente.length > 0 && detalleCliente[0].email_representante}</span>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button className="btn btn-dark" onClick={handleEditarClienteClick}><i className="bi bi-pencil-square"></i> Editar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const modalComponentDetalleObra = (
    <Modal
      isOpen={isModalDetalleObraOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalDetalleObra}
      contentLabel="ModalDetalleObra"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Obra</h4>
            <button onClick={closeModalDetalleObra} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <ul className="list-group mb-4">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Cliente</span>
                <span className="label">{clienteSelected.nombre_cliente}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Obra</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].nombre_obra}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Dirección</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].direccion}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Región</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].region}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Comuna</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].comuna}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Bodega</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].bodega}</span>
              </li>
            </ul>
            <h5 className="mb-3">Información de Contacto</h5>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Nombre</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].nombres + " " + detalleObra[0].apellido_paterno + " " + detalleObra[0].apellido_materno}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">RUT</span>
                <span className="label"> {detalleObra.length > 0 && formatearRut(detalleObra[0].rut_contacto)}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Cargo</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].cargo_contacto}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Teléfono</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].telefono_contacto}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span className="form-label mb-0">Email</span>
                <span className="label">{detalleObra.length > 0 && detalleObra[0].email_contacto}</span>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button className="btn btn-dark" onClick={handleEditarObraClick}><i className="bi bi-pencil-square"></i> Editar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const evaluacionToEmoji = {
    1: "Positiva 😊",
    2: "Neutra 😐",
    3: "Negativa ☹️",
  };

  const modalComponentEvaluacion = (
    <Modal
      isOpen={isModalEvaluacionOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalEvaluacion}
      contentLabel="ModalEvaluacion"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Evaluación Cliente</h4>
            <button onClick={closeModalEvaluacion} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Nombre</label>
                <input
                  className="form-control"
                  name="comentario_nombre_empresa"
                  value={clienteSelected.nombre_cliente}
                  type="text"
                  disabled
                />
              </div>
              <div className="col">
                <label className="form-label">Evaluación</label>
                <select
                  className="form-select"
                  name="id_clasificacion"
                  value={formComentarios.id_clasificacion}
                  onChange={handleFormComentarios}
                >
                  <option value="1">Positiva 😊</option>
                  <option value="2">Neutra 😐</option>
                  <option value="3">Negativa ☹️</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label">Observaciones</label>
              <textarea
                className="form-control"
                name="observaciones"
                value={formComentarios.observaciones}
                onChange={handleFormComentarios}
                type="textarea"
                rows="2"
              />
            </div>
            <h5 className="mb-3">Historial</h5>
            <div className="table-responsive">
              <table className="table table-hover table-sm">
                <thead>
                  <tr className="table-active">
                    <th className="col-2 ps-2">Fecha</th>
                    <th className="col-4">Autor</th>
                    <th className="col-2">Evaluación</th>
                    <th className="col-4 pe-2">Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  {comentarios ? (
                    comentarios.map((comentario, index) => (
                      <tr key={index}>
                        <td className="col-2 ps-2">{comentario.fecha_evaluacion}</td>
                        <td className="col-4">{comentario.nombre_usuario}</td>
                        <td className="col-2">{evaluacionToEmoji[comentario.id_clasificacion]}</td>
                        <td className="col-4 pe-2">{comentario.observaciones}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={4}>No se encontraron evaluaciones asociadas al cliente</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitComentarios}><i className="bi bi-floppy"></i> Guardar Evaluación</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  //Editar Cliente
  const modalComponentEditarCliente = (
    <Modal
      isOpen={isModalEditarClienteOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalEditarCliente}
      contentLabel="ModalEditarCliente"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Cliente</h4>
            <button onClick={closeModalEditarCliente} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Rut</label>
                <input
                  className="form-control"
                  name="rut"
                  value={formClienteEditData.rut}
                  onChange={handleFormClienteEditData}
                  type="text"
                  maxLength={10}
                  placeholder="xxxxxxxx-x"
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Nombre</label>
              <input
                className="form-control"
                name="nombre_cliente"
                value={formClienteEditData.nombre_cliente}
                onChange={handleFormClienteEditData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Razón Social</label>
              <input
                className="form-control"
                name="razon_social"
                value={formClienteEditData.razon_social}
                onChange={handleFormClienteEditData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Giro</label>
              <input
                className="form-control"
                name="giro"
                value={formClienteEditData.giro}
                onChange={handleFormClienteEditData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Dirección</label>
              <input
                className="form-control"
                name="direccion"
                value={formClienteEditData.direccion}
                onChange={handleFormClienteEditData}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Región</label>
              {regiones.length > 0 ? (
                <select className="form-select" name="id_region" value={formClienteEditData.id_region} onChange={handleFormClienteEditData}>
                  <option value="">Seleccione Región</option>
                  {regiones.map((region, index) => (
                    <option key={index} value={region.id_region}>{region.region}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_region">
                  <option value="">Seleccione Región</option>
                </select>
              )}
            </div>
            <div className="mb-4">
              <label className="form-label">Comuna / Ciudad</label>
              {comunas.length > 0 ? (
                <select className="form-select" name="id_comuna" value={formClienteEditData.id_comuna} onChange={handleFormClienteEditData}>
                  <option value="">Seleccione Comuna</option>
                  {comunas.map((comuna, index) => (
                    <option key={index} value={comuna.id_comuna}>{comuna.comuna}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_comuna">
                  <option value="">Seleccione Comuna</option>
                </select>
              )}
            </div>
            <h5 className="mb-3">Información del Representante</h5>
            <div className="mb-3">
              <label className="form-label">Nombre Representante</label>
              <input
                className="form-control"
                name="nombre_representante"
                value={formClienteEditData.nombre_representante}
                onChange={handleFormClienteEditData}
                type="text"
              />
            </div>
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Rut Representante</label>
                <input
                  className="form-control"
                  name="rut_representante"
                  value={formClienteEditData.rut_representante}
                  onChange={handleFormClienteEditData}
                  type="email"
                />
              </div>
              <div className="col">
                <label className="form-label">Teléfono Representante</label>
                <input
                  className="form-control"
                  name="telefono_representante"
                  value={formClienteEditData.telefono_representante}
                  onChange={handleFormClienteEditData}
                  type="text"
                  maxLength={9}
                  placeholder="9xxxxxxxx"
                />
              </div>
            </div>
            <label className="form-label">Email Representante</label>
            <input
              className="form-control"
              name="email_representante"
              value={formClienteEditData.email_representante}
              onChange={handleFormClienteEditData}
              type="email"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEditCliente}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  //Editar Obra
  const modalComponentEditarObra = (
    <Modal
      isOpen={isModalEditarObraOpen} // Agrega esta línea para controlar la visibilidad
      onRequestClose={closeModalEditarObra}
      contentLabel="ModalEditarObra"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Obra</h4>
            <button onClick={closeModalEditarObra} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-2">
              <label className="form-label d-block mb-1">Cliente</label>
              <span className="label">{clienteSelected.nombre_cliente}</span>
            </div>
            <div className="mb-2">
              <label className="form-label">Nombre Obra</label>
              <input
                className="form-control"
                name="nombre_obra"
                type="text"
                value={formObraEditData.nombre_obra}
                onChange={handleFormObraEditData}
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Dirección</label>
              <input
                className="form-control"
                name="direccion"
                type="text"
                value={formObraEditData.direccion}
                onChange={handleFormObraEditData}
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Región</label>
              {regiones.length > 0 ? (
                <select className="form-select" name="id_region" value={formObraEditData.id_region} onChange={handleFormObraEditData}>
                  <option value="">Seleccione Región</option>
                  {regiones.map((region, index) => (
                    <option key={index} value={region.id_region}>{region.region}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_region">
                  <option value="">Seleccione Región</option>
                </select>
              )}
            </div>
            <div className="mb-2">
              <label className="form-label">Comuna / Ciudad</label>
              {comunas.length > 0 ? (
                <select className="form-select" name="id_comuna" value={formObraEditData.id_comuna} onChange={handleFormObraEditData}>
                  <option value="">Seleccione Comuna</option>
                  {comunas.map((comuna, index) => (
                    <option key={index} value={comuna.id_comuna}>{comuna.comuna}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_comuna">
                  <option value="">Seleccione Comuna</option>
                </select>
              )}
            </div>
            <div className="mb-4">
              <label className="form-label">Bodega</label>
              {bodegas.length > 0 ? (
                <select className="form-select" name="id_bodega" value={formObraEditData.id_bodega} onChange={handleFormObraEditData}>
                  <option value="">Seleccione Bodega</option>
                  {bodegas.map((bodega, index) => (
                    <option key={index} value={bodega.id_bodega}>{bodega.bodega}</option>
                  ))}
                </select>
              ) : (
                <select className="form-select" name="id_comuna">
                  <option value="">Seleccione Comuna</option>
                </select>
              )}
            </div>
            <h5 className="mb-3">Información de Contacto</h5>
            <div className="mb-2">
              <label className="form-label">Nombres</label>
              <input
                className="form-control"
                name="nombres"
                type="text"
                value={formObraEditData.nombres}
                onChange={handleFormObraEditData}
              />
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-2">
              <div className="col">
                <label className="form-label">Apellido Paterno</label>
                <input
                  className="form-control"
                  name="apellido_paterno"
                  type="text"
                  value={formObraEditData.apellido_paterno}
                  onChange={handleFormObraEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Apellido Materno</label>
                <input
                  className="form-control"
                  name="apellido_materno"
                  type="text"
                  value={formObraEditData.apellido_materno}
                  onChange={handleFormObraEditData}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-2">
              <div className="col">
                <label className="form-label">Rut</label>
                <input
                  className="form-control"
                  name="rut_contacto"
                  type="text"
                  maxLength={10}
                  value={formObraEditData.rut_contacto}
                  onChange={handleFormObraEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Cargo</label>
                <input
                  className="form-control"
                  name="cargo_contacto"
                  type="text"
                  value={formObraEditData.cargo_contacto}
                  onChange={handleFormObraEditData}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col">
                <label className="form-label">Teléfono</label>
                <input
                  className="form-control"
                  name="telefono_contacto"
                  type="text"
                  value={formObraEditData.telefono_contacto}
                  onChange={handleFormObraEditData}
                  maxLength={9}
                  placeholder="9xxxxxxxx"
                />
              </div>
              <div className="col">
                <label className="form-label">Email</label>
                <input
                  className="form-control"
                  name="email_contacto"
                  type="email"
                  value={formObraEditData.email_contacto}
                  onChange={handleFormObraEditData}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEditObra}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const handleSelectReport = (reportData, tipo_report) => {
    setModalOpen(true);
    switch (tipo_report) {
      case "BH":
        setModalContent(<ReportBHDetalleModalContent reportBH={reportData} />);
        break;
      case "GH":
        setModalContent(<ReportGHDetalleModalContent reportGH={reportData} />);
        break;
      case "GT":
        setModalContent(<ReportGTDetalleModalContent reportGT={reportData} />);
        break;
    }
  };

  const handleAnularReport = (reportData, tipo_report) => {
    closeModal();
    setModalOpen(true);
    
    setModalContent(<ConfirmaAnularReportModalContent reportData={reportData} tipoReport={tipo_report} />);
  };

  function ConfirmaAnularReportModalContent ({ reportData, tipoReport }) {
    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Anular Report: {tipoReport}-{reportData.id_report_bh || reportData.id_report_gh || reportData.id_report_gt}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <p className="fs-5">¿Está seguro que desea anular el report: {tipoReport}-{reportData.id_report_bh || reportData.id_report_gh || reportData.id_report_gt}?</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={(e) => handleSubmitAnularReport(e, reportData, tipoReport)}><i className="bi bi-dash-circle"></i> Anular Report</button>
          </div>
        </div>
      </div>
    );
  }

  const handleSubmitAnularReport = async (event, reportData, tipoReport) => {
    event.preventDefault();

    try {
      switch (tipoReport) {
        case "BH":
          const formAnularReportBH = { id_report_bh: reportData.id_report_bh, anula_report: 1 };
          const responseAnularReportBH = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/report_bh.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formAnularReportBH),
            }
          );

          const dataAnularReportBH = await responseAnularReportBH.json();
          if (dataAnularReportBH.message === "Report BH anulado exitosamente.") {
            alert("Report anulado exitosamente.");
            
            obtenerReportsBH(reportData.id_obra);

            navigate("/cliente", { state: dataLogin });
          } else {
            throw new Error();
          }
          break;
        case "GH":
          const formAnularReportGH = { id_report_gh: reportData.id_report_gh, anula_report: 1 };
          const responseAnularReportGH = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/report_gh.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formAnularReportGH),
            }
          );

          const dataAnularReportGH = await responseAnularReportGH.json();
          if (dataAnularReportGH.message === "Report GH anulado exitosamente.") {
            alert("Report anulado exitosamente.");
            
            obtenerReportsGH(reportData.id_obra);

            navigate("/cliente", { state: dataLogin });
          } else {
            throw new Error();
          }
          break;
        case "GT":
          const formAnularReportGT = { id_report_gt: reportData.id_report_gt, anula_report: 1 };
          const responseAnularReportGT = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/report_gt.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formAnularReportGT),
            }
          );

          const dataAnularReportGT = await responseAnularReportGT.json();
          if (dataAnularReportGT.message === "Report GT anulado exitosamente.") {
            alert("Report anulado exitosamente.");
            
            obtenerReportsGT(reportData.id_obra);

            navigate("/cliente", { state: dataLogin });
          } else {
            throw new Error();
          }
          break;
      }
    } catch (error) {
      alert(error);
    }

    closeModal();
  }

  function ReportBHDetalleModalContent ({ reportBH }) {
    const [detalleReportBH, setDetalleReportBH] = useState([]);

    useEffect(() => {
      obtenerDetalleReportBH(reportBH.id_report_bh);
      obtenerFallosReportBH((reportBH.id_report_bh));
    }, []);

    const obtenerDetalleReportBH = async (id_report_bh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/report_bh_detalle.php?id_report_bh=${id_report_bh}`
        );
        const data = await response.json();
  
        if (data && data[0].id_report_bh_detalle) {
          setDetalleReportBH(data);
        }
      } catch (error) {
        setDetalleReportBH([]);
      }
    };

    const [fallosReportBH, setFallosReportBH] = useState([]);
    const obtenerFallosReportBH = async (id_report_bh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/report_bh_fallos.php?id_report_bh=${id_report_bh}`
        );
        const data = await response.json();

        if (data && data[0].id_fallo) {
          setFallosReportBH(data);
          //console.log(JSON.stringify(data,null,2));
        }
      } catch (error) {
        setFallosReportBH([]);
      }
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Report: Bomba Hormigón {reportBH.id_report_bh}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{reportBH.nombre_cliente}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{`${reportBH.rut.slice(0, 2)}.${reportBH.rut.slice(2, 5)}.${reportBH.rut.slice(5)}`}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Solicitante</span>
                    <span className="label">{reportBH.nombre_solicitante || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Obra</span>
                    <span className="label">{reportBH.nombre_obra}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hormigonera</span>
                    <span className="label">{reportBH.hormigonera || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" id="firstCheckbox" checked={reportBH.accesos_instalacion === 1} readOnly/>
                    <label className="form-check-label" htmlFor="firstCheckbox">Accesos y vías de instalación expeditas</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" id="secondCheckbox" checked={reportBH.distancia_tendidos_electricos === 1} readOnly/>
                    <label className="form-check-label" htmlFor="secondCheckbox">Distancia optima a tendidos eléctricos</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" id="thirdCheckbox" checked={reportBH.distancia_excavaciones === 1} readOnly/>
                    <label className="form-check-label" htmlFor="thirdCheckbox">Distancia a excavaciones y taludes</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" id="fourthCheckbox" checked={reportBH.condiciones_terreno === 1} readOnly/>
                    <label className="form-check-label" htmlFor="fourthCheckbox">Condiciones de terreno para instalación de equipo</label>
                  </li>
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" id="fifthCheckbox" checked={reportBH.plataforma_trabajo === 1} readOnly/>
                    <label className="form-check-label" htmlFor="fifthCheckbox">Plataforma de trabajo y cuerdas de vida</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{reportBH.folio}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Report</span>
                    <span className="label">{format(parse(reportBH.fecha_report, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-2">Información de Bomba</h5>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tipo de bomba</span>
                    <span className="label">{reportBH.tipo_maquinaria}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">¿Traslado Bomba?</span>
                    <span className="label">{reportBH.requiere_traslado_bomba === 1 ? "Si" : "No"}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">¿Tubería Adicional?</span>
                    <span className="label">{reportBH.requiere_tuberia_adicional === 1 ? "Si" : "No"}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-md-2">
              <div className="col">
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Bomba</span>
                    <span className="label">{reportBH.bomba}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Operador</span>
                    <span className="label">{reportBH.operador}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tubero 1</span>
                    <span className="label">{reportBH.tubero_1}</span>
                  </li>
                  {reportBH.tubero_2 && (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="form-label mb-0">Tubero 2</span>
                      <span className="label">{reportBH.tubero_2}</span>
                    </li>
                  )}
                  {reportBH.tubero_3 && (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="form-label mb-0">Tubero 3</span>
                      <span className="label">{reportBH.tubero_3}</span>
                    </li>
                  )}
                  {reportBH.tubero_4 && (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="form-label mb-0">Tubero 4</span>
                      <span className="label">{reportBH.tubero_4}</span>
                    </li>
                  )}
                  {reportBH.tubero_5 && (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span className="form-label mb-0">Tubero 5</span>
                      <span className="label">{reportBH.tubero_5}</span>
                    </li>
                  )}
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Horometro Inicial</span>
                    <span className="label">{reportBH.horometro_inicial}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Horometro Final</span>
                    <span className="label">{reportBH.horometro_final}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Elemento Hormigonado</span>
                    <span className="label">{reportBH.elemento_hormigonado}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tipo de Hormingón</span>
                    <span className="label">{reportBH.tipo_hormigon}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Volumen Mínimo M3</span>
                    <span className="label">{reportBH.volumen_minimo} M3</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Volumen Bombeado M3</span>
                    <span className="label">{reportBH.volumen_bombeado} M3</span>
                  </li>
                </ul>
                <ul className="list-group mb-4">
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label d-block mb-1">Salida Bodega</span>
                        <span className="label">{format(parse(reportBH.hora_salida_bodega, 'HH:mm:ss', new Date()), 'HH:mm')}</span>
                      </div>
                      <div className="col">
                        <span className="form-label d-block mb-1">Llegada a Obra</span>
                        <span className="label">{format(parse(reportBH.hora_llegada_obra, 'HH:mm:ss', new Date()), 'HH:mm')}</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Solicitada de Bombeo</span>
                    <span className="label">{format(parse(reportBH.hora_solicitada_bombeo, 'HH:mm:ss', new Date()), 'HH:mm')}</span>
                  </li>
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label d-block mb-1">Hora Inicio Bombeo</span>
                        <span className="label">{format(parse(reportBH.hora_inicio_bombeo, 'HH:mm:ss', new Date()), 'HH:mm')}</span>
                      </div>
                      <div className="col">
                        <span className="form-label d-block mb-1">Hora Termino Bombeo</span>
                        <span className="label">{format(parse(reportBH.hora_termino_bombeo, 'HH:mm:ss', new Date()), 'HH:mm')}</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tiempo de Espera</span>
                    <span className="label">{format(parse(reportBH.tiempo_espera, 'HH:mm:ss', new Date()), 'HH:mm') || ""}</span>
                  </li>
                  <li className="list-group-item">
                    <span className="form-label d-block mb-1">Motivo de Espera</span>
                    <span className="label">{reportBH.motivo_espera || ""}</span>
                  </li>
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label d-block mb-1">Salida de Obra</span>
                        <span className="label">{format(parse(reportBH.hora_salida_obra, 'HH:mm:ss', new Date()), 'HH:mm') || ""}</span>
                      </div>
                      <div className="col">
                        <span className="form-label d-block mb-1">Llegada a Bodega</span>
                        <span className="label">{format(parse(reportBH.hora_llegada_bodega, 'HH:mm:ss', new Date()), 'HH:mm') || ""}</span>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <span className="form-label d-block mb-1">Bolsas Vittech</span>
                        <span className="label">{reportBH.aditivo}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col">
                <div className="table-responsive border rounded">
                  <table className="table table-hover text-center mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th scope="col">Mixer</th>
                        <th scope="col">Guía</th>
                        <th scope="col">M3</th>
                        <th scope="col">Llegada Obra</th>
                        <th scope="col">Desc. Inicio</th>
                        <th scope="col">Desc. Termino</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detalleReportBH ? (
                        detalleReportBH.map((detalleReport, index) => (
                          <tr key={index}>
                            <td>{detalleReport.mixer}</td>
                            <td>{detalleReport.guia}</td>
                            <td>{detalleReport.m3}</td>
                            <td>{format(parse(detalleReport.llegada_obra, 'HH:mm:ss', new Date()), 'HH:mm')}</td>
                            <td>{format(parse(detalleReport.descarga_inicio, 'HH:mm:ss', new Date()), 'HH:mm')}</td>
                            <td>{format(parse(detalleReport.descarga_termino, 'HH:mm:ss', new Date()), 'HH:mm')}</td>
                          </tr>
                        ))
                      ) : (
                          <tr>
                            <td className="text-center ps-1" colSpan={6}>No se encontraron detalles asociados</td>
                          </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <h5 className="mb-3">Fallos Cliente</h5>
                <ul className="list-group">
                  {fallosReportBH.length > 0 ? (
                    fallosReportBH.filter(fallo => fallo.fallo_cliente === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloCliente" + index} value={fallo.id_fallo} checked readOnly/>
                        <label className="form-check-label" htmlFor={"falloCliente" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos asignados de cliente</span>
                  )}
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Fallos Vittamaq</h5>
                <ul className="list-group">
                  {fallosReportBH.length > 0 ? (
                    fallosReportBH.filter(fallo => fallo.fallo_vittamaq === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloVittamaq" + index} value={fallo.id_fallo} checked readOnly/>
                        <label className="form-check-label" htmlFor={"falloVittamaq" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos asignados de vittamaq</span>
                  )}
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Observaciones</h5>
            <textarea className="form-control form-control-sm" rows={2} value={reportBH.observaciones} readOnly></textarea>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-danger" onClick={() => handleAnularReport(reportBH, 'BH')}><i className="bi bi-dash-circle"></i> Anular</button>
            <button className="btn btn-yellow" onClick={closeModal}><i className="bi bi-arrow-right-circle"></i> Aceptar</button>
          </div>
        </div>
      </div>
    );
  }

  function ReportGHDetalleModalContent ({ reportGH }) {
    //console.log(JSON.stringify(reportBH));

    const [detalleReportGH, setDetalleReportGH] = useState({});

    useEffect(() => {
      obtenerDetalleReportGH(reportGH.id_report_gh);
      obtenerFallosReportGH(reportGH.id_report_gh);
    }, []);

    const obtenerDetalleReportGH = async (id_report_gh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/report_gh_detalle.php?id_report_gh=${id_report_gh}`
        );
        const data = await response.json();
  
        if (data && data[0].id_report_gh_detalle) {
          setDetalleReportGH(data);
        }
      } catch (error) {
        setDetalleReportGH([]);
      }
    };
    
    const [fallosReportGH, setFallosReportGH] = useState([]);
    const obtenerFallosReportGH = async (id_report_gh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/report_gh_fallos.php?id_report_gh=${id_report_gh}`
        );
        const data = await response.json();

        if (data && data[0].id_fallo) {
          setFallosReportGH(data);
          //console.log(JSON.stringify(data,null,2));
        }
      } catch (error) {
        setFallosReportGH([]);
      }
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Report: Grúa Hidráulica {reportGH.id_report_gh}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut Cliente</span>
                    <span className="label">{`${reportGH.rut.slice(0, 2)}.${reportGH.rut.slice(2, 5)}.${reportGH.rut.slice(5)}`}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{reportGH.folio}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="inspeccion_diaria_maquina" id="inspeccion_diaria_maquina" checked={reportGH.inspeccion_diaria_maquina === 1} readOnly/>
                    <label className="form-check-label" htmlFor="inspeccion_diaria_maquina">Inspección diaria de máquina</label>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Información de Grúa</h5>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{reportGH.nombre_cliente}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Lugar de Faena / Obra</span>
                    <span className="label">{reportGH.nombre_obra}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Operador Grúa</span>
                    <span className="label">{reportGH.operador_grua}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Solicitado Por</span>
                    <span className="label">{reportGH.solicitado_por}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Report</span>
                    <span className="label">{format(parse(reportGH.fecha_report, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Número</span>
                    <span className="label">{reportGH.numero}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Ayudante</span>
                    <span className="label">{reportGH.ayudante}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Segundo Ayudante</span>
                    <span className="label">{reportGH.segundo_ayudante}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Tonelaje Grúa</span>
                    <span className="label">{reportGH.tonelaje_grua}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">¿Movilización Grúa?</span>
                    <span className="label">{reportGH.requiere_movilizacion_grua === 1 ? "Sí" : "No"}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">¿Traslado de contrapesos?</span>
                    <span className="label">{reportGH.requiere_traslado_contrapeso === 1 ? "Sí" : "No"}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Horarios</h5>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Salida Bodega</span>
                    <span className="label">{detalleReportGH[0]?.hora_salida_bodega.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Llegada Faena</span>
                    <span className="label">{detalleReportGH[0]?.hora_llegada_faena.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Término Faena</span>
                    <span className="label">{detalleReportGH[0]?.hora_termino_faena.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Llegada Bodega</span>
                    <span className="label">{detalleReportGH[0]?.hora_llegada_bodega.slice(0, -3)}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
              <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Mínimo de Horas</span>
                    <span className="label">{detalleReportGH[0]?.minimo_horas.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Inicio</span>
                    <span className="label">{detalleReportGH[0]?.hora_inicio_colacion.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Término</span>
                    <span className="label">{detalleReportGH[0]?.hora_termino_colacion.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Total Horas Trabajadas</span>
                    <span className="label">{detalleReportGH[0]?.total_horas_trabajadas.slice(0, -3)}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <h5 className="mb-3">Fallos Cliente</h5>
                <ul className="list-group">
                  {fallosReportGH.length > 0 ? (
                    fallosReportGH.filter(fallo => fallo.fallo_cliente === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloCliente" + index} value={fallo.id_fallo} checked readOnly/>
                        <label className="form-check-label" htmlFor={"falloCliente" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos asignados de cliente</span>
                  )}
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Fallos Vittamaq</h5>
                <ul className="list-group">
                  {fallosReportGH.length > 0 ? (
                    fallosReportGH.filter(fallo => fallo.fallo_vittamaq === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloVittamaq" + index} value={fallo.id_fallo} checked readOnly/>
                        <label className="form-check-label" htmlFor={"falloVittamaq" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos asignados de vittamaq</span>
                  )}
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Observaciones</h5>
            <textarea 
              className="form-control form-control-sm" 
              rows={3}
              value={reportGH.observaciones}
              readOnly
            ></textarea>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-danger" onClick={() => handleAnularReport(reportGH, 'GH')}><i className="bi bi-dash-circle"></i> Anular</button>
            <button className="btn btn-yellow" onClick={closeModal}><i className="bi bi-arrow-right-circle"></i> Aceptar</button>
          </div>
        </div>
      </div>
    );
  }

  function ReportGTDetalleModalContent ({ reportGT }) {
    //console.log(JSON.stringify(reportGT));

    const [detalleReportGT, setDetalleReportGT] = useState({});

    useEffect(() => {
      obtenerDetalleReportGT(reportGT.id_report_gt);
      obtenerFallosReportGT(reportGT.id_report_gt);
    }, []);

    const obtenerDetalleReportGT = async (id_report_gt) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/report_gt_detalle.php?id_report_gt=${id_report_gt}`
        );
        const data = await response.json();
  
        if (data && data[0].id_report_gt_detalle) {
          setDetalleReportGT(data);
        }
      } catch (error) {
        setDetalleReportGT([]);
      }
    };
    
    const [fallosReportGT, setFallosReportGT] = useState([]);
    const obtenerFallosReportGT = async (id_report_gt) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/report_gt_fallos.php?id_report_gt=${id_report_gt}`
        );
        const data = await response.json();

        if (data && data[0].id_fallo) {
          setFallosReportGT(data);
          //console.log(JSON.stringify(data,null,2));
        }
      } catch (error) {
        setFallosReportGT([]);
      }
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Report: Grúa Torre {reportGT.id_report_gt}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut Cliente</span>
                    <span className="label">{`${reportGT.rut.slice(0, 2)}.${reportGT.rut.slice(2, 5)}.${reportGT.rut.slice(5)}`}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{reportGT.folio}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item">
                    <input className="form-check-input me-2" type="checkbox" name="inspeccion_diaria_maquina" id="inspeccion_diaria_maquina" checked={reportGT.inspeccion_diaria_maquina === 1} readOnly/>
                    <label className="form-check-label" htmlFor="inspeccion_diaria_maquina">Inspección diaria de máquina</label>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Información de Grúa</h5>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{reportGT.nombre_cliente}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Lugar de Faena / Obra</span>
                    <span className="label">{reportGT.nombre_obra}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Operador Grúa</span>
                    <span className="label">{reportGT.operador_grua}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha</span>
                    <span className="label">{format(parse(reportGT.fecha_report, 'yyyy-MM-dd', new Date()), 'dd-MM-yyyy')}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Máquina / Número / Modelo</span>
                    <span className="label">{reportGT.maquina} / {reportGT.numero} / {reportGT.modelo}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Horarios</h5>
            <div className="row row-cols-lg-2 mb-3">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Inicio Mañana</span>
                    <span className="label">{detalleReportGT[0]?.hora_inicio_manana.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Término Mañana</span>
                    <span className="label">{detalleReportGT[0]?.hora_termino_manana.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Inicio Tarde</span>
                    <span className="label">{detalleReportGT[0]?.hora_inicio_tarde.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Término Tarde</span>
                    <span className="label">{detalleReportGT[0]?.hora_termino_tarde.slice(0, -3)}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
              <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Mínimo de Horas</span>
                    <span className="label">{detalleReportGT[0]?.minimo_horas.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Inicio</span>
                    <span className="label">{detalleReportGT[0]?.hora_inicio_colacion.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Hora Colación Término</span>
                    <span className="label">{detalleReportGT[0]?.hora_termino_colacion.slice(0, -3)}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Total Horas Trabajadas</span>
                    <span className="label">{detalleReportGT[0]?.total_horas_trabajadas.slice(0, -3)}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-md-2 mb-3">
              <div className="col">
                <h5 className="mb-3">Fallos Cliente</h5>
                <ul className="list-group">
                  {fallosReportGT.length > 0 ? (
                    fallosReportGT.filter(fallo => fallo.fallo_cliente === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloCliente" + index} value={fallo.id_fallo} checked readOnly/>
                        <label className="form-check-label" htmlFor={"falloCliente" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos asignados de cliente</span>
                  )}
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Fallos Vittamaq</h5>
                <ul className="list-group">
                  {fallosReportGT.length > 0 ? (
                    fallosReportGT.filter(fallo => fallo.fallo_vittamaq === 1).map((fallo, index) => (
                      <li className="list-group-item" key={index}>
                        <input className="form-check-input me-2" type="checkbox" name="id_fallo" id={"falloVittamaq" + index} value={fallo.id_fallo} checked readOnly/>
                        <label className="form-check-label" htmlFor={"falloVittamaq" + index}>{fallo.descripcion}</label>
                      </li>
                    ))
                  ) : (
                    <span>No se encontraron fallos asignados de vittamaq</span>
                  )}
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Observaciones</h5>
            <textarea 
              className="form-control form-control-sm" 
              rows={3}
              value={reportGT.observaciones}
              readOnly
            ></textarea>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-danger" onClick={() => handleAnularReport(reportGT, 'GT')}><i className="bi bi-dash-circle"></i> Anular</button>
            <button className="btn btn-yellow" onClick={closeModal}><i className="bi bi-arrow-right-circle"></i> Aceptar</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Clientes</h4>
                  {dataLogin.id_perfil === 1 && (
                    <button className="btn btn-yellow" onClick={handleAddClienteClick}>
                      <i className="bi-plus-circle"></i> Agregar
                    </button>
                  )}
                </div>
                <div className="card-search px-3 pb-3">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="bi bi-search"></i>
                    </span>
                    <input type="text" className="form-control" placeholder="Cliente..." onChange={handleFiltroCliente}/>
                  </div>
                </div>
                <div className="card-body card-body-fh pt-0">
                  {clientesFiltrados ? (
                    <div>
                      {clientesFiltrados.map((cliente) => (
                        <ClienteCard
                          key={cliente.id_cliente}
                          cliente={cliente}
                          onSelect={handleSelect}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      <i className="bi bi-exclamation-triangle me-2"></i>
                      No se encontraron clientes.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Obras</h4>
                  {mostrarBotonObras && dataLogin.id_perfil === 1 && (
                    <button className="btn btn-yellow" onClick={handleAddObrasClick}>
                      <i className="bi-plus-circle"></i> Agregar
                    </button>
                  )}
                </div>
                {mostrarBotonObras && (
                  <div className="card-search px-3 pb-3">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Obra..." onChange={handleFiltroObra}/>
                    </div>
                  </div>
                )}
                <div className="card-body card-body-fh pt-0">
                  {obrasFiltradas.length > 0 ? (
                    <div>
                      {obrasFiltradas.map((obra) => (
                        <ObraCard 
                          key={obra.id_obra} 
                          obra={obra} 
                          onSelect={handleSelectObra}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      <i className="bi bi-exclamation-triangle me-2"></i>
                      No se encontraron obras asociadas al cliente.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Reports</h4>
                  {mostrarBotonEvaluacion && (
                    <div className="btn-group">
                      <button type="button" className="btn btn-yellow dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi-plus-circle"></i> Agregar
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <button className="dropdown-item" type="button" onClick={() => handleAddReportClick(clienteSelected, obraSelected, 'BH')}>Report Bomba Hormigón</button>
                          </li>
                        <li>
                          <button className="dropdown-item" type="button" onClick={() => handleAddReportClick(clienteSelected, obraSelected, 'GH')}>Report Grúa Hidráulica</button>
                        </li>
                        <li>
                          <button className="dropdown-item" type="button" onClick={() => handleAddReportClick(clienteSelected, obraSelected, 'GT')}>Report Grúa Torre</button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                {mostrarBotonObras && (
                  <div className="card-search px-3 pb-3">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Report..." onChange={handleFiltroReport}/>
                    </div>
                  </div>
                )}
                <div className="card-body card-body-fh pt-0">
                  {((!reportsBH || reportsBH.length === 0) && (!reportsGH || reportsGH.length === 0) && (!reportsGT || reportsGT.length === 0)) ? (
                    <div className="alert alert-warning" role="alert">
                      <i className="bi bi-exclamation-triangle me-2"></i>
                      No se encontraron reports asociados a la obra.
                    </div>
                  ) : (
                    <div>
                      {Array.isArray(reportsBH) && reportsBH.length > 0 && reportsBH.map((reportBH) => (
                        <ReportCard
                          key={reportBH.id_report_bh}
                          report_data={reportBH}
                          onClick={() => handleSelectReport(reportBH, 'BH')}
                        />
                      ))}

                      {Array.isArray(reportsGH) && reportsGH.length > 0 && reportsGH.map((reportGH) => (
                        <ReportCard
                          key={reportGH.id_report_gh}
                          report_data={reportGH}
                          onClick={() => handleSelectReport(reportGH, 'GH')}
                        />
                      ))}

                      {Array.isArray(reportsGT) && reportsGT.length > 0 && reportsGT.map((reportGT) => (
                        <ReportCard
                          key={reportGT.id_report_gt}
                          report_data={reportGT}
                          onClick={() => handleSelectReport(reportGT, 'GT')}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalComponentCliente}
      {modalComponentDetalleCliente}
      {modalComponentEditarCliente}
      {modalComponentDetalleObra}
      {modalComponentEditarObra}
      {modalComponentObras}
      {modalComponentComentarios}
      {modalComponentEvaluacion}
    </div>
  );
}

const styles = {
  lineHeight: {
    lineHeight: 1.9
  },
  mainContainer: {
    /*display: "flex",
    flexDirection: "row",*/
    height: "100vh",
  },
  modalCloseButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    background: "none",
    border: "none",
    fontSize: "1.25rem",
    cursor: "pointer",
  },
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "1.5rem 1.5rem 1.5rem",
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#f7f9fc",
    boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.12)",
    width: "30%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default Cliente;
