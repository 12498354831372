import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import './App.css';

function ReporteVenta() {
  const location = useLocation();
  const [dataLogin, setDataLogin] = useState([]);
  const [facturaciones, setFacturaciones] = useState([]);
  const [maquinariasOptions, setMaquinariasOptions] = useState([]);
  const [clientesOptions, setClientesOptions] = useState([]);
  const [equiposOptions, setEquiposOptions] = useState([]);
  const [hormigonerasOptions, setHormigonerasOptions] = useState([]);
  const [opcionesHormigonera, setOpcionesHormigonera] = useState([]);

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerMaquinarias();
      obtenerClientes();
      obtenerHormigoneras();
    }
  }, [location]);
  
  let opcionesHormigoneraarray = [];
  const obtenerHormigoneras = async () => {
    try {
      const response = await fetch('https://vittamaq.qa-gtalent.cl/api/hormigonera.php');
      const data = await response.json();
      
      if(data && data[0].nombre_homigonera){
        // console.log(JSON.stringify(data,null,2));
        opcionesHormigoneraarray = data.map((hormigonera, index) => ({
          id: index + 1, // Generar un ID autogenerado basado en el índice (comenzando desde 1)
          nombre: hormigonera.nombre_homigonera // Mantener el nombre de la hormigonera
        }));
        setOpcionesHormigonera(opcionesHormigoneraarray);
        // console.log("opcionesHormigoneraarray  "+JSON.stringify(opcionesHormigoneraarray,null,2));
        setHormigonerasOptions(data);
      }
    } catch (error) {
      setHormigonerasOptions([]);
    }
  };

  const obtenerMaquinarias = async () => {
    try {
      const response = await fetch('https://vittamaq.qa-gtalent.cl/api/maquinaria.php');
      const data = await response.json();

      if(data && data[0].id_maquinaria){
        
        /* const maquinariaBH = data.find(maquinaria => maquinaria.id_maquinaria == "1");
        if (maquinariaBH) {
          setMaquinariasOptions([maquinariaBH]);
        } */
        setMaquinariasOptions(data);
      }
    } catch (error) {
      setMaquinariasOptions([]);
    }
  };

  const obtenerClientes = async () => {
    try {
      const response = await fetch('https://vittamaq.qa-gtalent.cl/api/cliente.php');
      const data = await response.json();

      if(data && data[0].id_cliente){
        // console.log(data);
        setClientesOptions(data);
      }
    } catch (error) {
      setClientesOptions([]);
    }
  };

  const obtenerEquipos = async (maquinaria) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/equipo.php?id_maquinaria=${maquinaria}`
      );
      const data = await response.json();
      
      if(data && data[0].id_equipo){
        //const equiposBH = data.filter(equipo => ["Telescópica", "Estacionaria"].includes(equipo.descripcion));
        // console.log(equiposBH);
        /* if (equiposBH) {
          setEquiposOptions(equiposBH);
        } */
        // console.log(data);
        setEquiposOptions(data);
      }
    } catch (error) {
      setEquiposOptions([]);
    }
  };

  const [clienteSelected, setClienteSelected] = useState('');
  const [obras, setObras] = useState([]);

  const handleClienteSelect = async (clienteSelected) => {
    setClienteSelected(clienteSelected);
    await obtenerObras(clienteSelected);

    setFormConsultaData({
      ...formConsultaData,
      ["id_cliente"]: clienteSelected,
    });

    //console.log(formConsultaData);
  };
  
  const obtenerObras = async (cliente) => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/reporte_estado_pago.php?id_cliente=${cliente}`
      );
      const data = await response.json();
      if (data && data[0].id_obra) {
        setObras(data);
      }
    } catch (error) {
      setObras([]);
    }
  };

  //Filtro 
  const [filtroFacturaciones, setFiltroEstadoPagoBH] = useState('');
  const manejarCambioFiltro = (event) => {
    setFiltroEstadoPagoBH(event.target.value);
  };

  const facturacionesFiltradas = facturaciones.filter((item) =>
    item.nombre_obra.toLowerCase().includes(filtroFacturaciones.toLowerCase())
  );

  const currentDate = new Date().toISOString().split('T')[0];
  const [formConsultaData, setFormConsultaData] = useState({
    id_maquinaria: 0,
    id_cliente: 0,
    id_obra: 0,
    fecha_inicio: currentDate,
    fecha_fin: currentDate,
    equipos: 0,
    hormigonera:""
  });

  const [maquinariaSelect, setMaquinariaSelect] = useState('');
  const [hormigoneraSelect, setHormigoneraSelect] = useState('');

  const handleFormConsultaData = async (e) => {
    const { name, value } = e.target;

    setFormConsultaData({
      ...formConsultaData,
      [name]: value,
    });
  };
  /* useEffect(() => {
    console.log(JSON.stringify(formConsultaData,null,2));
  }, [formConsultaData]); */

  const handleMaquinariaSelect = async (maquinariaSelect) => {
    setMaquinariaSelect(maquinariaSelect);
    await obtenerEquipos(maquinariaSelect);

    setFormConsultaData({
      ...formConsultaData,
      ["id_maquinaria"]: maquinariaSelect,
    });

    //console.log(formConsultaData);
  };

  const handleHormigoneraSelect = async (hormigoneraSelect) => {
    setHormigoneraSelect(hormigoneraSelect);
    const hormigoneraSeleccionada = opcionesHormigonera.find(option => option.id == hormigoneraSelect);
    const nombreHormigonera = hormigoneraSeleccionada ? hormigoneraSeleccionada.nombre : ''; // Obtén el nombre de la hormigonera seleccionada

    setFormConsultaData({
      ...formConsultaData,
      ["hormigonera"]: nombreHormigonera,
    });

    //console.log(formConsultaData);
  };
  const handleBuscarFacturacionClick = async (event) => {
    event.preventDefault();

    try {
      //console.log(JSON.stringify(formConsultaData,null,2));
      const responseRequerimiento = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/reporte_facturacion.php`,
        {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formConsultaData),
        }
      );

      const dataRequerimiento = await responseRequerimiento.json();
      if (dataRequerimiento && dataRequerimiento[0].id_report) {
        setFacturaciones(dataRequerimiento);
      }

    } catch (error) {
      //console.log(error);
      setFacturaciones([]);
    }
  }

  const exportToExcel = () => {
    const ahora = new Date();
    const fechaFormateada = formatearFecha(ahora);

    const arregloExportar = facturaciones.map(obj => {
      return {
        ["Maquinaria"]: obj.nombre_maquinaria,
        ["Folio"]: obj.folio,
        ["Fecha Report"]: obj.fecha_report,
        ["Nombre Cliente"]: obj.nombre_cliente,
        ["Nombre Obra"]: obj.nombre_obra,
        ["Equipo"]: obj.equipo,
        ["Hormigonera"]: obj.hormigonera,
        ["M3"]: obj.m3,
        ["Tiempo de espera"]: obj.tiempo_espera,
      };
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arregloExportar);
    XLSX.utils.book_append_sheet(wb, ws, 'Reporte de venta');
    XLSX.writeFile(wb, 'Facturación ' + fechaFormateada + '.xlsx');
  };

  const formatearFecha = (fecha) => {
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const year = fecha.getFullYear();
    const hora = String(fecha.getHours()).padStart(2, '0');
    const minutos = String(fecha.getMinutes()).padStart(2, '0');

    return `${dia}-${mes}-${year} ${hora}_${minutos}`;
  };

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="d-flex gap-md-2 mb-3">
                <div>
                  <label className="form-label">Maquinaria</label>
                  <select className="form-select" name="id_tipo_maquinaria" value={formConsultaData.id_maquinaria} onChange={(e) => handleMaquinariaSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {maquinariasOptions.map((maquinaria, index) => (
                      <option key={index} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Equipo</label>
                  {equiposOptions.length > 0 ? (
                  <select className="form-select" name="equipos" value={formConsultaData.equipos} onChange={handleFormConsultaData}>
                    <option value="0">Todos</option>
                    {equiposOptions.map((equipo) => (
                      <option key={equipo.id_equipo} value={equipo.id_equipo}>{equipo.marca + " " + equipo.modelo}</option>
                    ))}
                  </select>
                  ) : (
                    <select className="form-select" name="equipos">
                      <option value="0">Todos</option>
                    </select>
                    )}
                </div>
                <div>
                  <label className="form-label">Hormigonera</label>
                  <select className="form-select" name="hormigoneras" value={hormigoneraSelect} onChange={(e) => handleHormigoneraSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {/* {hormigonerasOptions.map((hormigonera) => (
                      <option key={hormigonera.id} value={hormigonera.id}>{hormigonera.nombre_homigonera}</option>
                    ))} */}
                    {hormigonerasOptions.map((hormigonera, index) => (
                      <option key={index+1} value={index+1}>{hormigonera.nombre_homigonera}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Cliente</label>
                  <select className="form-select" name="cliente" value={clienteSelected} onChange={(e) => handleClienteSelect(e.target.value)}>
                    <option value="0">Todos</option>
                    {clientesOptions.map((cliente) => (
                      <option key={cliente.id_cliente} value={cliente.id_cliente}>{cliente.nombre_cliente}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="form-label">Obra</label>
                  {obras.length > 0 ? (
                    <select className="form-select" name="id_obra" value={formConsultaData.id_obra} onChange={handleFormConsultaData}>
                      <option value="0">Todos</option>
                      {obras.map((obra) => (
                        <option key={obra.id_obra} value={obra.id_obra}>{obra.nombre}</option>
                      ))}
                    </select>
                  ) : (
                    <select className="form-select" name="id_obra">
                      <option value="0">Todos</option>
                    </select>
                  )}
                </div>  
                <div>
                  <label className="form-label">Fecha Inicio</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_inicio"
                    value={formConsultaData.fecha_inicio}
                    onChange={handleFormConsultaData}
                  />
                </div>
                <div className="">
                  <label className="form-label">Fecha Fin</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fecha_fin"
                    value={formConsultaData.fecha_fin}
                    onChange={handleFormConsultaData}
                  />
                </div>     
                <div>
                  <label className="form-label d-block">&nbsp; </label>
                  <button className="btn btn-yellow" onClick={handleBuscarFacturacionClick}>
                    <i className="bi bi-search"></i> Buscar
                  </button>
                </div>   
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                  <h4>Reporte Facturación</h4>
                  <div className="d-flex gap-md-2">
                    <div className="input-group w-auto">
                      <span className="input-group-text" id="search-folio">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Obra..." aria-describedby="search-folio" onChange={manejarCambioFiltro}/>
                    </div>
                    {facturaciones.length > 0 ? (
                      <button className="btn btn-success" onClick={exportToExcel}><i className="bi bi-download"></i> Exportar Excel</button>
                    ) : (
                      <button className="btn btn-success" disabled><i className="bi bi-download"></i> Exportar Excel</button>
                    )}
                  </div>
                </div>
                <div className="card-body card-body-filter border-bottom-radius p-0">
                  <div className="table-responsive">
                    <table className="table table-hover mb-0">
                      <thead>
                        <tr className="table-active border-top border-bottom">
                          <th scope="col" className="ps-3">Maquinaria</th>
                          <th scope="col">Folio</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Cliente</th>
                          <th scope="col">Obra</th>
                          <th scope="col">Equipo</th>
                          <th scope="col">Hormigonera</th>
                          <th scope="col">M3</th>
                          <th scope="col" className="pe-3">Tiempo de espera</th>
                        </tr>
                      </thead>
                      <tbody>
                        {facturacionesFiltradas.length > 0 ? (
                          facturacionesFiltradas.map((facturacion, index) => (
                            <tr key={index}>
                              <td className="ps-3">{facturacion.nombre_maquinaria}</td>
                              <td className="ps-3">{facturacion.folio}</td>
                              <td>{facturacion.fecha_report}</td>
                              <td>{facturacion.nombre_cliente}</td>
                              <td>{facturacion.nombre_obra}</td>
                              <td>{facturacion.equipo}</td>
                              <td>{facturacion.hormigonera}</td>
                              <td>{facturacion.m3}</td>
                              <td className="pe-3">{facturacion.tiempo_espera}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={10}>No se encontró facturación</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default ReporteVenta;
