import React from "react";

function CentroNotificaciones() {
  return (
    <div className="offcanvas offcanvas-end bg-light" id="notificaciones" aria-labelledby="notificacionesLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="notificacionesLabel">Notificaciones</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <div className="list-group">
          <a href="#" className="list-group-item list-group-item-action" aria-current="true">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">List group item heading</h5>
              <small>3 days ago</small>
            </div>
            <p className="mb-1">Some placeholder content in a paragraph.</p>
            <small>And some small print.</small>
          </a>
          <a href="#" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">List group item heading</h5>
              <small className="text-body-secondary">3 days ago</small>
            </div>
            <p className="mb-1">Some placeholder content in a paragraph.</p>
            <small className="text-body-secondary">And some muted small print.</small>
          </a>
          <a href="#" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">List group item heading</h5>
              <small className="text-body-secondary">3 days ago</small>
            </div>
            <p className="mb-1">Some placeholder content in a paragraph.</p>
            <small className="text-body-secondary">And some muted small print.</small>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CentroNotificaciones;
