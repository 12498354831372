import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import { validate, clean } from 'rut.js';
import './App.css';

function Herramientas() {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataLogin, setDataLogin] = useState([]);
  const [isModalTipoPerfilOpen, setModalTipoPerfilOpen] = useState(false);
  const [isModalUsuarioOpen, setModalUsuarioOpen] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [tipoPerfilOptions, setTipoPerfilOptions] = useState([]);
  const [activeTab, setActiveTab] = useState('usuarios');

  const [formTipoPerfilData, setFormTipoPerfilData] = useState({
    nombre_perfil: "",
    id_usuario: ""
  });

  const [formUsuarioData, setFormUsuarioData] = useState({
    id_perfil: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    rut: "",
    email: "",
    iniciales: "",
    telefono: "",
    contrasena: "",
    repite_contrasena: "",
    id_usuario_registra: ""
  });

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData);
      obtenerTipoPerfil();
      obtenerUsuarios();
    }
  }, [location]);

  const obtenerTipoPerfil = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/perfil.php`
      );
      const data = await response.json();

      if (data && data[0].id_perfil) {
        setTipoPerfilOptions(data);
      }
    } catch (error) {
      setTipoPerfilOptions([]);
    }
  };

  const obtenerUsuarios = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/usuario.php`
      );
      const data = await response.json();

      if (data && data[0].id_usuario) {
        setUsuarios(data);
      }
    } catch (error) {
      setUsuarios([]);
    }
  };

  const handleFormTipoPerfilData = (e) => {
    setFormTipoPerfilData({
      ...formTipoPerfilData,
      [e.target.name]: e.target.value,
    });

    //console.log(JSON.stringify(formTipoPerfilData));
  };

  const handleFormUsuarioData = (e) => {
    setFormUsuarioData({
      ...formUsuarioData,
      [e.target.name]: e.target.value,
    });

    //console.log(JSON.stringify(formUsuarioData));
  };

  const closeModalUsuario = () => {
    setModalUsuarioOpen(false);
  };

  const closeModalTipoPerfil = () => {
    setModalTipoPerfilOpen(false);
  };

  const handleAddUsuarioClick = () => {
    setFormUsuarioData({
      ...formUsuarioData,
      ["id_usuario_registra"]: dataLogin.id_usuario
    });
    setModalUsuarioOpen(true);
  };

  const handleAddTipoPerfilClick = () => {
    setFormTipoPerfilData({
      ...formTipoPerfilData,
      ["id_usuario"]: dataLogin.id_usuario
    });
    setModalTipoPerfilOpen(true);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const formatearRut = (rut) => {
    const rutFormateado = `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(5)}`;
    return rutFormateado;
  };

  const formatearRutSinPuntos = (rut) => {
    const rutLimpio = clean(rut);
    
    if (rutLimpio.length < 8) {
      return rut;
    }

    const rutFormateado = `${rutLimpio.slice(0, -1)}-${rutLimpio.slice(-1)}`;
    return rutFormateado;
  };

  //Valida Rut
  const validarRut = (rut) => {
    const rutFormateado = formatearRutSinPuntos(rut);
    return validate(rutFormateado);
  }

  //Valida Email
  const validarEmail = (email) => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmitTipoPerfil = async (event) => {
    event.preventDefault();

    try {
      if (formTipoPerfilData.nombre_perfil.trim() === "") {
        alert("Debe ingresar un nombre de perfil");
      } else {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/perfil.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formTipoPerfilData),
          }
        );
  
        const data = await response.json();
  
        if (data.message === "Perfil agregado con éxito.") {
          alert("Perfil agregado con éxito.");
          setFormTipoPerfilData({
            nombre_perfil: "",
            id_usuario: ""
          });
          navigate("/herramientas", { state: dataLogin });
          closeModalTipoPerfil();
        } else {
          throw new Error();
        }
      }
      
    } catch (error) {
      alert("Error al agregar el perfil.");
    }
  };

  const handleSubmitUsuario = async (event) => {
    event.preventDefault();

    try {
      const esFormularioValido = validaFormularioUsuario();
      const esRutValido = validarRut(formUsuarioData.rut);
      const esEmailValido = validarEmail(formUsuarioData.email);

      if (!esRutValido) {
        alert("Rut No Válido");
      } else if (!esEmailValido) {
        alert("Email No Válido");
      } else if (esFormularioValido) {
        const rutFormateado = formatearRutSinPuntos(formUsuarioData.rut);

        const responseVerifica = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?verifica=1&rut=${rutFormateado}`
        );
        
        const data = await responseVerifica.json();
  
        if (data && data[0].existe === 1) {
          alert("Usuario ya registrado, verifique la información ingresada");
        } else {
          const iniciales = formUsuarioData.nombres[0] + formUsuarioData.apellido_paterno[0];
          formUsuarioData.iniciales = iniciales;
    
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/usuario.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formUsuarioData),
            }
          );
    
          const data = await response.json();
    
          if (data.message === "Usuario agregado con éxito.") {
            alert("Usuario agregado con éxito.");
            setFormUsuarioData({
              id_perfil: "",
              nombres: "",
              apellido_paterno: "",
              apellido_materno: "",
              rut: "",
              email: "",
              iniciales: "",
              telefono: "",
              contrasena: "",
              repite_contrasena: "",
              id_usuario_registra: ""
            });
            navigate("/herramientas", { state: dataLogin });
  
            closeModalUsuario();
          } else {
            throw new Error();
          }
        }
      }
    } catch (error) {
      alert("Error al agregar Usuario.");
    }
  };

  const validaFormularioUsuario = () => {
    if (formUsuarioData.nombres.trim() === "") {
      alert('Debe ingresar nombres de usuario');
      return false;
    }

    if (formUsuarioData.apellido_paterno.trim() === '') {
      alert('Debe ingresar el apellido paterno');
      return false;
    }

    if (formUsuarioData.apellido_materno.trim() === '') {
      alert('Debe ingresar el apellido materno');
      return false;
    }

    if (formUsuarioData.rut.trim() === '') {
      alert('Debe ingresar el rut');
      return false;
    }

    if (formUsuarioData.email.trim() === '') {
      alert('Debe ingresar el email');
      return false;
    }

    if (formUsuarioData.telefono.trim() === '') {
      alert('Debe ingresar el teléfono');
      return false;
    }

    if (formUsuarioData.id_perfil.trim() === '') {
      alert('Debe seleccionar un perfil');
      return false;
    }

    if (formUsuarioData.contrasena.trim() === '') {
      alert('Debe ingresar una contraseña');
      return false;
    }

    if (formUsuarioData.repite_contrasena.trim() === '') {
      alert('Debe repetir la contraseña');
      return false;
    }

    if (formUsuarioData.contrasena != formUsuarioData.repite_contrasena) {
      alert('Debe repetir la misma contraseña');
      return false;
    }

    return true;
  }

  const [mostrarContrasena, setMostrarContrasena] = useState({
    contrasena: false,
    repite_contrasena: false
  });

  const handleMostrarContrasena = (name) => {
    setMostrarContrasena({ ...mostrarContrasena, [name]: !mostrarContrasena[name] });
  };

  const modalComponentTipoPerfil = (
    <Modal
      isOpen={isModalTipoPerfilOpen}
      onRequestClose={closeModalTipoPerfil}
      contentLabel="ModalTipoPerfil"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Nuevo Perfil</h4>
            <button onClick={closeModalTipoPerfil} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <label className="form-label">Nombre del Perfil</label>
            <input
              className="form-control"
              name="nombre_perfil"
              value={formTipoPerfilData.nombre_perfil}
              onChange={handleFormTipoPerfilData}
              type="text"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitTipoPerfil}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const modalComponentUsuario = (
    <Modal
      isOpen={isModalUsuarioOpen}
      onRequestClose={closeModalUsuario}
      contentLabel="ModalUsuario"
      className="modal"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Nuevo Usuario</h4>
            <button onClick={closeModalUsuario} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-2">
              <label className="form-label">Nombres</label>
              <input
                type="text"
                className="form-control"
                name="nombres"
                value={formUsuarioData.nombres}
                onChange={handleFormUsuarioData}
              />
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-2">
              <div className="col">
                <label className="form-label">Apellido Paterno</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido_paterno"
                  value={formUsuarioData.apellido_paterno}
                  onChange={handleFormUsuarioData}
                />
              </div>
              <div className="col">
                <label className="form-label">Apellido Materno</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido_materno"
                  value={formUsuarioData.apellido_materno}
                  onChange={handleFormUsuarioData}
                />
              </div>
            </div>
            <div className="mb-2">
              <label className="form-label">Rut</label>
              <input
                type="text"
                className="form-control"
                name="rut"
                value={formUsuarioData.rut}
                onChange={handleFormUsuarioData}
                maxLength={10}
                placeholder="xxxxxxxx-x"
              />
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formUsuarioData.email}
                  onChange={handleFormUsuarioData}
                />
              </div>
              <div className="col">
                <label className="form-label">Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  name="telefono"
                  maxLength={9}
                  value={formUsuarioData.telefono}
                  onChange={handleFormUsuarioData}
                />
              </div>
            </div>
            <h5 className="mb-2">Datos de Perfil</h5>
            <div className="mb-2">
              <label className="form-label">Perfil</label>
              <select
                className="form-select"
                name="id_perfil"
                value={formUsuarioData.id_perfil}
                onChange={handleFormUsuarioData}
              >
                <option value="">Seleccione un perfil</option>
                {tipoPerfilOptions.map((tipo) => (
                    <option key={tipo.id_perfil} value={tipo.id_perfil}>
                        {tipo.nombre_perfil}
                    </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label className="form-label">Contraseña</label>
              <div className="d-flex gap-2">
                <input
                  type={mostrarContrasena.contrasena ? 'text' : 'password'}
                  className="form-control"
                  name="contrasena"
                  value={formUsuarioData.contrasena}
                  onChange={handleFormUsuarioData}
                />
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => handleMostrarContrasena('contrasena')}
                >
                  {mostrarContrasena.contrasena ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <label className="form-label">Repita Contraseña</label>
            <div className="d-flex gap-2">
              <input
                type={mostrarContrasena.repite_contrasena ? 'text' : 'password'}
                className="form-control"
                name="repite_contrasena"
                value={formUsuarioData.repite_contrasena}
                onChange={handleFormUsuarioData}
              />
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() => handleMostrarContrasena('repite_contrasena')}
              >
                {mostrarContrasena.repite_contrasena ? (
                  <i className="bi bi-eye-slash"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitUsuario}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    </Modal>
  );

  /* Filtros Buscador */
  const [filtroUsuarios, setFiltroUsuarios] = useState('');
  const handleFiltroUsuarios = (event) => {
    setFiltroUsuarios(event.target.value);
  };
  const usuariosFiltrados = usuarios.filter((item) =>
    (item.nombres + " " + item.apellido_paterno + " " + item.apellido_paterno).toLowerCase().includes(filtroUsuarios.toLowerCase())
  );

  const [filtroPerfiles, setFiltroPerfiles] = useState('');
  const handleFiltroPerfiles = (event) => {
    setFiltroPerfiles(event.target.value);
  };
  const perfilesFiltrados = tipoPerfilOptions.filter((item) =>
    (item.nombre_perfil).toLowerCase().includes(filtroPerfiles.toLowerCase())
  );

  /* Edición Usuarios y Perfiles */
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [secondModalContent, setSecondModalContent] = useState(null);

  const handleEditButtonClick = (edicion, data) => {
    setModalOpen(true);
    switch (edicion) {
      case "Usuario":
        setModalContent(<EditarUsuarioModalContent datos={data} />);
        break;
      case "Perfil":
        setModalContent(<EditarPerfilModalContent datos={data} />);
        break;
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const closeSecondModal = () => {
    setSecondModalOpen(false);
    setSecondModalContent(null);
  };

  function EditarUsuarioModalContent({ datos }) {
    const navigate = useNavigate();

    const [formUsuarioEditData, setFormUsuarioEditData] = useState({
      id_usuario: datos.id_usuario || "",
      id_perfil: datos.id_perfil || "",
      nombre_perfil: datos.nombre_perfil || "",
      nombres: datos.nombres || "",
      apellido_paterno: datos.apellido_paterno || "",
      apellido_materno: datos.apellido_materno || "",
      rut: datos.rut || "",
      email: datos.email || "",
      iniciales: datos.iniciales || "",
      telefono: datos.telefono || "",
      contrasena: datos.contrasena || "",
      contrasena_actual: "",
      contrasena_nueva: "",
      repite_contrasena_nueva: "",
      id_estado_usuario: datos.id_estado_usuario.toString() || "",
      estado: datos.estado || ""
    });

    const handleFormUsuarioEditData = (e) => {
      setFormUsuarioEditData({
        ...formUsuarioEditData,
        [e.target.name]: e.target.value,
      });
    };

    const [mostrarCamposContrasena, setMostrarCamposContrasena] = useState(false);
    const handleRadioChange = (event) => {
      setMostrarCamposContrasena(event.target.value === "1");

      setFormUsuarioEditData({
        ...formUsuarioEditData,
        ["contrasena_actual"]: "",
        ["contrasena_nueva"]: "",
        ["repite_contrasena_nueva"]: ""
      });
    }

    const [mostrarContrasena, setMostrarContrasena] = useState({
      contrasena_actual: false,
      contrasena_nueva: false,
      repite_contrasena_nueva: false
    });

    const handleMostrarContrasena = (name) => {
      setMostrarContrasena({ ...mostrarContrasena, [name]: !mostrarContrasena[name] });
    };

    const handleSubmitEditUsuario = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormularioUsuario();
        const esRutValido = validarRut(formUsuarioEditData.rut);
        const esEmailValido = validarEmail(formUsuarioEditData.email);

        if (!esRutValido) {
          alert("Rut No Válido");
        } else if (!esEmailValido) {
          alert("Email No Válido");
        } else if (esFormularioValido) {
          const iniciales = formUsuarioEditData.nombres[0] + formUsuarioEditData.apellido_paterno[0];
          formUsuarioEditData.iniciales = iniciales;

          const responseEditUsuario = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/usuario.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formUsuarioEditData),
            }
          );
  
          const dataEditUsuario = await responseEditUsuario.json();
          if (dataEditUsuario.message === "Usuario modificado con éxito.") {
            setFormUsuarioEditData({
              id_usuario: "",
              nombre_perfil: "",
              nombres: "",
              apellido_paterno: "",
              apellido_materno: "",
              rut: "",
              email: "",
              iniciales: "",
              telefono: "",
              contrasena: "",
              contrasena_actual: "",
              contrasena_nueva: "",
              repite_contrasena_nueva: "",
              id_estado_usuario: "",
              estado: ""
            });
  
            alert("Usuario modificado con éxito.");
            closeModal();
            navigate("/herramientas", { state: dataLogin });
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert("Error al editar el usuario.");
      }
    }

    const validaFormularioUsuario = () => {
      if (formUsuarioEditData.nombres === "") {
        alert('Debe ingresar nombres');
        return false;
      }
  
      if (formUsuarioEditData.apellido_paterno === "") {
        alert('Debe ingresar el apellido paterno');
        return false;
      }

      if (formUsuarioEditData.rut === "") {
        alert('Debe ingresar el rut');
        return false;
      }

      if (formUsuarioEditData.email === "") {
        alert('Debe ingresar el email');
        return false;
      }
  
      if (formUsuarioEditData.telefono === "") {
        alert('Debe ingresar el teléfono');
        return false;
      }

      if (formUsuarioEditData.id_perfil === "") {
        alert('Debe seleccionar un perfil');
        return false;
      }

      if (mostrarCamposContrasena) {
        if (formUsuarioEditData.contrasena_actual === "") {
          alert('Debe ingresar la contraseña actual');
          return false;
        }

        if (formUsuarioEditData.contrasena_nueva === "") {
          alert('Debe ingresar la contraseña actual');
          return false;
        }

        if (formUsuarioEditData.repite_contrasena_nueva === "") {
          alert('Debe repetir la contraseña nueva');
          return false;
        }

        if (formUsuarioEditData.contrasena != formUsuarioEditData.contrasena_actual) {
          alert('La contraseña actual es incorrecta');
          return false;
        }

        if (formUsuarioEditData.contrasena_nueva != formUsuarioEditData.repite_contrasena_nueva) {
          alert('Debe repetir la misma contraseña actual');
          return false;
        }
      }
  
      return true;
    }

    /* Deshabilita Usuario */
    const handleDeshabilitarUsuario = (idUsuario, nombreUsuario) => {
      setSecondModalOpen(true);
      setSecondModalContent(<ConfirmaDeshabilitarUsuarioModalContent id_usuario={idUsuario} nombre_usuario={nombreUsuario} />);
    };

    function ConfirmaDeshabilitarUsuarioModalContent ({ id_usuario, nombre_usuario }) {
      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Deshabilitar Usuario</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea deshabilitar al usuario: <span className="text-red">{nombre_usuario}</span>?</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-danger" onClick={(e) => handleSubmitDeshabilitarUsuario(e, id_usuario)}><i className="bi bi-dash-circle"></i> Deshabilitar</button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitDeshabilitarUsuario = async (event, idUsuario) => {
      event.preventDefault();
  
      try {
        const formDeshabilitaUsuario = {
          id_usuario: idUsuario
        };

        const responseDeshabilitarUsuario = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?deshabilita=1`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formDeshabilitaUsuario),
          }
        );

        const dataDeshabilitarUsuario = await responseDeshabilitarUsuario.json();
        if (dataDeshabilitarUsuario.message === "Usuario deshabilitado con éxito.") {
          setFormUsuarioEditData({
            id_usuario: "",
            nombre_perfil: "",
            nombres: "",
            apellido_paterno: "",
            apellido_materno: "",
            rut: "",
            email: "",
            iniciales: "",
            telefono: "",
            contrasena: "",
            contrasena_actual: "",
            contrasena_nueva: "",
            repite_contrasena_nueva: "",
            id_estado_usuario: "",
            estado: ""
          });

          alert("Usuario deshabilitado con éxito.");
          closeSecondModal();
          closeModal();
          obtenerUsuarios();
          navigate("/herramientas", { state: dataLogin });
        }
      } catch (error) {
        alert("Error al deshabilitar al usuario");
      }
    }

    /* Habilita Usuario */
    const handleHabilitarUsuario = (idUsuario, nombreUsuario) => {
      setSecondModalOpen(true);
      setSecondModalContent(<ConfirmaHabilitarUsuarioModalContent id_usuario={idUsuario} nombre_usuario={nombreUsuario} />);
    };

    function ConfirmaHabilitarUsuarioModalContent ({ id_usuario, nombre_usuario }) {
      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Habilitar Usuario</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea habilitar al usuario: <span className="text-green">{nombre_usuario}</span>?</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-success" onClick={(e) => handleSubmitHabilitarUsuario(e, id_usuario)}><i className="bi bi-dash-circle"></i> Habilitar</button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitHabilitarUsuario = async (event, idUsuario) => {
      event.preventDefault();
  
      try {
        const formHabilitaUsuario = {
          id_usuario: idUsuario
        };

        const responseHabilitarUsuario = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/usuario.php?habilita=1`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formHabilitaUsuario),
          }
        );

        const dataHabilitarUsuario = await responseHabilitarUsuario.json();
        if (dataHabilitarUsuario.message === "Usuario habilitado con éxito.") {
          setFormUsuarioEditData({
            id_usuario: "",
            nombre_perfil: "",
            nombres: "",
            apellido_paterno: "",
            apellido_materno: "",
            rut: "",
            email: "",
            iniciales: "",
            telefono: "",
            contrasena: "",
            contrasena_actual: "",
            contrasena_nueva: "",
            repite_contrasena_nueva: "",
            id_estado_usuario: "",
            estado: ""
          });

          alert("Usuario habilitado con éxito.");
          closeSecondModal();
          closeModal();
          obtenerUsuarios();
          navigate("/herramientas", { state: dataLogin });
        }
      } catch (error) {
        alert("Error al habilitar al usuario");
      }
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Usuario</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-2">
              <label className="form-label">Nombres</label>
              <input
                type="text"
                className="form-control"
                name="nombres"
                value={formUsuarioEditData.nombres}
                onChange={handleFormUsuarioEditData}
              />
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-2">
              <div className="col">
                <label className="form-label">Apellido Paterno</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido_paterno"
                  value={formUsuarioEditData.apellido_paterno}
                  onChange={handleFormUsuarioEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Apellido Materno</label>
                <input
                  type="text"
                  className="form-control"
                  name="apellido_materno"
                  value={formUsuarioEditData.apellido_materno}
                  onChange={handleFormUsuarioEditData}
                />
              </div>
            </div>
            <div className="mb-2">
              <label className="form-label">Rut</label>
              <input
                type="text"
                className="form-control"
                name="rut"
                value={formUsuarioEditData.rut}
                onChange={handleFormUsuarioEditData}
                maxLength={10}
                placeholder="xxxxxxxx-x"
              />
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formUsuarioEditData.email}
                  onChange={handleFormUsuarioEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Teléfono</label>
                <input
                  type="text"
                  className="form-control"
                  name="telefono"
                  maxLength={9}
                  value={formUsuarioEditData.telefono}
                  onChange={handleFormUsuarioEditData}
                />
              </div>
            </div>
            <h5 className="mb-2">Datos de Perfil</h5>
            <div className="mb-3">
              <label className="form-label">Perfil</label>
              <select className="form-select" name="id_perfil" value={formUsuarioEditData.id_perfil} onChange={handleFormUsuarioEditData}>
                {tipoPerfilOptions ? (
                  tipoPerfilOptions.map((tipo, index) => (
                    <option key={index} value={tipo.id_perfil}>{tipo.nombre_perfil}</option>
                  ))
                ) : (
                  <option value="">Usuario sin perfil asignado</option>
                )}
              </select>
            </div>
            <label className="form-label d-block">¿Editar contraseña?</label>
            <div>
              <input
                type="radio" 
                className="btn-check" 
                name="editar_contrasena" 
                id="editar-contrasena-si" 
                autoComplete="off" 
                value="1"
                checked={mostrarCamposContrasena}
                onChange={handleRadioChange}
              />
              <label className="btn" htmlFor="editar-contrasena-si">Si</label>
              <input 
                type="radio" 
                className="btn-check" 
                name="editar_contrasena" 
                id="editar-contrasena-no" 
                autoComplete="off"
                value="0"
                checked={!mostrarCamposContrasena}
                onChange={handleRadioChange}
              />
              <label className="btn" htmlFor="editar-contrasena-no">No</label>
            </div>
            {mostrarCamposContrasena && (
              <div className="mt-2">
                <div className="mb-2">
                  <label className="form-label">Contraseña Actual</label>
                  <div className="d-flex gap-2">
                    <input
                      type={mostrarContrasena.contrasena_actual ? 'text' : 'password'}
                      className="form-control"
                      name="contrasena_actual"
                      value={formUsuarioEditData.contrasena_actual}
                      onChange={handleFormUsuarioEditData}
                    />
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={() => handleMostrarContrasena('contrasena_actual')}
                    >
                      {mostrarContrasena.contrasena_actual ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="mb-2">
                  <label className="form-label">Contraseña Nueva</label>
                  <div className="d-flex gap-2">
                    <input
                      type={mostrarContrasena.contrasena_nueva ? 'text' : 'password'}
                      className="form-control"
                      name="contrasena_nueva"
                      value={formUsuarioEditData.contrasena_nueva}
                      onChange={handleFormUsuarioEditData}
                    />
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={() => handleMostrarContrasena('contrasena_nueva')}
                    >
                      {mostrarContrasena.contrasena_nueva ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <label className="form-label">Repita Contraseña Nueva</label>
                <div className="d-flex gap-2">
                  <input
                    type={mostrarContrasena.repite_contrasena_nueva ? 'text' : 'password'}
                    className="form-control"
                    name="repite_contrasena_nueva"
                    value={formUsuarioEditData.repite_contrasena_nueva}
                    onChange={handleFormUsuarioEditData}
                  />
                  <button
                    className="btn btn-outline-primary"
                    type="button"
                    onClick={() => handleMostrarContrasena('repite_contrasena_nueva')}
                  >
                    {mostrarContrasena.repite_contrasena_nueva ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer flex-row gap-1">
            {formUsuarioEditData.id_estado_usuario === "1" ? (
              <button className="btn btn-outline-danger" onClick={(e) => handleDeshabilitarUsuario(formUsuarioEditData.id_usuario, formUsuarioEditData.nombres + " " + formUsuarioEditData.apellido_paterno + " " + formUsuarioEditData.apellido_materno)}>
                <i className="bi bi-dash-circle"></i> Deshabilitar
              </button>
            ) : formUsuarioEditData.id_estado_usuario === "0" && (
              <button className="btn btn-outline-success" onClick={(e) => handleHabilitarUsuario(formUsuarioEditData.id_usuario, formUsuarioEditData.nombres + " " + formUsuarioEditData.apellido_paterno + " " + formUsuarioEditData.apellido_materno)}>
                <i className="bi bi-check-circle"></i> Habilitar
              </button>
            )}
            <button className="btn btn-yellow" onClick={handleSubmitEditUsuario}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function EditarPerfilModalContent({ datos }) {
    const navigate = useNavigate();

    const [formPerfilEditData, setFormPerfilEditData] = useState({
      id_perfil: datos.id_perfil || "",
      nombre_perfil: datos.nombre_perfil || ""
    });

    const handleFormPerfilEditData = (e) => {
      setFormPerfilEditData({
        ...formPerfilEditData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmitEditPerfil = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormularioPerfil();

        if (esFormularioValido) {
          const responseEditPerfil = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/perfil.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formPerfilEditData),
            }
          );
  
          const dataEditPerfil = await responseEditPerfil.json();
          if (dataEditPerfil.message === "Perfil modificado con éxito.") {
            setFormPerfilEditData({
              id_perfil: "",
              nombre_perfil: ""
            });
  
            alert("Perfil modificado con éxito.");
            closeModal();
            navigate("/herramientas", { state: dataLogin });
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert("Error al editar el perfil.");
      }
    }

    const validaFormularioPerfil = () => {
      if (formPerfilEditData.nombre_perfil === "") {
        alert('Debe ingresar el nombre del perfil');
        return false;
      }
  
      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Perfil</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <label className="form-label">Nombre del Perfil</label>
            <input
              className="form-control"
              name="nombre_perfil"
              value={formPerfilEditData.nombre_perfil}
              onChange={handleFormPerfilEditData}
              type="text"
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEditPerfil}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>
      <Modal
        isOpen={isSecondModalOpen}
        onRequestClose={closeSecondModal}
        contentLabel="Modal"
        className="modal"
      >
        {secondModalContent}
      </Modal>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header pt-3">
                  <ul className="nav nav-tabs card-header-tabs mx-auto">
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab === 'usuarios' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('usuarios')}>Usuarios</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeTab === 'perfiles' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('perfiles')}>Perfiles</a>
                    </li>
                  </ul>
                </div>
                <div className="card-body card-body-fh p-0">
                  <div className="tab-content">
                    <div className={`tab-pane ${activeTab === 'usuarios' ? 'active' : ''}`}>
                      <div className="d-flex justify-content-md-between p-3">
                        <div className="input-group w-25">
                          <span className="input-group-text"><i className="bi bi-search"></i></span>
                          <input type="text" className="form-control" placeholder="Nombre usuario..." onChange={handleFiltroUsuarios}/>
                        </div>
                        <button type="button" className="btn btn-yellow" onClick={handleAddUsuarioClick}>
                          <i className="bi bi-plus-circle"></i> Agregar Usuario
                        </button>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover mb-0">
                          <thead>
                            <tr className="table-active border-top border-bottom">
                              <th scope="col" className="ps-3">Nombre Usuario</th>
                              <th scope="col">Rut</th>
                              <th scope="col">Email</th>
                              <th scope="col">Teléfono</th>
                              <th scope="col">Iniciales</th>
                              <th scope="col">Perfil</th>
                              <th scope="col">Estado</th>
                              <th scope="col" className="pe-3">Edición</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usuariosFiltrados.map((usuario) => (
                              <tr key={usuario.id_usuario}>
                                <td className="ps-3">{usuario.nombres} {usuario.apellido_paterno} {usuario.apellido_materno}</td>
                                <td>{formatearRut(usuario.rut)}</td>
                                <td>{usuario.email}</td>
                                <td>(+56) {usuario.telefono}</td>
                                <td>{usuario.iniciales}</td>
                                <td>{usuario.nombre_perfil}</td>
                                <td>
                                  <span className={usuario.estado === 'Habilitado' ? 'text-green' : 'text-red'}>{usuario.estado}</span>
                                </td>
                                <td>
                                  <button className="btn btn-outline-dark btn-sm w-100" onClick={() => handleEditButtonClick("Usuario", usuario)}>
                                    <i className="bi bi-pencil-square"></i> Editar
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'perfiles' ? 'active' : ''}`}>
                      <div className="d-flex justify-content-md-between p-3">
                        <div className="input-group w-25">
                          <span className="input-group-text" id="input-search-perfil"><i className="bi bi-search"></i></span>
                          <input type="text" className="form-control" placeholder="Nombre perfil..." onChange={handleFiltroPerfiles}/>
                        </div>
                       <button type="button" className="btn btn-yellow" onClick={handleAddTipoPerfilClick}>
                          <i className="bi bi-plus-circle"></i> Agregar Perfil
                        </button>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover mb-0">
                          <thead>
                            <tr className="table-active border-top border-bottom">
                              <th className="col-md-11 ps-3">Nombre Perfil</th>
                              <th className="col-md-1">Edición</th>
                            </tr>
                          </thead>
                          <tbody>
                            {perfilesFiltrados.map((perfil) => (
                              <tr key={perfil.id_perfil}>
                                <td className="ps-3">{perfil.nombre_perfil}</td>
                                <td>
                                  <button className="btn btn-outline-dark btn-sm w-100" onClick={() => handleEditButtonClick("Perfil", perfil)}>
                                    <i className="bi bi-pencil-square"></i> Editar
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalComponentTipoPerfil}
      {modalComponentUsuario}
    </div>
  );
}

const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default Herramientas;
